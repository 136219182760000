import React, { useState, useEffect } from 'react'
import { useField } from 'formik'
import { FormField, Grid, Popup, Button } from 'semantic-ui-react'
import styles from '../../../assets/styles/modules/UI/TimePicker.module.scss'

const HOURS_NAME = 'hourSelector'

const fillArray = (length, step) => {
  const filledArr = new Array(length).fill().map((_, index) => {
    return index
  })
  return !step ? filledArr : filledArr.filter((index) => index * step < length).map((index) => index * step)
}

const TimePicker = (props) => {
  const [field, meta, helpers] = useField(props)

  const [open, setOpen] = React.useState(false)
  const [hour, setHour] = useState('')

  useEffect(() => {
    setHour(field.value)
  }, [field.value])

  const handleTimeChange = (target) => {
    setHour(target.innerText)
  }

  const setNewTime = () => {
    const newTime = `${hour}`
    helpers.setValue(newTime)
    if (props.fieldValueSetter) {
      props.fieldValueSetter(props.name, newTime)
    }
    setOpen(false)
  }

  const PopupHours = () => {
    const hoursArray = fillArray(24)
    const minutesArray = fillArray(2)
    
    return (
      <Grid.Column className={styles.PopupColumn}>
        {hoursArray.map((hours) => minutesArray.map((minutes) => {
          let minute = (minutes % 2 === 0) ? '00' : '30'
          return (
            <Button
              className={styles.SelectorButton}
              key={hours}
              content={hours < 10 ? `0${hours}:${minute}` : `${hours}:${minute}`}
              onClick={({ target }) => handleTimeChange(target)}
              name={HOURS_NAME}
            />
          )
        })
      )}
      </Grid.Column>
    )
  }

  const CustomPopup = () => {
    return (
      <span>
        <Popup
          on="click"
          pinned
          position="bottom right"
          disabled={props.disabled}
          open={open}
          eventsEnabled={true}
          className={styles.Popup}
          trigger={<span className={styles.TriggerButton} />}
          onOpen={() => setOpen(true)}
          onClose={() => setNewTime()}
        >
          <Grid className={styles.GridContainer} columns="equal">
            <PopupHours />
          </Grid>
        </Popup>
      </span>
    )
  }

  return (
    <FormField className={styles.TimePicker}>
      <div className={styles.InputsContainer}>
        <input
          className='time-input'
          type="string"
          name={props.name}
          disabled
          defaultValue={hour}
        />
      </div>
      <CustomPopup />
    </FormField>
  )
}

export default TimePicker
