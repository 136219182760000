import React, { useState, useEffect } from 'react'
import { Grid, Button, Table, Label, Icon } from 'semantic-ui-react'
import { Form, Formik, FieldArray } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../../config/firebase'
import TextInput from '../../UI/Form/TextInput'
import styles from '../../../assets/styles/modules/orders/OrderConfirmation.module.scss'

const OrderConfirmation = (props) => {
  const [missingItems, setMissingItems] = useState(false)
  const [initialValues, setInitialValues] = useState(null)
  const { t } = useTranslation(['orders', 'validationMessages', 'stock', 'buttons'])

  useEffect(() => {
    const getUserStock = async () => {
      const response = await httpsCallable(
        cloudFunctions,
        'getUserStock'
      )({
        locationId: props.order.deliveryLocation.locationId,
      })

      const stock = response.data
      const items = props.order.items

      if (items) {
        const updatedItems = items.map((item) => {
          const stockItem = stock.find((el) => el.id === item.itemId)
          return stockItem ? { ...item, previousTotalAmount: stockItem.amount } : { ...item }
        })

        await httpsCallable(cloudFunctions, 'updateOrderItems')({ orderId: props.order.id, items: updatedItems })
        setInitialValues({ items: props.order.items })
      }
    }

    getUserStock()
  }, [props.order.deliveryLocation.locationId, props.order.items, props.order.id])

  const validationSchema = Yup.object().shape({
    items: Yup.array().of(
      Yup.object().shape({
        amount: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(0, t('below_zero', { ns: 'validationMessages' })),
      })
    ),
  })

  const getUpdatedValues = (initialValues, values) => {
    const updatedValues = {
      items: initialValues.items.map((item) => {
        const updatedItem = values.items.find((updatedItem) => updatedItem.itemId === item.itemId)
        if (updatedItem) {
          const updatedItemAmount = Number(updatedItem.amount)
          const itemAmount = Number(item.amount)
          let newAmount = item.previousTotalAmount ? item.previousTotalAmount : 0
          if (updatedItemAmount !== itemAmount) {
            if (updatedItemAmount === 0) {
              newAmount -= item.amount
            } else {
              newAmount += (item.totalAmount / itemAmount) * updatedItemAmount
              newAmount -= itemAmount
            }
          } else {
            newAmount += updatedItem ? Number(updatedItem.totalAmount) : 0
            newAmount -= updatedItem ? Number(updatedItem.amount) : 0
          }
          return { ...item, amount: newAmount }
        }
        return { ...item }
      }),
    }

    return updatedValues
  }

  const getUpdatedOrderItems = (initialValues, values) => {
    const updatedOrderItems = {
      items: initialValues.items.map((item) => {
        const updatedItem = values.items.find((updatedItem) => updatedItem.itemId === item.itemId)
        const updatedItemAmount = Number(updatedItem.amount)
        const itemAmount = Number(item.amount)
        let newTotalAmount =
          updatedItemAmount !== itemAmount ? (item.totalAmount / itemAmount) * updatedItemAmount : item.totalAmount

        return {
          ...item,
          amountReceived:
            updatedItemAmount !== itemAmount ? { amount: updatedItemAmount, totalAmount: newTotalAmount } : null,
        }
      }),
    }

    return updatedOrderItems
  }

  const GridHeaders = ({ renderInput }) => {
    return (
      <Grid.Row className={renderInput ? 'delivered-grid-headers' : 'ordered-grid-headers'}>
        <Grid.Column>
          <strong>{t('item', { ns: 'orders '})}</strong>
        </Grid.Column>
        <Grid.Column>
          <strong>{t('amount_ordered', { ns: 'orders '})}</strong>
        </Grid.Column>
        <Grid.Column>
          <strong>{t('amount_delivered', { ns: 'orders '})}</strong>
        </Grid.Column>
      </Grid.Row>
    )
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        props.onChangeStatusClick('delivered')

        const updatedValues = getUpdatedValues(initialValues, values)
        const updatedOrderItems = getUpdatedOrderItems(initialValues, values)

        // Update Stock with new values
        props.updateOrderItems(updatedOrderItems)
        props.updateStockItems(updatedValues)
        props.changeConfirmationSection('general')
        setSubmitting(false)
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, isSubmitting, errors }) => (
        <Form autocomplete="off" className="ui form">
          {errors.distr && <Label basic color="red" content={errors.stock} style={{ marginBottom: 10 }} />}
          <div className={styles.OrderDetails}>
            <Grid>
              <Grid.Row className="order-headers">
                <Grid.Column>
                  <Icon color="grey" name="box" size="large" />
                  <p>{t('order_number', { number: props.order.orderId, ns: 'orders' })}</p>
                </Grid.Column>
                <Grid.Column>
                  <p className={styles.DistributorName}>{props.order.distributorName}</p>
                </Grid.Column>
              </Grid.Row>
              {!missingItems ? (
                <>
                  <Grid.Row className="all-received-row">
                    <Grid.Column>
                      <p>{t('mark_everything_as_received', { ns: 'buttons' })}:</p>
                    </Grid.Column>
                    <Grid.Column>
                      <Button
                        basic
                        disabled={!initialValues}
                        type="button"
                        content={t('received', { ns: 'buttons' })}
                        color="green"
                        onClick={() => {
                          const updatedValues = getUpdatedValues(initialValues, values)
                          props.updateStockItems(updatedValues)
                          props.onChangeStatusClick('delivered')
                          props.changeConfirmationSection('general')
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <GridHeaders renderInput={false} />

                  <Grid.Row className="body-row-container">
                    <FieldArray
                      name="items"
                      render={() => (
                        <>
                          {props.order.items &&
                            props.order.items.map((item) => (
                              <>
                                <Grid.Row key={item.itemId + '.name'} className="row-item">
                                  <Grid.Column>
                                    <p>{item.name}</p>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <p>
                                      {item.amount} {item.orderUnit}
                                    </p>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row key={item.itemId + '.itemNumber'} className="row-number">
                                  <Grid.Column>
                                    <p>{t('item_no', { ns: 'stock' })} {item.itemNumber}</p>
                                  </Grid.Column>
                                </Grid.Row>
                              </>
                            ))}
                        </>
                      )}
                    />
                  </Grid.Row>

                  <Grid.Row className="mobile-received-row">
                    <Grid.Column>
                      <Button
                        disabled={!initialValues}
                        type="button"
                        content={t('received', { ns: 'buttons' })}
                        color="green"
                        onClick={() => {
                          const updatedValues = getUpdatedValues(initialValues, values)
                          props.updateStockItems(updatedValues)
                          props.onChangeStatusClick('delivered')
                          props.changeConfirmationSection('general')
                        }}
                      />
                      <p className="hint">{t('mark_everything_as_received', { ns: 'buttons' })}.</p>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className="missing-order-row">
                    <Grid.Column>
                      <Button
                        disabled={!initialValues}
                        className={cx(styles.ui, styles.button, styles.CustomColor)}
                        type="button"
                        content={t('something_is_missing', { ns: 'buttons' })}
                        onClick={() => setMissingItems(true)}
                      />
                      <p className="hint">
                        {t('something_is_missing', { ns: 'buttons' })}
                        <br />
                        {t('let_me_update_the_order', { ns: 'buttons' })}
                      </p>
                    </Grid.Column>
                  </Grid.Row>
                </>
              ) : (
                <>
                  <Grid.Row className='missing-products-message'>
                    <Grid.Column width={16}>
                      <p className="missing-product-text">{t('amount_that_got_delivered', { ns: 'orders' })}</p>
                    </Grid.Column>
                  </Grid.Row>

                  <GridHeaders renderInput={true} />

                  <Grid.Row className="body-row-container">
                    <FieldArray
                      name="items"
                      render={() => (
                        <>
                          {props.order.items &&
                            props.order.items.map((item, index) => (
                              <>
                                <Grid.Row key={item.itemId + '.name'} className="row-item">
                                  <Grid.Column>{item.name}</Grid.Column>
                                  <Grid.Column className='desktop-item-amount'>
                                    <p>
                                      {item.amount} {item.orderUnit}
                                    </p>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <TextInput
                                      name={`items.${index}.amount`}
                                      disabled={isSubmitting}
                                    />
                                    <label>{item.orderUnit}</label>
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row key={item.itemId + '.itemNumber'} className="row-number">
                                  <Grid.Column>
                                    <p>{t('item_no', { ns: 'stock' })} {item.itemNumber}</p>
                                  </Grid.Column>
                                </Grid.Row>
                              </>
                            ))}
                        </>
                      )}
                    />
                  </Grid.Row>

                  <Grid.Row className='update-botton-row'>
                    <Grid.Column>
                      <Button
                        type="submit"
                        content={t('update_stock', { ns: 'buttons' })}
                        color="green"
                        disabled={errors.items}
                        loading={isSubmitting}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row className='missing-error-message'>
                    <Grid.Column textAlign="center">
                      {t('something_missing', { ns: 'orders' })}
                    </Grid.Column>
                  </Grid.Row>

                </>
              )}
            </Grid>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default OrderConfirmation
