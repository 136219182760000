import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getOrdersByIds, listenToNewOrders } from '../../store/actions/orderActions'
import useFirestoreCollection from '../../hooks/useFirestoreCollection'
import { Message, Button } from 'semantic-ui-react'
import OrderDetails from './Order/OrderDetails'
import buttonStyles from '../../assets/styles/modules/UI/Button.module.scss'
import Spinner from '../UI/Spinner/Spinner'
import { setPageTitle } from '../../store/actions/navigationActions'
import { useTranslation } from 'react-i18next'

const OrderSuccess = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { newOrders, orders } = useSelector((state) => state.order)
  const { loading } = useSelector((state) => state.async)
  const { t } = useTranslation(['orders'])

  useEffect(() => {
    dispatch(setPageTitle(t('orders_placed', { ns: 'orders' })))
  }, [dispatch])

  useFirestoreCollection({
    query: () => getOrdersByIds(newOrders),
    data: (orders) => dispatch(listenToNewOrders(orders)),
    deps: [dispatch, newOrders],
  })

  if (loading) return <Spinner content={t('loading', { ns: 'orders' })} />

  return (
    <>
      {(!newOrders || !newOrders.length) && <Message content={t('no_newly_created_orders', { ns: 'orders' })} />}
      {orders &&
        orders.map((order) => {
          return <OrderDetails key={order.id} order={order} />
        })}
      <div className="btn-container flex center-content">
        <Button
          className={buttonStyles.Secondary}
          type="button"
          color="green"
          content={t('to_all_orders', { ns: 'orders' })}
          onClick={() => {
            history.push('/orders')
          }}
        />
      </div>
    </>
  )
}

export default OrderSuccess
