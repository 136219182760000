import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { listenToStock, getStockFromFirestore } from '../../store/actions/stockActions'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../store/actions/userActions'
import useFirestoreDoc from '../../hooks/useFirestoreDoc'
import useSessionStorage from '../../hooks/useSessionStorage'
import useFirestoreCollection from '../../hooks/useFirestoreCollection'
import { orderInProgressAction } from '../../store/actions/orderActions'
import OrderDistributorItem from './OrderDistributorItem/OrderDistributorItem'
import { Button, Label, Message, Table, Search } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import { Form, Formik, FieldArray } from 'formik'
import * as Yup from 'yup'
import styles from '../../assets/styles/modules/orders/OrderDistributorsForm.module.scss'
import btnStyles from '../../assets/styles/modules/UI/Button.module.scss'
import useSearchCallback from '../../hooks/useSearchCallback'
import cx from 'classnames'

const OrderDistributorsForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['validationMessages', 'common', 'orders', 'buttons'])
  const { user } = useSelector((state) => state.user)
  const { loading, error } = useSelector((state) => state.async)
  const { selectedLocation } = useSelector((state) => state.location)
  const { orderInProgress } = useSelector((state) => state.order)
  const { value, searchResults } = useSelector((state) => state.search)
  const [currentOrder, setCurrentOrder] = useSessionStorage('currentOrder', null)

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  useFirestoreCollection({
    query: () => getStockFromFirestore(props.match.params.location),
    data: (stock) => {
      dispatch(listenToStock(stock))
    },
    deps: [dispatch],
  })

  const handleSearchChange = useSearchCallback(orderInProgress.orderItems)

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />

  if (
    (!user.isPremium) || (user.hasLocations && props.match.params.location && !selectedLocation)
  ) {
    return <Redirect to="/orders" />
  }

  const updateOrderItemWithSingleDistributorOption = (orderItem, updatedOrderSummary) => {
    const selectedDistributorId = orderItem.distributors
      ? Object.keys(orderItem.distributors)[0]
      : orderItem.distributorId
    const selectedDistributorName = orderItem.distributors
      ? Object.values(orderItem.distributors)[0]
      : orderItem.distributor
    const updatedOrderItem = {
      ...orderItem,
      selectedDistributor: {
        name: selectedDistributorName,
        selectedDistributorId: selectedDistributorId,
      },
    }
    updatedOrderSummary.push(updatedOrderItem)
  }

  const updateOrderInProgress = (values) => {
    const updatedOrderSummary = []
    const orderAmountSummary = []
    // Delete empty values from distributors array
    const distributors = values.distributors.filter((distributor) => distributor)
    orderInProgress.orderItems.forEach((orderItem) => {
      //Order Items list has items with multiple distributor list for selection
      if (distributors.length > 0 && orderItem.distributors) {
        const orderItemWithSelectedDistributor = distributors.find((ingrDist) => ingrDist.ingredient === orderItem.name)
        if (orderItemWithSelectedDistributor) {
          const selectedDistributorId = Object.keys(orderItem.distributors).find(
            (key) => key === orderItemWithSelectedDistributor.selectedDistributor
          )
          const selectedDistributor = orderItem.multiDistributors ? orderItem.multiDistributors[selectedDistributorId].filter(el => el.generic_name === orderItemWithSelectedDistributor.selectedProduct)[0] : orderItem.distributors[selectedDistributorId]
          const amountPerOrder = (selectedDistributor.orderUnitPcs || 1) * (selectedDistributor.qtyPiece || 1)
          let desiredAmount = orderItem.desiredAmount ? orderItem.desiredAmount : orderItem.amount
          let newAmount = Math.ceil(orderItem.amount / amountPerOrder)
          if (currentOrder) {
            const currentItem = currentOrder.find((el) => el.itemId === orderItem.itemId)
            if (currentItem) {
              newAmount = Math.ceil(currentItem.amount / amountPerOrder)
            }
          } else {
            orderAmountSummary.push({ itemId: orderItem.itemId, amount: Number(orderItem.amount) })
          }
          const updatedOrderItem = {
            ...orderItem,
            amount: newAmount,
            itemNumber: selectedDistributor.itemNumber,
            distributor: selectedDistributor.name,
            distributorId: selectedDistributorId,
            qtyPiece: selectedDistributor.qtyPiece,
            orderUnit: selectedDistributor.orderUnit,
            orderUnitPcs: selectedDistributor.orderUnitPcs,
            selectedDistributor: {
              name: selectedDistributor.name,
              selectedDistributorId,
            },
            desiredAmount,
          }
          updatedOrderSummary.push(updatedOrderItem)
        } else {
          // this is one of the orders items which has no multiple distributor list for selection
          updateOrderItemWithSingleDistributorOption(orderItem, updatedOrderSummary)
        }
      } else {
        // None of the order list items has multiple distributor list for selection
        updateOrderItemWithSingleDistributorOption(orderItem, updatedOrderSummary)
      }
    })
    if (orderAmountSummary !== [] && currentOrder === null) {
      setCurrentOrder(orderAmountSummary)
    }
    return updatedOrderSummary
  }

  const saveOrderInProgress = (values) => {
    const updatedOrderItems = updateOrderInProgress(values)
    dispatch(
      orderInProgressAction({
        orderItems: updatedOrderItems,
        suggestionsUsed: orderInProgress.suggestionsUsed,
        dateRange: orderInProgress.dateRange,
        deliveryDate: orderInProgress.deliveryDate,
        location: orderInProgress.location,
        delivery: [],
        pickUp: [],
        notes: [],
      })
    )

    history.push('/order-summary')
  }

  const multiDistributorIngredients = orderInProgress.orderItems.filter(
    (item) => (item.distributors && Object.values(item.distributors).length > 1) || (item.multiDistributors && Object.keys(item.multiDistributors).some(distId => item.multiDistributors[distId].length > 1))
  )
  const distributorsDropdownOptions = []

  multiDistributorIngredients.forEach((ingredient) => {
    Object.keys(ingredient.distributors).forEach((key) => {
      distributorsDropdownOptions.push({
        text: ingredient.distributors[key].name,
        value: key,
        key,
      })
    })
  })

  const getDropdownOptions = (item) => {
    const distributorsDropdownOptions = []
    item.onboarding.forEach((item) => {
      distributorsDropdownOptions.push({
        text: item.distributor_name,
        value: { distributor: item.id_distributor, product: item.id_product_onboarding ? item.id_product_onboarding : item.typed_product },
        key: item.id_distributor,
        disabled: !item.id_product_onboarding && !item.typed_product,
        description: !item.id_product_onboarding && !item.typed_product ? t('item_being_verified', { ns: 'orders' }) : item.id_product_onboarding ? item.id_product_onboarding : item.typed_product
      })
    })
    return distributorsDropdownOptions
  }

  const getMultiDistributorItemsQuantity = (items) => {
    const test = items.filter((item) => Object.keys(item.distributors || {}).length > 1)
    return test.length
  }

  const getMultiStockPerDistributorItemsQuantity = (items) => {
    const test = items.filter((item) => Object.keys(item.multiDistributors || {}).some(distId => item.multiDistributors[distId].length > 1))
    return test.length
  }

  const getIsDisabled = (values, items) => {
    return ((values.distributors?.filter((value) => value).length !== getMultiDistributorItemsQuantity(items)) && (values.distributors?.filter((value) => value).length !== getMultiStockPerDistributorItemsQuantity(items)))
  }

  return (
    <div className={styles.OrderFormContainer}>
      <div className="btn-container top fixed center-content search">
        <Search
          id="ingredientSearch"
          loading={loading}
          onSearchChange={handleSearchChange}
          results={[]}
          showNoResults={false}
          value={value}
          placeholder={t('search_for_ingredient', { ns: 'orders' })}
        />
      </div>
      <div>
        <div className={styles.DistributorsTitle}>
          <Trans
            t={t}
            ns={'orders'}
            i18nKey={'distributors_attached'}
          >
            <p className="large" style={{ marginTop: '20px' }}>
              Some items have multiple distributors attached to them! <br /> Select who you want to order from. &nbsp;
            </p>
          </Trans>
        </div>
        {error && <Message warning>{t('something_wrong', { ns: 'common' , error })}</Message>}
        {props.match.params.location && selectedLocation && (
          <p className="x-large align-center">
            {t('new_order_for', { ns: 'orders' })} <strong>{selectedLocation.name}</strong>
          </p>
        )}
        <Formik
          onSubmit={(values) => saveOrderInProgress(values)}
          validationSchema={Yup.object().shape({
            items: Yup.array().of(
              Yup.object().shape({
                amount: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(0, t('below_zero', { ns: 'validationMessages' })),
              })
            ),
          })}
          enableReinitialize={true}
          initialValues={{}}
        >
          {({ isSubmitting, errors, setFieldValue, values }) => (
            <Form autocomplete="off" className="ui form">
              {errors.distr && <Label basic color="red" content={errors.stock} style={{ marginBottom: 10 }} />}
              <FieldArray
                name="ingredients"
                render={() => (
                  <Table striped compact className="order-form-table">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{t('ingredient', { ns: 'orders' })}:</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{t('from', { ns: 'orders' })} </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{t('ordering', { ns: 'orders' })}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {orderInProgress?.orderItems.length > 0 &&
                        orderInProgress.orderItems.map((item, index) => {
                          if (!searchResults.length || searchResults.find((ing) => ing.itemId === item.itemId)) {
                            return (
                              <OrderDistributorItem
                                key={item.itemId}
                                values={values}
                                index={index}
                                item={item}
                                currentOrder={currentOrder}
                                fieldValueSetter={setFieldValue}
                                distributorsOptions={
                                  multiDistributorIngredients.find((ingr) => ingr === item)
                                    ? getDropdownOptions(item)
                                    : null
                                }
                                currentFieldName={`distributors.${index}`}
                              />
                            )
                          } else {
                            return <></>
                          }
                        })}
                    </Table.Body>
                  </Table>
                )}
              />
              <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapper}>
                  <Button
                    className={cx(styles.Button, styles.Orange)}
                    floated="left"
                    type="button"
                    basic
                    color="orange"
                    size="big"
                    onClick={() => history.push('/orders')}
                    content={t('cancel', { ns: 'buttons' })}
                  />
                  <Button
                    className={cx(styles.Button, styles.Green, btnStyles.Secondary)}
                    floated="right"
                    loading={isSubmitting}
                    disabled={getIsDisabled(values, orderInProgress.orderItems)}
                    type="submit"
                    content={t('next', { ns: 'buttons' })}
                    size="big"
                  />
                </span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default OrderDistributorsForm
