import React, { useEffect, useState, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Grid, Message, Label } from 'semantic-ui-react'
import { Form, FieldArray } from 'formik'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import TextInput from '../../UI/Form/TextInput'
import { getUnitArray, getDistributorsOnboarding, getCurrentDistributorProducts, getLocationId } from '../../../shared/utility'
import SelectInput from '../../UI/Form/SelectInput'
import styles from '../../../assets/styles/modules/stock/StockItem.module.scss'
import { cloudFunctions } from '../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import AddDistributorButton from '../../Menu/Form/Button/AddDistributorButton'
import { shouldDisableSaveButton } from './utils'
import StockWithDropdown from '../StockWithDropdown'
import { TYPE_YOUR_OWN_DISTRIBUTOR } from '../../../store/wordings'

export function ShowForm({ isSubmitting, errors, values, setFieldValue, locations, handleReset, locationName = '', selectedLocation }) {
  const history = useHistory()
  const [distributors, setDistributors] = useState([])
  const [isLoadingDistributors, setIsLoadingDistributors] = useState(true)
  const [distributorProducts, setDistributorProducts] = useState({})
  const { t } = useTranslation(['stock', 'buttons', 'menu'])

  useEffect(() => {
    const getDistributors = async () => {
      setDistributors((await getDistributorsOnboarding()).concat([TYPE_YOUR_OWN_DISTRIBUTOR(t)]))
      setIsLoadingDistributors(false)
    }

    // Should be able to type your own distributor
    getDistributors()
  }, [])

  const getDistributorProducts = async (distributorId) => {
    const distributor = distributors.filter(dist => dist.name === distributorId)
    if (!distributorProducts[`${distributorId}`]) {
      const custom = distributor.length && distributor[0].customDistributor
      const data = await getCurrentDistributorProducts(custom ? distributor[0].id : distributorId, custom ? getLocationId(selectedLocation) : null, t)
      setDistributorProducts((prev) => {
        return { ...prev, [distributorId]: data }
      })
    }
  }

  const updateProducts = (distributorId, setIsLoadingProducts, setDisableDropdown) => {
    getDistributorProducts(distributorId)
    if (distributorProducts[`${distributorId}`]?.length) {
      setIsLoadingProducts(false)
      setDisableDropdown(false)
      return distributorProducts[`${distributorId}`]
    }
    return []
  }

  const shouldDisableSave = useMemo(() => shouldDisableSaveButton(values), [values])
  return (
    <div className={styles.StockItemContainer}>
      {errors.stock && <Message error content={errors.stock} />}
      <Form autocomplete="off" className="ui form">
        <Grid className={styles.StockItemGrid} stackable={true}>
          <Grid.Row className={styles.MobileTitleRow}>
            <p>{t('stock_item', { ns: 'stock' })}</p>
          </Grid.Row>
          <Grid.Row verticalAlign="bottom" style={{ margin: '12px 0px' }} className={styles.InputsRow}>
            <TextInput name="name" placeholder={t('name_of_item', { ns: 'stock' })} type="text" style={{ width: '100%' }} />
            <TextInput name="amount" placeholder={t('amount_in_stock', { ns: 'stock' })} type="number" inputmode="decimal" style={{ width: '100%' }} />
            <SelectInput
              placeholder={t('unit_of_amount')}
              hint={t('measures')}
              name="unit"
              style={{ width: '100%' }}
              options={getUnitArray()}
            />
          </Grid.Row>

          <Grid.Row className={styles.MobileTitleRow}>
            <p>{t('distributor', { ns: 'stock' })}</p>
          </Grid.Row>

          <FieldArray
            name="onboarding"
            render={(arrayHelpers) => (
              <>
                {values.onboarding.length > 0 &&
                  values.onboarding.map((_, index) => (
                    <Grid
                      style={{ padding: '0 !important' }}
                      className={cx(styles.ui, styles.InputsColumn, 'stock-distributor-grid')}
                    >
                      <StockWithDropdown
                        index={index}
                        distributors={distributors}
                        fieldArrayHelper={arrayHelpers}
                        fieldValueSetter={setFieldValue}
                        values={values.onboarding}
                        updateProducts={updateProducts}
                        isLoadingDistributors={isLoadingDistributors}
                        error={errors.onboarding && errors.onboarding[index]}
                        isSubmitting={isSubmitting}
                      />
                      <hr style={{ width: '100%', border: '0.5px solid #3c3c43', display: values.onboarding.length > 1 ? 'block' : 'none' }} />
                    </Grid>
                  ))}
                <Grid.Row
                  style={{ paddingTop: '0px', marginBottom: '24px' }}
                  className={styles.AddDistributorContainer}
                >
                  <p>{t('from_multiple_distributors', { ns: 'stock' })}</p>
                  <AddDistributorButton fieldArrayHelper={arrayHelpers} />
                </Grid.Row>
              </>
            )}
          />
        </Grid>
        <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
          <span className={styles.ButtonWrapper}>
            <Button
              className={cx(styles.Button, styles.Orange)}
              type="button"
              basic
              color="orange"
              size="big"
              onClick={async () => {
                await handleReset()
                history.push('/stock')
              }}
              content={t('cancel', { ns: 'buttons' }).toUpperCase()}
            />
            <Button
              className={cx(styles.Button, styles.Green)}
              loading={isSubmitting}
              type="submit"
              color="green"
              content={t('save', { ns: 'buttons' }).toUpperCase()}
              size="big"
              disabled={shouldDisableSave}
            />
          </span>
        </div>
      </Form>
    </div>
  )
}
