import * as actionTypes from './actionTypes'
import { firestore } from '../../config/firebase'
import { query, collection, orderBy, doc } from 'firebase/firestore'
import { getUserId } from './authActions'

export const listenToDistributors = (distributors) => {
  return {
    type: actionTypes.GET_DISTRIBUTORS_SUCCESS,
    distributors: distributors,
  }
}

export function getDistributorsFromFirestore() {
  return query(collection(firestore, `users/${getUserId()}/distributors`), orderBy('name', 'asc'))
}

export const listenToDistributor = (distributor) => {
  return {
    type: actionTypes.GET_DISTRIBUTOR_SUCCESS,
    distributor,
  }
}

export function getDistributorFromFirestore(distrId) {
  return doc(firestore, `users/${getUserId()}/distributors`, distrId)
}
