import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { Button, Grid, Icon, Label, Popup, Segment } from 'semantic-ui-react'
import cx from 'classnames'
import { sortOpeningHoursByDay } from '../../../shared/utility'
import { getFirstDay, getDayKey } from './utils'
import styles from '../../../assets/styles/modules/distributors/Distributor.module.scss'
import placeholderSrc from '../../../assets/images/distributors/placeholder.svg'
import Accordion from '../../UI/Accordion/Accordion'

const Distributor = (props) => {
  const history = useHistory()
  const { distributor } = props
  const sortedOpeningHours = sortOpeningHoursByDay(distributor.openingHours)
  const firstDay = sortedOpeningHours ? getFirstDay(sortedOpeningHours) : null
  const target = history.location?.state?.target || localStorage.getItem('target')
  const { t } = useTranslation(['distributor', 'buttons'])
  const openingHoursKeys = Object.keys(sortedOpeningHours)
  const mediaMatch = window.matchMedia('(min-width: 768px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(target)
      if (!element) { return }
      element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      history.replace({state: {}})
      localStorage.removeItem('target')
    }, 500);

    return () => clearTimeout(timer)
  }, [history, target])

  const renderExtraInfo = (data) => {
    if (!data) return ''
    return data.reduce((accumulator, item) => accumulator += '/ ' + item + ' ', '')
  }

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addEventListener('change', handler);
    return () => mediaMatch.removeEventListener('change', handler);
  }, []);

  const popUpPosition = (isDesktop) => isDesktop ? 'top left' : 'top center'
  const stylesPopUp = (isDesktop) => isDesktop ? { fontSize: '1rem' } : { fontSize: '0.8rem' }

  const thereIsInfo = ({ email, phone, website, firstDay }) => email || phone || website || firstDay
  
  return (
    <Segment id={distributor.id} raised className={styles.Distributor}>
      { !distributor.email &&
        <span className={styles['IncompleteTag']}>{t('incomplete_info', { ns: 'distributor' })}</span>
      }
      <Grid className={styles['Distributor-Grid']}>
        <Grid.Row>
          <Grid.Column className={styles.Details} style={{ padding: '0 10px' }}>
          <p className={cx(styles.Name, !distributor.email ? styles.CustomName : '')}>{distributor.name}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className='distributor-logo'>
            <img
              src={distributor.distrPhotoURL ? distributor.distrPhotoURL : placeholderSrc}
              alt={distributor.name}
            />
          </Grid.Column>
          { thereIsInfo({ ...distributor, firstDay }) &&
            <Grid.Column className="distributor-information">
              { distributor.email !== '' &&
                <dl>
                  <dt>
                    <Icon name="mail" color="grey" />
                  </dt>
                  <dd className="wrap">{distributor.email}</dd>
                </dl>
              }
              { distributor.phone !== '' &&
                <dl>
                  <dt>
                    <Icon className={styles.PhoneIcon} name="phone" color="grey" />
                  </dt>
                  <dd>{distributor.phone}</dd>
                </dl>
              }
              { distributor.website !== '' &&
                <dl>
                  <dt>
                    <Icon name="computer" color="grey" />
                  </dt>
                  <dd className="wrap">{distributor.website}</dd>
                </dl>
              }
              { firstDay &&
                <dl>
                  <dt>
                    <Icon style={{ marginTop: '4px' }} name="clock outline" color="grey" />
                  </dt>
                  <dd className={styles.OpeningHours}>
                    {firstDay && (
                      <span className={cx(styles.DayLabel, ((openingHoursKeys.length === 1) && (!sortedOpeningHours[openingHoursKeys[0]].extraInfo)) ? styles.DayLabel_NoExtraInfo : '')}>
                        <Trans
                          t={t}
                          ns={'distributor'}
                          i18nKey={getDayKey(firstDay)}
                        >
                          <Label circular color="green" style={{ marginRight: '5px' }}>
                            {firstDay}
                          </Label>
                        </Trans>
                        {sortedOpeningHours[firstDay].openFrom} - {sortedOpeningHours[firstDay].openTo}
                        {((openingHoursKeys.length > 1) || ((openingHoursKeys.length === 1) && (sortedOpeningHours[openingHoursKeys[0]].extraInfo !== null))) && (
                          <Popup
                            style={stylesPopUp(matches)}
                            content={openingHoursKeys.map((key, index) => {
                              return (
                                <div key={key + index} className={styles.OpenHoursPopUp}>
                                  <Trans
                                    t={t}
                                    ns={'distributor'}
                                    i18nKey={getDayKey(key)}
                                  >
                                    <Label circular color="green" style={{ marginRight: '5px' }}>
                                      {key}
                                    </Label>
                                  </Trans>
                                  <span style={{ display: 'inline-block', marginRight: '15px' }}>
                                    {sortedOpeningHours[key].openFrom} - {sortedOpeningHours[key].openTo} {renderExtraInfo(sortedOpeningHours[key].extraInfo)} 
                                  </span>
                                </div>
                              )
                            })}
                            trigger={
                              <Label as="a" circular>
                                +
                              </Label>
                            }
                            position={popUpPosition(matches)}
                          />
                        )}
                      </span>
                    )}
                  </dd>
                </dl>
              }
              { distributor.representativeName &&
                <dl>
                  <dd>
                    <Accordion items={[distributor.representativeName, distributor.representativePhone, distributor.representativeEmail]} />
                  </dd>
                </dl>
              }
            </Grid.Column>
          }
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button
              color="green"
              basic
              content={t('edit', { ns: 'buttons' })}
              onClick={() => {
                localStorage.setItem('target', distributor.id)
                props.history.push(`${props.path}/edit/${distributor.id}`, { target: distributor.id })
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default Distributor
