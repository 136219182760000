import React from 'react'
import { Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import TextInput from '../../UI/Form/TextInput'
import TextInputWithAmountControls from '../../UI/Form/TextInputWithAmountControls'

const StockItem = (props) => {
  const { t } = useTranslation(['stock'])

  return (
    <Table.Row verticalAlign="bottom">
      <Table.Cell>
        <TextInput fluid name={`items.${props.index}.name`} placeholder="Item Name" />
      </Table.Cell>
      <Table.Cell>
        <TextInput
          fluid
          name={`items.${props.index}.itemNumber`}
          textLabel={t('item_number', { ns: 'stock' })}
          placeholder={props.item.hasMultipleDistributors ? t('multiple_distributors', { ns: 'stock' }) : t('item_number', { ns: 'stock' })}
          disabled={props.item.hasMultipleDistributors}
        />
      </Table.Cell>
      <Table.Cell textAlign="center" verticalAlign="middle">
        <div className="field">
          <label>{t('status', { ns: 'stock' })}</label>
          {(props.item && props.item.isArchived) === true ? t('archived', { ns: 'stock' }) : t('active', { ns: 'stock' })}
        </div>
      </Table.Cell>
      <Table.Cell textAlign="center">
        <TextInputWithAmountControls
          name={`items.${props.index}.amount`}
          mobileLabel={t('amount_in_stock', { ns: 'stock' })}
          type="number"
          inputmode="decimal"
          placeholder={t('amount', { ns: 'stock' })}
          label={props.unit}
          labelPosition="right"
          fieldValueSetter={props.fieldValueSetter}
          currentFieldName={`items.${props.index}.amount`}
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default StockItem
