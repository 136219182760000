import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
//import { initializeApp } from 'firebase/app'; //v9
import firebase from './config/firebase'
import configureStore, { history } from './configureStore'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import { verifyAuth } from './store/actions'
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import App from './App'
import home_de from "./translations/de/home.json";
import home_en from "./translations/en/home.json";
import sidebar_de from "./translations/de/sidebar.json";
import sidebar_en from "./translations/en/sidebar.json";
import orders_de from "./translations/de/orders.json";
import orders_en from "./translations/en/orders.json";
import menu_de from "./translations/de/menu.json";
import menu_en from "./translations/en/menu.json";
import buttons_de from "./translations/de/buttons.json";
import buttons_en from "./translations/en/buttons.json";
import location_de from "./translations/de/location.json";
import location_en from "./translations/en/location.json";
import distributor_de from "./translations/de/distributor.json";
import distributor_en from "./translations/en/distributor.json";
import dashboard_de from "./translations/de/dashboard.json";
import dashboard_en from "./translations/en/dashboard.json";
import settings_de from "./translations/de/settings.json";
import settings_en from "./translations/en/settings.json";
import validationMessages_de from "./translations/de/validationMessages.json";
import validationMessages_en from "./translations/en/validationMessages.json";
import stock_de from "./translations/de/stock.json";
import stock_en from "./translations/en/stock.json";
import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import greenbytes_de from "./translations/de/greenbytes.json";
import greenbytes_en from "./translations/en/greenbytes.json";
import pos_de from "./translations/de/pos.json";
import pos_en from "./translations/en/pos.json";
import imageUpload_de from "./translations/de/imageUpload.json";
import imageUpload_en from "./translations/en/imageUpload.json";
import changeEmail_de from "./translations/de/changeEmail.json";
import changeEmail_en from "./translations/en/changeEmail.json";
import changePassword_de from "./translations/de/changePassword.json";
import changePassword_en from "./translations/en/changePassword.json";
import errorMessages_de from "./translations/de/errorMessages.json";
import errorMessages_en from "./translations/en/errorMessages.json";
import layout_de from "./translations/de/layout.json";
import layout_en from "./translations/en/layout.json";
import SecurityWarning from './SecurityWarning'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import 'semantic-ui-less/semantic.less'
import './assets/styles/main.scss'

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  enableClaims: true, // Get custom claims along with the profile
}

export const store = configureStore()

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
}

store.dispatch(verifyAuth())

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
      en: {
          home: home_en,
          sidebar: sidebar_en,
          orders: orders_en,
          menu: menu_en,
          buttons: buttons_en,
          location: location_en,
          distributor: distributor_en,
          dashboard: dashboard_en,
          settings: settings_en,
          validationMessages: validationMessages_en,
          stock: stock_en,
          common: common_en,
          greenbytes: greenbytes_en,
          pos: pos_en,
          imageUpload: imageUpload_en,
          changeEmail: changeEmail_en,
          changePassword: changePassword_en,
          errorMessages: errorMessages_en,
          layout: layout_en
      },
      de: {
          home: home_de,
          sidebar: sidebar_de,
          orders: orders_de,
          menu: menu_de,
          buttons: buttons_de,
          location: location_de,
          distributor: distributor_de,
          dashboard: dashboard_de,
          settings: settings_de,
          validationMessages: validationMessages_de,
          stock: stock_de,
          common: common_de,
          greenbytes: greenbytes_de,
          pos: pos_de,
          imageUpload: imageUpload_de,
          changeEmail: changeEmail_de,
          changePassword: changePassword_de,
          errorMessages: errorMessages_de,
          layout: layout_de
      },
  },
});

if(window.self === window.top) {
  ReactDOM.render(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ConnectedRouter history={history}>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </ConnectedRouter>
      </ReactReduxFirebaseProvider>
    </Provider>,
    document.getElementById('root')
  )
} else {
  ReactDOM.render(<SecurityWarning website={window?.location?.host} href={window?.location?.href} />, document.getElementById('root'))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
