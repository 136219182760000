import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setPageTitle } from '../../store/actions'
import OrderPerDistributor from './Order/OrderPerDistributor'
import OrderSign from './Order/OrderSign'
import { Button } from 'semantic-ui-react'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../config/firebase'
import { orderCreatedSuccess } from '../../store/actions/orderActions'
import { asyncActionFinish, asyncActionStart, asyncActionError } from '../../store/actions/asyncActions'
import { listenToDistributors, getDistributorsFromFirestore } from '../../store/actions'
import { getUserId } from '../../store/actions/authActions'
import useFirestoreCollection from '../../hooks/useFirestoreCollection'
import _ from 'lodash'

const OrderSummary = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['orders', 'buttons'])

  const [ordersByDistributor, setOrdersByDistributor] = useState([])
  const { orderInProgress } = useSelector((state) => state.order)
  const { loading } = useSelector((state) => state.async)
  const { locations } = useSelector((state) => state.location)
  const [selectedLocationInfo, setSelectedLocationInfo] = useState(null)
  const [isFormErrors, setIsFormErrors] = useState(true)
  const [formErrors, setFormErrors] = useState({})
  const [signError, setSignError] = useState(true)

  useEffect(() => {
    dispatch(setPageTitle(t('summary', { ns: 'orders' })))
  }, [dispatch, history])

  useFirestoreCollection({
    query: () => getDistributorsFromFirestore(getUserId()),
    data: (distributors) => dispatch(listenToDistributors(distributors)),
    deps: [dispatch],
  })

  if (!orderInProgress) history.goBack()

  useEffect(() => {
    let fullLocationInfo = locations.find((loc) => loc.id === orderInProgress.location.id)
    setSelectedLocationInfo(fullLocationInfo)
  }, [orderInProgress.location])

  const getItemsPerDistributor = (stock) => {
    const groupedOrders = _.groupBy(stock, 'distributorId')
    const distributorIds = Object.keys(groupedOrders)

    const ordersPerDistributor = distributorIds.map((distributorId) => {
      const items = groupedOrders[distributorId]
      return {
        id: distributorId,
        distributorName: items[0].multiDistributors ? items[0].multiDistributors[distributorId][0].distributor_name : items[0]?.distributor,
        items,
      }
    })

    return ordersPerDistributor
  }

  useEffect(() => {
    const orders = getItemsPerDistributor(orderInProgress.orderItems)
    setOrdersByDistributor(
      orders.map((order) => {
        return { ...order }
      })
    )

    // Update order type
    orderInProgress.delivery = orders.map((order) => order.id)

    // Update Order in progress notes
    orderInProgress.notes = orders.map((order) => {
      return {
        id: order.id,
        note: '',
      }
    })

    // Update Order in progress delivery dates
    orderInProgress.deliveryDate = orders.map((order) => {
      return {
        id: order.id,
        date: '',
      }
    })
  }, [])

  const updateOrderType = (distributorId, orderType) => {
    if (orderType === 'delivery' && !orderInProgress.delivery.includes(distributorId)) {
      orderInProgress.delivery.push(distributorId)
      orderInProgress.pickUp = orderInProgress.pickUp.filter((order) => order !== distributorId)
    } else if (orderType === 'pickup' && !orderInProgress.pickUp.includes(distributorId)) {
      orderInProgress.pickUp.push(distributorId)
      orderInProgress.delivery = orderInProgress.delivery.filter((order) => order !== distributorId)
    }
  }

  const updateOrderNotes = (distributorId, note) => {
    const index = orderInProgress.notes.findIndex((note) => note.id === distributorId)
    orderInProgress.notes[index].note = note
  }

  const updateOrderDeliveryDate = (distributorId, date) => {
    const index = orderInProgress.deliveryDate.findIndex((order) => order.id === distributorId)
    orderInProgress.deliveryDate[index].date = date
  }

  const updateOrderSign = (sign) => {
    orderInProgress.sign = sign
  }

  const validateErrors = (hasError, orderId) => {
    setFormErrors({ ...formErrors, [orderId]: hasError })
    isValidForm({ ...formErrors, [orderId]: hasError })
  }

  const isValidForm = (errors) => {
    const values = Object.values(errors)
    const hasSameLength = values.length === ordersByDistributor.length
    const formHasErrors = !!values.find((value) => value === true) || !hasSameLength
    setIsFormErrors(formHasErrors)
  }

  const addIdProductOnboarding = (orderItems) => {
    return orderItems.map(item => ({...item, id_product_onboarding: item.productIds ? item.productIds[item.distributorId] : null}))
  }

  const createOrders = async () => {
    orderInProgress.orderItems = addIdProductOnboarding(orderInProgress.orderItems)
    try {
      dispatch(asyncActionStart())
      const res = await httpsCallable(
        cloudFunctions,
        'placeOrders'
      )({ ...orderInProgress, location: selectedLocationInfo })
      if (res.data.length) {
        dispatch(orderCreatedSuccess(res.data))
        history.replace('/orders/success')
      }
      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError(error, ''))
    }
  }

  return (
    <>
      {ordersByDistributor &&
        ordersByDistributor.map((order) => (
          <div key={order.id}>
            <OrderPerDistributor
              order={order}
              orderInProgress={orderInProgress}
              selectedLocationInfo={selectedLocationInfo}
              updateOrderType={updateOrderType}
              updateOrderNotes={updateOrderNotes}
              updateOrderDeliveryDate={updateOrderDeliveryDate}
              validateErrors={validateErrors}
              setFormErrors={(orderId) => {
                if (!formErrors.includes(orderId)) setFormErrors([...formErrors, orderId])
              }}
            />
          </div>
        ))}
      <OrderSign
        updateOrderSign={updateOrderSign}
        setSignError={setSignError}
      />
      <div className="flex space-between gap-16">
        <Button
          type="button"
          basic
          color="red"
          onClick={() => history.goBack()}
          content={t('back', { ns: 'buttons' })}
          disabled={loading}
        />
        <Button
          content={t('confirm', { ns: 'buttons' })}
          size="big"
          color="green"
          onClick={createOrders}
          disabled={loading || isFormErrors || signError}
          loading={loading}
        />
      </div>
    </>
  )
}

export default OrderSummary
