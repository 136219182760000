import React, { useState, useEffect } from 'react'
import { Table, Dropdown } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { formatNumber } from '../../../shared/utility'
import styles from '../../../assets/styles/modules/orders/OrderDistributorItem.module.scss'

const OrderDistributorItem = ({ item, currentOrder, distributorsOptions, fieldValueSetter, currentFieldName }) => {
  const [distributorId, setDistributorId] = useState('')
  const [itemAmount, setItemAmount] = useState(null)
  const { t } = useTranslation(['orders'])

  useEffect(() => {
    if (item.distributors) {
      setDistributorId(Object.keys(item.distributors)[0])
    }
  }, [item.distributors])

  useEffect(() => {
    const currentItem = currentOrder && currentOrder.find((el) => el.itemId === item.itemId)
    if (currentItem) {
      setItemAmount(currentItem.amount)
    }
  }, [currentOrder, item])

  return (
    <Table.Row>
      <Table.Cell width={6}>
        <p className="large">{item.name}</p>
      </Table.Cell>
      <Table.Cell width={6} verticalAlign="middle" textAlign="center">
        {distributorsOptions ? (
          <Dropdown
            className={styles.DistributorDropdown}
            placeholder={t('select_a_distributor', { ns: 'orders'})}
            clearable
            id={item.itemId}
            selection
            search
            options={distributorsOptions}
            onChange={(e, { value }) => {
              fieldValueSetter(currentFieldName, { ingredient: item.name, selectedDistributor: value.distributor, selectedProduct: value.product })
            }}
            fluid
          />
        ) : (
          <p>{item.distributor}</p>
        )}
      </Table.Cell>
      <Table.Cell width={4} verticalAlign="middle" textAlign="center">
        <p className="large">
          {itemAmount ? itemAmount : formatNumber(item.amount, item.unit)}{' '}
          { item.unit
            ? t(`unit_${item.unit}`, { ns: 'orders' })
            : item.orderUnit
            ? t(`unit_${item.orderUnit}`, { ns: 'orders' })
            : t(`unit_${item.distributors[distributorId].orderUnit}`, { ns: 'orders' })}
        </p>
      </Table.Cell>
    </Table.Row>
  )
}

export default OrderDistributorItem
