import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { onSnapshot } from 'firebase/firestore'
import { asyncActionStart, asyncActionError, asyncActionFinish } from '../store/actions/asyncActions'
import {
  setFirstDocOfCollection,
  setCurrentPage,
  setPageCounters,
  endOfCollectionReached,
} from '../store/actions/paginationActions'
import { getDataFromSnapshot } from '../shared/firestoreService'

export default function usePagination({ query, data, pageLoadingData, limit, cursor, deps }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(asyncActionStart())
    const unsubscribe = onSnapshot(
      query(),
      (snapshot) => {
        if (snapshot.docs.length) {
          //Set current page parameters
          const firstVisible = snapshot.docs[0]
          const pageLength = snapshot.docs.length
          const moreItems = pageLength > limit // compare with limit

          // Set last doc in the current batch
          const lastVisiblePos = moreItems ? pageLength - 2 : pageLength - 1
          const lastVisible = snapshot.docs[lastVisiblePos]

          const firstOrderCount = cursor * limit + 1
          const lastOrderCount = moreItems ? cursor * limit + (pageLength - 1) : cursor * limit + pageLength

          dispatch(setCurrentPage(firstVisible, lastVisible, moreItems))

          if (!pageLoadingData.startAt) {
            dispatch(setFirstDocOfCollection(firstVisible))
          }
          dispatch(setPageCounters(firstOrderCount, lastOrderCount))

          const docs = snapshot.docs.map((doc) => getDataFromSnapshot(doc))

          if (moreItems) docs.pop()

          data(docs)
        } else {
          // Keep last page state as it is
          dispatch(endOfCollectionReached())
        }
        dispatch(asyncActionFinish())
      },
      (error) => {
        dispatch(asyncActionError(error, 'Data could not be loaded.', 'usePagination: '))
      }
    )

    return () => {
      unsubscribe()
    }
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}
