import * as actionTypes from './actionTypes'

export const setFirstDocOfCollection = (firstDocOfCollection) => {
  return { type: actionTypes.SET_FIRST_DOC_OF_COLLECTION, firstDocOfCollection }
}

export const setCurrentPage = (firstDocOfPage, lastDocOfPage, moreItems) => {
  return { type: actionTypes.SET_CURRENT_PAGE, firstDocOfPage, lastDocOfPage, moreItems }
}

export const setPageCounters = (firstOrderCount, lastOrderCount) => {
  return { type: actionTypes.SET_PAGE_COUNTERS, firstOrderCount, lastOrderCount }
}

export const endOfCollectionReached = () => {
  return { type: actionTypes.END_OF_COLLECTION }
}
