import React from 'react'
import { FormField, Label, Input, Button } from 'semantic-ui-react'
import { useField } from 'formik'
import { useTranslation, Trans } from 'react-i18next'
import { addToNumber, subtractFromNumber } from '../../../shared/utility'
import cx from 'classnames'
import styles from '../../../assets/styles/modules/UI/ControlInput.module.scss'

const TextInputWithAmountControls = (props) => {
  const [field, meta] = useField(props)
  const { t } = useTranslation(['orders'])

  const cleanProps = { ...props }
  delete cleanProps.label
  delete cleanProps.mobileLabel
  delete cleanProps.style
  delete cleanProps.className
  delete cleanProps.fieldValueSetter
  delete cleanProps.currentFieldName
  delete cleanProps.currentFieldValue

  return (
    <FormField error={meta.touched && !!meta.error} style={props.style} className={props.className}>
      {props.textLabel ? <label>{props.textLabel}</label> : null}
      <Input
        className={styles.ControlInput}
        fluid
        action
        labelPosition="right"
        type="text"
        size="large"
        {...field}
        {...cleanProps}
      >
        <div className='input-container'>
          <input type='number' inputmode='decimal' className='controls-input' value={field.value === 0 ? '' : field.value} onChange={(e) => {
            props.fieldValueSetter(props.currentFieldName, Number(e.target.value))
          }} />
        </div>
        {props.label ? <Trans t={t} ns={'orders'} i18nKey={props.label}><Label className='input-label'>{props.label}</Label></Trans> : null}
        <div className='buttons-container'>
          <Button
            className={cx(styles.ButtonContainerButton, 'reduce-button')}
            type="button"
            color="orange-custom"
            basic
            icon="minus"
            title="Reduce"
            disabled={props.disabled}
            onClick={() => {
              props.fieldValueSetter(props.currentFieldName, field.value ? subtractFromNumber(Number(field.value), 1) : 0)
            }}
          />
          <Button
            className={cx(styles.ButtonContainerButton, 'increase-button')}
            type="button"
            color="green-custom"
            icon="plus"
            title="Increase"
            disabled={props.disabled}
            onClick={() => {
              props.fieldValueSetter(props.currentFieldName, field.value ? addToNumber(Number(field.value), 1) : 1)
            }}
          />
        </div>
      </Input>

      {meta.touched && meta.error ? <Label pointing content={meta.error} /> : null}
    </FormField>
  )
}

export default TextInputWithAmountControls
