import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Sidebar from '../../components/Navigation/Sidebar'
import logoSrc from '../../assets/images/gb-logo-linear-white.svg'
import backbtnSrc from '../../assets/images/back-btn.svg'
import { Message, Portal, Icon } from 'semantic-ui-react'
import { setNotification } from '../../store/actions/notificationActions'
import styles from '../../assets/styles/modules/Layout.module.scss'
import { cloudFunctions } from '../../config/firebase'
import { MAIN_PAGES, DISTRIBUTOR_TOASTER_PAGES, EDIT_DISTRIBUTOR_PAGE } from '../../shared/utility'
import { httpsCallable } from 'firebase/functions'
import cx from 'classnames'

const Layout = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isAuthenticated, currentLocation } = useSelector((state) => state.auth)
  const { pageTitle, subtitle } = useSelector((state) => state.nav)
  const notification = useSelector((state) => state.notification)
  const { selectedLocation } = useSelector((state) => state.location)
  const [showToaster, setShowToaster] = useState(false)
  const { t } = useTranslation(['layout'])
  const target = history.location?.state?.target
  const cursor = history.location?.state?.cursor

  const [showSidebar, setShowSidebar] = useState(false)

  const curPath = currentLocation.pathname.slice(1) //just to remove the first forward slash

  useEffect(() => {
    const checkDistributors = async () => {
      const distributors = await httpsCallable(cloudFunctions, 'getDistributors')()
      setShowToaster(distributors.data.some(dist => dist.email === ''))
    }

    checkDistributors()
  }, [isAuthenticated])

  const closeSidebarHandler = () => {
    setShowSidebar(false)
  }

  const sidebarToggleHandler = () => {
    setShowSidebar(!showSidebar)
  }

  const getPageTitle = () => {
    return pageTitle !== null && pageTitle.length ? (
      <h1>{pageTitle}</h1>
    ) : (
      <div className="logo">
        <img src={logoSrc} alt="GreenBytes" />
      </div>
    )
  }

  const clearNotification = () => dispatch(setNotification(null))

  const getPageSubtitle = () => {
    return subtitle ? <p>{subtitle}</p> : null
  }

  let sideBar = null
  if (isAuthenticated) {
    sideBar = <Sidebar open={showSidebar} closed={closeSidebarHandler} />
  }

  let hamburger = null
  if (isAuthenticated) {
    hamburger = (
      <div className="hamburger flex h-100" onClick={sidebarToggleHandler}>
        <div />
        <div />
        <div />
      </div>
    )
  }

  let mainStyles = 'main'
  let dashboardStyles = 'dashboard'
  let backBtnDestination = null
  if (curPath.length) {
    const re = new RegExp(
      /.*(orders\/view|menu\/view|menu\/edit|menu\/combo\/view|menu\/combo\/edit|distributors\/edit|distributors\/add|locations\/edit|locations\/add|provider-information|manage-premium|company-details|stock\/edit\/item).*/
    )
    const match = curPath.match(re)
    const subfolders = curPath.split('/')

    if (match !== null) {
      switch (match[1]) {
        case 'orders/view':
          backBtnDestination = '/orders'
          break
        case 'menu/combo/view':
        case 'menu/view':
          backBtnDestination = '/menu'
          break
        case 'menu/combo/edit':
          if (!subfolders.includes('location')) {
            backBtnDestination = '/menu/combo/view/' + subfolders.at(-1)
          } else {
            backBtnDestination = '/menu/view/' + subfolders[2] + '/location/' + subfolders.at(-1)
          }
          break
        case 'distributors/edit':
        case 'distributors/add':
          backBtnDestination = '/distributors'
          break
        case 'locations/edit':
        case 'locations/add':
          backBtnDestination = '/locations'
          break
        case 'provider-information':
          backBtnDestination = '/settings'
          break
        case 'manage-premium':
          backBtnDestination = '/settings'
          break
        case 'company-details':
          backBtnDestination = '/settings'
          break
        case 'stock/edit/item':
          if (history?.location?.state?.backBtn) {
            backBtnDestination = history.location.state.backBtn
          } else {
            backBtnDestination = '/stock'
          }
          break
        default:
          backBtnDestination = null
      }
    }

    if (curPath === 'login' || curPath === 'signup') {
      mainStyles += ' login-area'
    } else if (curPath.includes('stock/edit')) {
      mainStyles += ' edit-stock'
    } else if (curPath.includes('stock')) {
      mainStyles += ' stock'
    } else if (curPath.includes('orders/create')) {
      mainStyles += ' order-form'
    }
  }

  return (
    <>
      <header>
        <div className="topbar flex fixed w-100">
          {backBtnDestination && (
            <div className="back-btn" onClick={() => history.push(backBtnDestination, { target, cursor })}>
              <img src={backbtnSrc} alt="Go Back" />
            </div>
          )}
          <div className={`page-title flex w-100 h-100 center-content ${subtitle ? 'padding-inherit' : ''}`}>
            {getPageTitle()}
            {getPageSubtitle()}
          </div>
          {hamburger}
        </div>
        { selectedLocation &&
          <div className={styles.SelectedLocation}>
            <span><Icon name="map marker alternate" color="#204D36" /> {selectedLocation.name}</span>
            { MAIN_PAGES.includes(curPath) &&
              <Link to="/locations">{t('change_location', { ns: 'layout' })} <Icon name="chevron right" color="#204D36" /></Link>
            }
          </div>
        }
      </header>
      {sideBar}
      <main className={cx(history.location.pathname === '/' || history.location.pathname.includes('dashboard') ? dashboardStyles : mainStyles, selectedLocation ? 'selected-location' : '')}>
        {props.children}
        <Portal open={!!notification}>
          <Message
            visible
            info
            floating
            onDismiss={clearNotification}
            className="global-notification"
            header={notification?.title}
            content={notification?.body}
          />
        </Portal>
      </main>
      { !DISTRIBUTOR_TOASTER_PAGES.includes(curPath) && !curPath.includes(EDIT_DISTRIBUTOR_PAGE) && showToaster &&
        <Message className={styles.Toaster} onDismiss={() => setShowToaster(false)}>{t('complete_distributor_profile', { ns: 'layout' })} <Link to="/distributors">{t('view', { ns: 'layout' })}</Link></Message>
      }
    </>
  )
}

export default Layout
