import * as actionTypes from './actionTypes'
import { firestore } from '../../config/firebase'
import { query, collection, orderBy, where } from 'firebase/firestore'
import { getUserId } from './authActions'

export const listenToStock = (stock) => {
  return {
    type: actionTypes.GET_STOCK_SUCCESS,
    stock: stock,
  }
}

/**
 * Fetch all stock items, with sorting
 *
 * @param String sortByField
 * @param String sortByDirection
 * @return {firebase.firestore.Query<firebase.firestore.DocumentData>}
 */
export function getStockFromFirestore(locationId = null) {
  const stockCollection = !locationId
    ? `users/${getUserId()}/stock`
    : `users/${getUserId()}/locations/${locationId}/stock`

  return query(
    collection(firestore, stockCollection),
    where('deleted', '!=', true),
    orderBy('deleted', 'asc'),
    orderBy('name', 'asc')
  )
}
