import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { onSnapshot } from 'firebase/firestore'
import { useTranslation } from 'react-i18next'
import { getOpenOrdersFromFirestore, getFaultyOrdersFromFirestore } from '../../../store/actions/orderActions'
import { asyncActionError } from '../../../store/actions'
import { getDataFromSnapshot } from '../../../shared/firestoreService'
import { Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

const OpenOrders = () => {
  const dispatch = useDispatch()
  const { selectedLocation } = useSelector((state) => state.location)
  const [undeliveredOrders, setUndeliveredOrders] = useState([])
  const [faultyOrders, setFaultyOrders] = useState([])
  const { t } = useTranslation(['orders'])

  useEffect(() => {
    const unsubscribe = onSnapshot(
      getOpenOrdersFromFirestore(selectedLocation),
      (snapshot) => {
        const docs = snapshot.docs.map((doc) => getDataFromSnapshot(doc))
        setUndeliveredOrders(docs)
      },
      (error) =>
        dispatch(asyncActionError(error, t('data_could_not_be_loaded', { ns: 'orders' }), 'OpenOrders::getOpenOrdersFromFirestore: '))
    )
    return () => {
      unsubscribe()
    }
  }, [dispatch])

  useEffect(() => {
    const unsubscribe = onSnapshot(
      getFaultyOrdersFromFirestore(),
      (snapshot) => {
        const docs = snapshot.docs.map((doc) => getDataFromSnapshot(doc))
        setFaultyOrders(docs)
      },
      (error) =>
        dispatch(asyncActionError(error, t('data_could_not_be_loaded', { ns: 'orders' }), 'OpenOrders::getOpenOrdersFromFirestore: '))
    )
    return () => {
      unsubscribe()
    }
  }, [dispatch])

  return (
    <div>
      {faultyOrders.length > 0 && (
        <Message warning style={{ textAlign: 'left' }}>
          <Message.Content>
            <Message.Header>{t('orders_could_not_be_sent', { size: faultyOrders.length, ns: 'orders' })}</Message.Header>
            {t('try_re_sending', { ns: 'orders' })}&nbsp;
            <Link to="/orders" style={{ fontWeight: 'bold' }}>
              {t('go_to', { ns: 'orders' })}
            </Link>
          </Message.Content>
        </Message>
      )}
      {undeliveredOrders.length > 0 && (
        <Message style={{ textAlign: 'left' }}>
          <Message.Content>
            { undeliveredOrders.length === 1 ?
              <Message.Header>{t('undelivered_order', { ns: 'orders' })}</Message.Header>
              :
              <Message.Header>{t('undelivered', { amount: undeliveredOrders.length, ns: 'orders' })}</Message.Header>
            }
            {t('received_question', { ns: 'orders' })}{' '}
            <Link to="/orders" style={{ color: '#4DB894' }}>
            {t('go_to', { ns: 'orders' })}
            </Link>
          </Message.Content>
        </Message>
      )}
    </div>
  )
}

export default OpenOrders
