import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import styles from '../../../assets/styles/modules/UI/Accordion.module.scss'

const Accordion = ({ items }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation(['distributor'])
  
  return (
    <div className={styles.AccordionContainer}>
      <span className={styles.AccordionTitle} onClick={() => setOpen(!open)}>
        <h3>{t('representative_details', { ns: 'distributor' })}</h3>
        { open ?
          <Icon name="angle up" className={styles.Icon} />
          :
          <Icon name="angle down" className={styles.Icon} />
        }
      </span>
      { open && items.map((text) => <p className={cx(styles.Accordion, styles.Item)}>{text}</p>)}
    </div>
  )
}

export default Accordion
