import React from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownToHtml = ({ markdown }) => {
    return (
        <ReactMarkdown>
            {markdown}
        </ReactMarkdown>
    )
}

export default MarkdownToHtml;