import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  menuItems: [],
  menuItem: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MENU_SUCCESS:
      return updateObject(state, { menuItems: action.menuItems })
    case actionTypes.GET_MENU_ITEM_SUCCESS:
      return updateObject(state, { menuItem: action.menuItem })
    default:
      return state
  }
}

export default reducer
