import React from 'react'
import { Checkbox, FormField, Label } from 'semantic-ui-react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import styles from '../../../assets/styles/modules/UI/CheckboxInput.module.scss'

const CheckboxInput = ({ defaultValue, isBatchItem = false, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const { t } = useTranslation(['menu'])
  const cleanProps = { ...props }
  delete cleanProps.prefix
  if (isBatchItem) {
    delete props.label
  }

  return (
    <FormField>
      {props.prefix && <span className="checkbox-prefix">{props.prefix}</span>}
      { isBatchItem && <span className={styles.BatchItemTag}>{t('batch_item', { ns: 'menu' })}&nbsp;&nbsp;</span>}
      <Checkbox
        className={styles.CheckboxInput}
        {...props}
        checked={defaultValue || !!field.checked}
        onChange={(e, d) => {
          props.onChange?.({ event: e, value: !!d.checked })
          // Disabled bugfix
          if (!props.disabled) helpers.setValue(!!d.checked)
        }}
        onBlur={() => helpers.setTouched(true)}
      />
      {meta.touched && meta.error ? <Label pointing content={meta.error} /> : null}
    </FormField>
  )
}

export default CheckboxInput
