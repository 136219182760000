import React, { useState, useEffect } from 'react'
import SelectInput from './SelectInput'
import TextInput from './TextInput'
import _ from 'lodash'

const CustomDropdown = (props) => {
  const [activeElementType, setActiveElementType] = useState('')

  const activateElementType = () => {
    if ((!_.isNil(props.isFromStock) && !props.isFromStock) || (props.options?.length === 1 && (props.currentStep === 'second-step' || props.currentStep === '/stock/add' || props.currentStep === '/stock/edit/item') && props.distributorSelected && !props.editStockFlow)) {
      setActiveElementType('input')
    } else {
      setActiveElementType('dropdown')
    }
  }

  useEffect(() => {
    activateElementType(props.isFromStock)
    return () => {
      setActiveElementType('')
    }
  }, [props.fromStock, props.isFromStock, props.options, props.distributorSelected])

  const dropdownChanged = (value, customValue) => {
    switch (value) {
      case '':
        setActiveElementType('input')
        if (props.shouldBeChecked) props.fieldValueSetter(props.shouldBeChecked, true)
        if (props.customDistributor) props.fieldValueSetter(props.customDistributor, true)
        if (props.fromStock) props.fieldValueSetter(props.fromStock, false)
        props.fieldValueSetter(props.fieldValueToSet, '')
        if (props.toggleEnterRef) props.toggleEnterRef.current = false
        if (props.setDistributor) props.setDistributor('')
        break
      case 'custom-value':
        setActiveElementType('input')
        if (props.shouldBeChecked) props.fieldValueSetter(props.shouldBeChecked, true)
        if (props.customDistributor) props.fieldValueSetter(props.customDistributor, true)
        if (props.fromStock) props.fieldValueSetter(props.fromStock, false)
        props.fieldValueSetter(props.fieldValueToSet, customValue)
        if (props.toggleEnterRef) props.toggleEnterRef.current = false
        if (props.setDistributor) props.setDistributor(customValue)
        if (props.setDistributorChanged) props.setDistributorChanged(true)
        break
      case 'close-input':
        setActiveElementType('dropdown')
        if (props.shouldBeChecked) props.fieldValueSetter(props.shouldBeChecked, false)
        if (props.customDistributor) props.fieldValueSetter(props.customDistributor, false)
        if (props.fromStock) props.fieldValueSetter(props.fromStock, true)
        if (props.toggleEnterRef) props.toggleEnterRef.current = false
        break
      default:
        setActiveElementType('dropdown')
        if (props.shouldBeChecked) props.fieldValueSetter(props.shouldBeChecked, false)
        if (props.customDistributor) props.fieldValueSetter(props.customDistributor, false)
        if (props.fromStock) props.fieldValueSetter(props.fromStock, true)
        if (props.toggleEnterRef) props.toggleEnterRef.current = true
        if (props.distributorFlow) props.fieldValueSetter(props.fieldValueToSet, value)
        if (props.setDistributor) props.setDistributor(value)
        if (props.setDistributorChanged) props.setDistributorChanged(true)
        break
    }
    if (props.buttonRef && props.buttonRefKind) props.buttonRef.current = props.buttonRefKind
  }

  return (
    <>
      {activeElementType === 'dropdown' ? (
        <SelectInput {...props} disabled={props.disabled} dropdownChanged={dropdownChanged} additionsAllowed={true} alterText={props.alterText} currentStep={props.currentStep} resetValue={props.loading && !props.doNotReset} />
      ) : (
        <>
          <TextInput
            name={props.fieldValueToSet}
            placeholder={props.placeholder instanceof Function ? props.placeholder() : props.placeholder}
            type="text"
            autoFocus={(!(props.placeholder instanceof Function) && (props.placeholder.toUpperCase() !== 'PRODUCT'))}
            dropdownChanged={dropdownChanged}
            stockPage={props.stockPage}
            firstStockPage={props.firstStockPage}
          />
          { props.showItemNumber && 
            <TextInput
              name={props.fieldItemNumberToSet}
              placeholder="Do you have an item number?"
              type="text"
            />
          }
        </>
      )}
    </>
  )
}

export default CustomDropdown
