import React from 'react'
import { FormField, Label } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'
import styles from '../../../assets/styles/modules/UI/DatePicker.module.scss'

const DateInput = (props) => {
  const [field, meta] = useField(props)
  const { t } = useTranslation(['validationMessages'])
  
  return (
    <FormField error={meta.touched && !!meta.error} className={styles.DatePicker}>
      <SemanticDatepicker
        placeholder={t('dd_mm_yyyy', { ns: 'validationMessages' })}
        onBlur={(event) => event.preventDefault()}
        minDate={props.minDate}
        format="DD.MM.YYYY"
        clearOnSameDateClick={false}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? <Label pointing content={meta.error} /> : null}
    </FormField>
  )
}

export default DateInput
