import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  results: [],
  value: '',
  searchData: [],
  searchResults: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_DATA:
      return updateObject(state, { searchData: action.searchData })
    case actionTypes.START_SEARCH:
      return updateObject(state, { value: action.query })
    case actionTypes.FINISH_SEARCH:
      return updateObject(state, { searchResults: action.results })
    case actionTypes.UPDATE_SELECTION:
      return updateObject(state, { value: action.selection })
    case actionTypes.CLEAR_SEARCH:
      return updateObject(state, {
        results: [],
        value: '',
        searchResults: [],
      })
    default:
      return initialState
  }
}

export default reducer
