import React from 'react'
import { FormField, Label } from 'semantic-ui-react'
import { useField } from 'formik'

const TextAreaInput = (props) => {
  const [field, meta] = useField(props)

  return (
    <FormField error={meta.touched && !!meta.error}>
      {props.label ? <label>{props.label}</label> : null}
      <textarea {...field} {...props} />
      {meta.touched && meta.error ? <Label pointing content={meta.error} /> : null}
    </FormField>
  )
}

export default TextAreaInput
