import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { asyncActionStart, asyncActionFinish, asyncActionError } from '../store/actions'
import { onSnapshot } from 'firebase/firestore'
import { getDataFromSnapshot } from '../shared/firestoreService'

export default function useFirestoreCollection({ query, data, deps, executeDispatch = true }) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (executeDispatch) dispatch(asyncActionStart())
    const unsubscribe = onSnapshot(
      query(),
      (snapshot) => {
        const docs = snapshot.docs.map((doc) => getDataFromSnapshot(doc))
        data(docs)
        if (executeDispatch) dispatch(asyncActionFinish())
      },
      (error) => {
        dispatch(asyncActionError(error, 'Data could not be loaded.', 'useFirestoreCollection: '))
      }
    )
    return () => {
      unsubscribe()
    }
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
}
