import * as actionTypes from './actionTypes'
import { firestore, auth } from '../../config/firebase'
import {
  browserLocalPersistence,
  browserSessionPersistence,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updateEmail,
  updatePassword,
  setPersistence,
  updateProfile,
} from 'firebase/auth'
import { doc, updateDoc } from 'firebase/firestore'
import { getUserId } from './authActions'

export const listenToUserProfile = (user) => {
  return {
    type: actionTypes.GET_USER_PROFILE_SUCCESS,
    user: user,
  }
}

export function getUserProfileFromFirestore() {
  return doc(firestore, 'users', auth.currentUser.uid)
}

export function changeUserPassword(values) {
  try {
    const user = auth.currentUser
    //return updatePassword(user, values.newPassword);
    reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email, values.currentPassword)).then((u) => {
      return updatePassword(user, values.newPassword)
    })
  } catch (error) {
    throw error
  }
}

export async function changeUserEmail(values) {
  try {
    const user = auth.currentUser

    await reauthenticateWithCredential(user, EmailAuthProvider.credential(user.email, values.currentPassword))
    // Update email in Firebase
    await updateEmail(user, values.newEmail)
    // Update email in Firestore
    return await updateDoc(doc(firestore, 'users', user.uid), { email: values.newEmail })
  } catch (error) {
    throw error
  }
}

export function updateUseSuggestionsSetting(useSuggestions) {
  return updateDoc(doc(firestore, 'users', getUserId()), {
    useSuggestions: useSuggestions,
  })
}

export async function updateSaveLoginSetting(saveLogin) {
  const persistence = saveLogin ? browserLocalPersistence : browserSessionPersistence

  await setPersistence(auth, persistence)
  return updateDoc(doc(firestore, 'users', getUserId()), {
    saveLogin: saveLogin,
  })
}

export async function updateUserProfile(values) {
  try {
    const user = auth.currentUser

    if (user.displayName !== values.companyName) {
      await updateProfile(user, { displayName: values.companyName })
    }
    return await updateDoc(doc(firestore, 'users', user.uid), {
      companyName: values.companyName,
      name: values.name,
      street: values.street,
      postalCode: values.postalCode,
      place: values.place,
      phone: values.phone ? values.phone : '',
      kennitala: values.kennitala ? values.kennitala : '',
    })
  } catch (error) {
    throw error
  }
}
