import React, { useEffect } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import styles from '../../../../assets/styles/modules/UI/AddIngredientButton.module.scss'

const AddSubMenuItemButton = ({ fieldArrayHelper, buttonRef, toggleEnterRef, label }) => {

  useEffect(() => {
    const keyDownHandler = event => {
      if ((event.key === 'Enter') && (buttonRef.current === 'add_sub_menu')) {
        event.preventDefault();
        if (toggleEnterRef.current) {
          toggleEnterRef.current = false
          fieldArrayHelper.push({ id: '', name: '', amount: '', unit: '' })
        } else {
          toggleEnterRef.current = true
        }
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <>
      <Button
        className={styles.MobileButton}
        type="button"
        basic
        color="green"
        size="big"
        onClick={() => fieldArrayHelper.push({ id: '', name: '', servings: '' })}
        content={
          <>
            <Icon color="green" name="plus circle" /> {label}
          </>
        }
      />
      <p className={styles.DesktopButton}>
        <span
          className="pointer"
          onClick={() => {
            buttonRef.current = 'add_sub_menu'
            fieldArrayHelper.push({ id: '', name: '', servings: '' })
          }}
        >
          <Icon color="green" name="plus circle" /> {label}
        </span>
      </p>
    </>
  )
}

export default AddSubMenuItemButton
