import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../../store/actions/userActions'
import { getSubscriptionsFromFirestore, listenToSubscriptions } from '../../../store/actions/subscriptionActions'
import useFirestoreDoc from '../../../hooks/useFirestoreDoc'
import useFirestoreCollection from '../../../../src/hooks/useFirestoreCollection'
import NavigationItem from './NavigationItem/NavigationItem'

const NavigationItems = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user)
  const { locations } = useSelector((state) => state.location)
  const { t } = useTranslation(['sidebar']);

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  useFirestoreCollection({
    query: () => getSubscriptionsFromFirestore(),
    data: (subscriptions) => dispatch(listenToSubscriptions(subscriptions)),
    deps: [dispatch],
  })

  return (
    <ul>
      <NavigationItem link="/orders" iconClass="orders">
        {t('orders', { ns: 'sidebar' })}
      </NavigationItem>
      <NavigationItem link="/menu" iconClass="menu">
        {t('menu', { ns: 'sidebar' })}
      </NavigationItem>
      <NavigationItem link="/distributors" iconClass="distributors">
        {t('distributors', { ns: 'sidebar' })}
      </NavigationItem>
      <NavigationItem link="/stock" iconClass="stock">
        {t('stock', { ns: 'sidebar' })}
      </NavigationItem>
      {user && (user.hasLocations || locations.length > 0) && (
        <NavigationItem link="/locations" iconClass="locations">
          {t('locations', { ns: 'sidebar' })}
        </NavigationItem>
      )}
    </ul>
  )
}
export default NavigationItems
