import React from 'react'
import { Checkbox, FormField, Label } from 'semantic-ui-react'
import { useField } from 'formik'

const RadioInput = (props) => {
  const [field, meta, helpers] = useField(props?.name)

  return (
    <FormField>
      <Checkbox
        radio
        {...props}
        checked={field.value === props.value}
        onChange={() => helpers.setValue(props.value)}
        onBlur={() => helpers.setTouched(true)}
        disabled={props.disabled}
      />
      {meta.touched && meta.error ? <Label pointing content={meta.error} /> : null}
    </FormField>
  )
}

export default RadioInput
