import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from '../../../assets/styles/modules/UI/GreenbytesModal.module.scss'

const GreenbytesModal = ({ confirmDeleteOpen = false, setConfirmDeleteOpen, handleConfirmClick = null, text, cancelButtonText, confirmButtonText, headerText = null, cancelButtonAction = null }) => {
  const { t } = useTranslation(['buttons'])

  return (
    <Modal
      className={styles['ModalContainer']}
      open={confirmDeleteOpen}
      onClose={() => setConfirmDeleteOpen(false)}
    >
      <Modal.Header className={styles['ModalHeader']}></Modal.Header>
      <Modal.Content className={cx(styles['Ui'], styles['Modal'], styles['Content'])}>
        <Modal.Description>
          { headerText &&
            <h2 className={styles['ModalHeaderText']}>
              {headerText}
            </h2>
          }
          <p className={styles['ModalDescription']}>
            {text}
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className={cx(styles['ModalActions'], !handleConfirmClick ? styles['ModalActionsCentered'] : '')}>
        { handleConfirmClick ?
          <>
            <Button          
              className={styles['CancelButton']}
              basic
              color="orange"
              onClick={() => {
                setConfirmDeleteOpen(false)
                if (cancelButtonAction) cancelButtonAction()
              }}
            >
              {t(cancelButtonText, { ns: 'buttons' })}
            </Button>
            <Button 
              className={styles['ConfirmButton']}
              onClick={handleConfirmClick}
              positive
            >
              {t(confirmButtonText, { ns: 'buttons' })}
            </Button>
          </>
        :
          <Button 
            className={styles['ConfirmButton']}
            onClick={() => {
              setConfirmDeleteOpen(false)
              if (cancelButtonAction) cancelButtonAction()
            }}
            positive
          >
            {t(confirmButtonText, { ns: 'buttons' })}
          </Button>
        }
      </Modal.Actions>
    </Modal>
  )
}

export default GreenbytesModal