import { useEffect, useCallback, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { clearSearch, finishSearch, startSearch } from '../store/actions/searchActions'
import _ from 'lodash'

export default function useSearchCallback(unfilteredData) {
  const dispatch = useDispatch()
  const timeoutRef = useRef()

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return useCallback(
    (e, data) => {
      if (data.id === 'ingredientSearch') {
        dispatch(startSearch(data.value))
      }

      timeoutRef.current = setTimeout(() => {
        let filteredResults
        if (!data.value || data.value.length === 0) {
          dispatch(finishSearch(unfilteredData))
          dispatch(clearSearch())
          return
        }

        if (data.id === 'ingredientSearch') {
          const re = new RegExp(_.escapeRegExp(data.value), 'i')
          const isMatch = (result) => re.test(result.name)
          filteredResults = _.filter(unfilteredData, isMatch)
        }
        if (data.id === 'distributorFilter') {
          filteredResults = unfilteredData.filter((item) => ((item.distributorId === data.value) || (Object.keys(item.distributors).indexOf(data.value) > -1)))
        }

        dispatch(finishSearch(filteredResults))
      }, 300)
    },
    [unfilteredData, dispatch]
  )
}
