import React, { useEffect } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Spinner from '../../UI/Spinner/Spinner'
import { cloudFunctions } from '../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import { getPayload, stockItemSchema } from './utils'
import { ShowForm } from './ShowForm'

const AddStockItem = () => {
  const history = useHistory()
  const { t } = useTranslation(['stock', 'validationMessages'])
  let locations = null,
    selectedLocation = null
  if (history.location.state) {
    ;({ locations, selectedLocation } = history.location.state)
  }
  const { loading } = useSelector((state) => state.async)

  useEffect(() => {
    if (!selectedLocation) {
      return <Redirect to="/locations" />
    }
  }, [])

  if (loading) return <Spinner content={t('loading')} />

  const initialValues = {
    name: '',
    amount: 0,
    onboarding: [
      {
        generic_name: '',
      },
    ],
    unit: '',
    locationIds: selectedLocation ? [selectedLocation.id] : [],
    locations: selectedLocation ? { [selectedLocation.id]: selectedLocation.name } : {}
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await httpsCallable(cloudFunctions, 'addStockItem')({ values: getPayload(values) })
            setSubmitting(false)
            history.push('/stock')
          } catch (error) {
            setErrors({ stock: error.message })
            setSubmitting(false)
          }
        }}
        validationSchema={stockItemSchema(t)}
      >
        {({ isSubmitting, errors, setFieldValue, values, handleReset }) => {
          return (
            <ShowForm
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              handleReset={handleReset}
              isSubmitting={isSubmitting}
              locations={locations}
              locationName={selectedLocation && selectedLocation.name ? selectedLocation.name : ''}
              selectedLocation={selectedLocation}
            />
          )
        }}
      </Formik>
    </div>
  )
}

export default AddStockItem
