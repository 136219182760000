import React from 'react'
import { useDispatch } from 'react-redux'
import ModalWrapper from '../../UI/Modal/ModalWrapper'
import { Button } from 'semantic-ui-react'
import { closeModal } from '../../../store/actions'
import styles from '../../../assets/styles/modules/terms/Terms.module.scss'
import buttonStyles from '../../../assets/styles/modules/UI/Button.module.scss'

const Terms = () => {
  const dispatch = useDispatch()

  return (
    <ModalWrapper size="small" header="Terms of Service">
      <div className={styles.Terms}>
        <p>These User Terms are Legally Binding</p>
        <p>
          These User Terms are a legally binding contract between you, the customer ("Customer") and us. WHEREAS, The
          Customer is granted a license to use the Software, subject to a set of limitations and restrictions.
          GreenBytes undertakes to provide Software while committing to comply with certain requirements relating to the
          maintenance of the Software.
        </p>
        <p>
          In consideration of the terms of this agreement, and other valuable consideration, the parties agree as
          follows:
        </p>
        <h2>Eligibility of usage of services</h2>
        <ol>
          <li>
            To access and use the Service, you must register for a GreenBytes account ("Account") by providing your full
            legal name, a valid email address and any other information indicated as required. GreenBytes may reject
            your application for an Account, or cancel an existing Account, for any reason, in our sole discretion.
          </li>
          <li>
            To access and use the Service, you must register for a GreenBytes account ("Account") by providing your full
            legal name, a valid email address and any other information indicated as required. GreenBytes may reject
            your application for an Account, or cancel an existing Account, for any reason, in our sole discretion.
          </li>
          <li>
            You are responsible for keeping your password secure. GreenBytes cannot and will not be liable for any loss
            or damage from failure to maintain the security of your Account and password.
          </li>
        </ol>
        <h2>Licensing information and limitation</h2>
        <p>
          The Provider hereby grants the Customer, including all authorized users of the Customer, a non-exclusive,
          non-sublicensable, non-assignable, royalty free, worldwide license to access and use the service (the
          “Service”) solely for the internal business operations of the Customer in accordance with the terms of this
          Agreement and the Provider´s online terms of use.
        </p>
        <p>Customer shall not, and shall not permit anyone to:</p>
        <p>a) Copy, republish or redistribute any content or materials of the SaaS Service or Software;</p>
        <p>b) Make the SaaS Services available to any person other than authorized users;</p>
        <p>
          c) Use or access the SaaS Services to provide service bureau, time-sharing or other computer hosting services
          to third parties;
        </p>
        <p>
          c) Use or access the SaaS Services to provide service bureau, time-sharing or other computer hosting services
          to third parties;
        </p>
        <p>
          e) Reverse engineer, decompile, disassemble, or otherwise attempt to derive the source code of the software
          used to provide the SaaS Services, except and only to the extent such activity is expressly permitted by
          applicable law;
        </p>
        <p>
          f) Access the SaaS Services or use the Documentation in order to build a similar product or competitive
          product.
        </p>
        <p>
          g) Use the Hosted Services in any way that causes, or may cause, damage to the Hosted Services or platform or
          impairment of the availability or accessibility of the Hosted Services.
        </p>
        <p>h) Sub-license its right to access and use the Hosted Services;</p>
        <p>i) Use the Hosted Services in any way that is unlawful, illegal, fraudulent, or harmful.</p>
        <h2>Termination of usage</h2>
        <p>
          GreenBytes may, in its sole discretion, at any time discontinue providing or limit access to the Service, any
          aspect of the Service, or content provided on or through the Service.
        </p>
        <p>
          You may stop using the Service and Software at any time for any reason upon 30 days' notice to the other
          party. Termination of your account does not relieve you of any obligation to pay any outstanding fees.
        </p>
        <h2>Intellectual property</h2>
        <p>
          Customer retains ownership and intellectual property rights in and to its Customer Content. GreenBytes ehf.
          retain all ownership and intellectual property rights to the services, Software programs, web application, and
          anything developed and delivered under the Agreement.
        </p>
        <p>
          Third party technology that may be appropriate or necessary for use with some GreenBytes ehf. programs are
          specified in the program Documentation or ordering document as applicable. Customer´s right to use such
          third-party technology is governed by the terms of the third-party technology license agreement specified by
          GreenBytes and not under the Agreement.
        </p>
        <p>
          Customer will not usually receive a physical or installed copy of the software. No ownership in the SaaS
          software will be transferred to the Customer.
        </p>
        <p>
          GreenBytes agrees to continuously assess our models and in the case that an algorithm does not perform
          optimally we will have back up prediction methods in place to ensure a smooth user experience.
        </p>
        <h2>Payment of service and usage</h2>
        <p>
          Unless otherwise specified in the applicable Order, each Subscription Term will renew for successive 12-month
          periods, unless either party gives the other party notice of non-renewal at least 90 days before the current
          Subscription Term ends. Upon notice of non-renewal, Customer will not be charged for the next billing cycle
          but will not receive any refunds or credits for amounts that have already been charged.
        </p>
        <p>
          Fees are as described in each Order. Unless the Order provides otherwise, all fees and expenses are due within
          30 days of the billing date specified in the applicable Order. Fees for renewal Subscription Terms are at
          GreenBytes‘ then-current rates, regardless of any discounted pricing in a prior Order. Late payments are
          subject to a service charge of 1.5% per month or the maximum amount allowed by Law, whichever is less.
        </p>
        <h2>Limitation of liability</h2>
        <p>
          a) Neither party will be liable for breach-of-contract damages suffered by the other party that are remote or
          speculative, or that could not have reasonably been foreseen on entry into this agreement.
        </p>
        <p>
          b) Neither party´s liability under this agreement will not exceed the fees paid by under this agreement during
          the 12 months preceding the date upon which the related claims arose.
        </p>
        <h2>Governing law</h2>
        <p>
          This Agreement shall be interpreted and enforced in accordance with the laws of Iceland the courts of which
          shall have exclusive jurisdiction over the parties as respects any dispute arising hereunder. English prevails
          and will be used for any legal interactions if there is a discrepancy.
        </p>
      </div>
      <div className="btn-container bottom fixed full-width">
        <Button
          onClick={() => dispatch(closeModal())}
          className={buttonStyles.Right}
          type="button"
          color="green"
          content="CLOSE"
          floated="right"
          size="large"
        />
      </div>
    </ModalWrapper>
  )
}

export default Terms
