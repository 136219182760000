import React from 'react';
import { Grid } from 'semantic-ui-react';
import TextInput from "../../UI/Form/TextInput";

const ProviderRequiredInputs = ({POS, isSubmitting}) => {
    switch (POS) {
        case "SALESCLOUD":
            return (
                <Grid.Row centered className='required-input'>
                    <Grid.Column>
                        <TextInput
                            name="UUID"
                            placeholder='UUID:'
                            type="text"
                            disabled={isSubmitting}
                        />
                    </Grid.Column>
                </Grid.Row>
            );

        case "REGLA":
            return (
                <>
                    <Grid.Row centered className='required-input'>
                        <Grid.Column>
                            <TextInput
                                name="username"
                                placeholder='Username:'
                                type="text"
                                disabled={isSubmitting}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered className='required-input'>
                        <Grid.Column>
                            <TextInput
                                name="password"
                                placeholder='Password:'
                                type="password"
                                disabled={isSubmitting}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </>
            );

        case "DK":
            return (
                <>
                    <Grid.Row centered className='required-input'>
                        <Grid.Column>
                            <TextInput
                                name="url"
                                placeholder='URL:'
                                type="text"
                                disabled={isSubmitting}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row centered className='required-input'>
                        <Grid.Column>
                            <TextInput
                                name="apiKey"
                                placeholder='API Key:'
                                type="text"
                                disabled={isSubmitting}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </>
            );
    
        default:
            return null;
    }
}

export default ProviderRequiredInputs;