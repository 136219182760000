import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  firstDocOfCollection: null,
  firstDocOfPage: null,
  lastDocOfPage: null,
  moreResults: true,
  counters: [0, 0],
  endOfCollection: false, // if last page has the same length as the page limit, this will be set to true
}

export default function paginationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_FIRST_DOC_OF_COLLECTION:
      return updateObject(state, {
        firstDocOfCollection: action.firstDocOfCollection,
        counters: [0, 0],
      })
    case actionTypes.SET_CURRENT_PAGE:
      return updateObject(state, {
        firstDocOfPage: action.firstDocOfPage,
        lastDocOfPage: action.lastDocOfPage,
        moreResults: action.moreItems,
      })
    case actionTypes.SET_PAGE_COUNTERS:
      const newCounters = [action.firstOrderCount, action.lastOrderCount]
      return updateObject(state, { counters: newCounters })
    case actionTypes.END_OF_COLLECTION:
      return updateObject(state, { endOfCollection: true })
    default:
      return state
  }
}
