import React, { useEffect } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useFirestoreCollection from '../../../hooks/useFirestoreCollection'
import { cloudFunctions } from '../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import { Message } from 'semantic-ui-react'
import { Formik } from 'formik'
import { listenToDistributors, getDistributorsFromFirestore } from '../../../store/actions'
import Spinner from '../../UI/Spinner/Spinner'
import { ShowFormEdit } from './ShowFormEdit'
import { getPayload, stockItemSchema } from './utils'

const EditStockItem = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['validationMessages', 'greenbytes', 'distributor'])

  const history = useHistory()
  let locations = null,
    selectedLocation = null,
    item = null
  if (history.location.state) {
    ;({ locations, selectedLocation, item } = history.location.state)
  }
  const { distributors } = useSelector((state) => state.distributor)
  const { loading } = useSelector((state) => state.async)

  useFirestoreCollection({
    query: () => getDistributorsFromFirestore(),
    data: (distributors) => dispatch(listenToDistributors(distributors)),
    deps: [dispatch],
  })

  useEffect(() => {
    if (!selectedLocation) {
      return <Redirect to="/locations" />
    }
  }, [])

  if (loading) return <Spinner content={t('loading', { ns: 'greenbytes' })} />

  const initialValues = item
    ? {
        name: item.name,
        amount: item.amount,
        onboarding: item.onboarding,
        unit: item.unit,
        locationIds: selectedLocation ? [selectedLocation.id] : [],
        locations: selectedLocation ? { [selectedLocation.id]: selectedLocation.name } : {}
      }
    : {}

  if (!distributors.length) {
    return (
      <Message style={{ textAlign: 'center' }}>
        <Message.Content>
          <p className="large">{t('add_distributor_first', { ns: 'distributor' })}</p>
          <Link className="link" to="/distributors">
            {t('go_to_distributors', { ns: 'distributor' })}
          </Link>
        </Message.Content>
      </Message>
    )
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await httpsCallable(
              cloudFunctions,
              'updateStockItem'
            )({
              id: item.id,
              values: getPayload(values),
              initialValues,
              locationId: selectedLocation ? selectedLocation.id : null,
            })
            setSubmitting(false)
            history.push('/stock')
          } catch (error) {
            setErrors({ stock: error.message })
            setSubmitting(false)
          }
        }}
        validationSchema={stockItemSchema(t)}
      >
        {({ isSubmitting, errors, setFieldValue, values, handleReset }) => (
          <ShowFormEdit
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            locations={locations}
            handleReset={handleReset}
            locationName={selectedLocation && selectedLocation.name ? selectedLocation.name : ''}
            selectedLocation={selectedLocation}
          />
        )}
      </Formik>
    </div>
  )
}

export default EditStockItem
