import * as actionTypes from './actionTypes'
import firebase, { firestore } from '../../config/firebase'
import {
  doc,
  query,
  collection,
  where,
  documentId,
  orderBy,
  startAfter,
  limit,
  limitToLast,
  endAt,
} from 'firebase/firestore'
import { getUserId } from './authActions'
import { getDataFromSnapshot } from '../../shared/firestoreService'

export const ORDER_STATUS_PROCESSING = 'processing'
export const ORDER_STATUS_SENT = 'sent'
export const ORDER_STATUS_CONFIRMED = 'confirmed'
export const ORDER_STATUS_DELIVERED = 'delivered'
export const ORDER_STATUS_ERROR = 'error'

export const listenToOrders = (orders) => {
  return {
    type: actionTypes.GET_ORDERS_SUCCESS,
    orders: orders,
  }
}

export const fetchOrdersSuccess = (orders, moreOrders) => {
  return {
    type: actionTypes.FETCH_ORDERS_SUCCESS,
    orders: orders,
    moreOrders: moreOrders,
  }
}

export function fetchOrdersForPagination(pageLimit, cursorDoc, getPrevious = false, selectedLocation) {
  const uid = getUserId()
  const updatedLimit = pageLimit + 1 // +1 to make sure there is more items on the next batch

  const orderCollection = collection(firestore, `users/${uid}/orders`)

  let orderQuery
  if (!cursorDoc) {
    if (selectedLocation) {
      orderQuery = query(orderCollection, where('deliveryLocation.locationId', '==', selectedLocation.id), orderBy('createdAt', 'desc'), limit(updatedLimit))
    } else {
      orderQuery = query(orderCollection, orderBy('createdAt', 'desc'), limit(updatedLimit))
    }
  } else {
    if (!getPrevious) {
      if (selectedLocation) {
        orderQuery = query(orderCollection, where('deliveryLocation.locationId', '==', selectedLocation.id), orderBy('createdAt', 'desc'), startAfter(cursorDoc), limit(updatedLimit))
      } else {
        orderQuery = query(orderCollection, orderBy('createdAt', 'desc'), startAfter(cursorDoc), limit(updatedLimit))
      }
    }
    else {
      if (selectedLocation) {
        orderQuery = query(orderCollection, where('deliveryLocation.locationId', '==', selectedLocation.id), orderBy('createdAt', 'desc'), endAt(cursorDoc), limitToLast(updatedLimit))
      } else {
        orderQuery = query(orderCollection, orderBy('createdAt', 'desc'), endAt(cursorDoc), limitToLast(updatedLimit))
      }
    }
  }

  return orderQuery
}

export const orderCreatedSuccess = (newOrders) => {
  return {
    type: actionTypes.ORDER_CREATED_SUCCESS,
    newOrders: newOrders,
  }
}

export const listenToNewOrders = (orders) => {
  return {
    type: actionTypes.GET_NEW_ORDERS_SUCCESS,
    orders: orders,
  }
}

export async function fetchOrders(limit, lastDoc = null, getPrevious = false, firstDoc = null) {
  const userId = getUserId()
  const collection = !getPrevious
    ? firestore
        .collection(`users/${userId}/orders`)
        .orderBy('createdAt', 'desc')
        .startAfter(lastDoc ? lastDoc : firebase.firestore.Timestamp.fromDate(new Date()))
        .limit(limit)
    : firestore.collection(`users/${userId}/orders`).orderBy('createdAt', 'desc').endBefore(firstDoc).limitToLast(limit)
  const snapshot = await collection.get()

  //For paging
  const firstVisible = snapshot.docs[0]
  const lastVisible = snapshot.docs[snapshot.docs.length - 1]
  const moreOrders = snapshot.docs.length >= limit
  const orders = snapshot.docs.map((doc) => getDataFromSnapshot(doc))

  return { orders, firstVisible, lastVisible, moreOrders }
}

export function getOrdersByIds(orderIds) {
  if (!orderIds.length) orderIds = ['null']
  return query(collection(firestore, `users/${getUserId()}/orders`), where(documentId(), 'in', orderIds))
}

export const listenToOrder = (order) => {
  return {
    type: actionTypes.GET_ORDER_SUCCESS,
    order: order,
  }
}

export function getOrderFromFirestore(orderId) {
  return doc(firestore, `users/${getUserId()}/orders`, orderId)
}

export function getOpenOrdersFromFirestore(location) {
  const orderQuery = !location
    ? query(collection(firestore, `users/${getUserId()}/orders`), where('status', '!=', ORDER_STATUS_DELIVERED))
    : query(collection(firestore, `users/${getUserId()}/orders`), where('status', '!=', ORDER_STATUS_DELIVERED), where('deliveryLocation.locationId', '==', location.id))
  return orderQuery
}

export function getFaultyOrdersFromFirestore() {
  return query(collection(firestore, `users/${getUserId()}/orders`), where('status', '==', ORDER_STATUS_ERROR))
}

export function getAllOrdersFromFirestore() {
  return query(collection(firestore, `users/${getUserId()}/orders`), orderBy('createdAt', 'desc'))
}

export const orderInProgressAction = (order) => {
  return {
    type: actionTypes.ORDER_IN_PROGRESS,
    order,
  }
}
