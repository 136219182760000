import React, { useEffect, useState } from 'react'
import { Grid, Icon, Label } from 'semantic-ui-react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import SelectInput from '../../UI/Form/SelectInput'
import CustomDropdown from '../../UI/Form/CustomDropdown'
import GreenbytesModal from '../../UI/GreenbytesModal/GreenbytesModal'
import TextInput from '../../UI/Form/TextInput'
import styles from '../../../assets/styles/modules/menu/AddMenuItem.module.scss'

const Ingredient = ({
  fieldArrayHelper,
  fieldValueSetter,
  index,
  showColumns,
  stockArr,
  unitArr,
  values,
  unitError,
  isSubmitting,
  buttonRef,
  toggleEnterRef,
  editFlow = false
}) => {
  const [submitFired, setSubmitFired] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const { t } = useTranslation(['menu'])

  useEffect(() => {
    if (isSubmitting) setSubmitFired(true)
  }, [isSubmitting, submitFired])

  const getIngredientName = (ingredient) => ingredient?.generic_name?.text || ingredient?.generic_name || null

  const removeIngredient = () => setConfirmDeleteOpen(true)

  const handleDelete = (arr) => {
    if (confirmDeleteOpen) {
      arr.remove(index)
      setConfirmDeleteOpen(false)
    }
  }

  return (
    <>
      <Grid.Row style={{ paddingTop: '0px' }} className={showColumns ? styles.CustomInputsRow : styles.CustomRow}>
        {showColumns ? (
          <>
            <Grid.Column className={styles.FirstCol}>
              <Label style={{ width: '100%', padding: '13px', fontSize: '16px', borderRadius: '80px' }}>
                {values[index]?.name || (typeof values[index]?.generic_name?.text === 'string' && values[index]?.generic_name?.text.split(' - Item Number')[0]) ||
                  (typeof values[index]?.generic_name === 'string' && values[index]?.generic_name.split(' - Item Number')[0])}
              </Label>
              <div className="mobile-icon">
                <Icon
                  className="pointer"
                  name="trash"
                  color="grey"
                  alt="Remove"
                  title="Remove"
                  disabled={values.length === 1}
                  onClick={() => removeIngredient()}
                />
              </div>
            </Grid.Column>
            <Grid.Column className={styles.SecondCol}>
              <TextInput name={`ingredients.${index}.amount`} placeholder={t('amount', { ns: 'menu' })} type="number" inputmode="decimal" />
            </Grid.Column>
            <Grid.Column className={cx(styles.four, styles.wide, styles.SecondCol)}>
              {submitFired && unitError?.unit_field && <Label pointing="below">{unitError?.unit_field}</Label>}
              <SelectInput
                className={cx(
                  styles.ui,
                  styles.selection,
                  styles.dropdown,
                  submitFired && unitError?.unit_field ? styles.error : ''
                )}
                name={`ingredients.${index}.unit_field`}
                placeholder={t('unit', { ns: 'menu' })}
                options={unitArr}
                fieldValueSetter={fieldValueSetter}
                fieldValueToSet={`ingredients.${index}.unit`}
              />
            </Grid.Column>
            <Grid.Column width={1}>
              <div className="desktop-icon">
                <Icon
                  className="pointer"
                  name="trash"
                  color="grey"
                  alt="Remove"
                  title="Remove"
                  disabled={values.length === 1}
                  onClick={() => removeIngredient()}
                />
              </div>
            </Grid.Column>
          </>
        ) : (
          <>
            <Grid.Column width={15} mobile={14}>
              <CustomDropdown
                name={`ingredients.${index}.id`}
                placeholder={editFlow && values[index].name ? values[index].name : t('ingredient', { ns: 'menu' })}
                options={stockArr}
                fieldValueSetter={fieldValueSetter}
                fieldValueToSet={`ingredients.${index}.generic_name`}
                fromStock={`ingredients.${index}.fromStock`}
                isFromStock={values[index]?.fromStock}
                buttonRef={buttonRef}
                buttonRefKind="add_ingredient"
                toggleEnterRef={toggleEnterRef}
                compact
              />
            </Grid.Column>
            <Grid.Column width={1} style={{ padding: '8px', display: 'flex', justifyContent: 'center' }}>
              <Icon
                className="pointer"
                name="trash"
                color="grey"
                alt="Remove"
                title="Remove"
                disabled={values.length === 1}
                onClick={() => removeIngredient()}
              />
            </Grid.Column>
          </>
        )}
      </Grid.Row>
      <GreenbytesModal
        confirmDeleteOpen={confirmDeleteOpen}
        setConfirmDeleteOpen={setConfirmDeleteOpen}
        handleConfirmClick={() => handleDelete(fieldArrayHelper)}
        cancelButtonText={'cancel'}
        confirmButtonText={'ok'}
        text={`Remove ${!getIngredientName(values[index]) ? 'it' : getIngredientName(values[index])} from ingredients?`}
      />
    </>
  )
}

export default Ingredient
