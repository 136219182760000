import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  pageTitle: null,
  subtitle: null,
}

const reducer = (state = initialState, { type, title, subtitle }) => {
  switch (type) {
    case actionTypes.SET_PAGE_TITLE:
      return updateObject(state, { pageTitle: title, subtitle })
    case actionTypes.SET_PAGE_SUBTITLE:
      return updateObject(state, { subtitle })
    default:
      return state
  }
}

export default reducer
