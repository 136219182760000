import * as Yup from 'yup'

export const NODE_OPENING_HOURS = {
    openFrom: '',
    openTo: '',
    days: {
        Su: { isOpen: false },
        M: { isOpen: false },
        Tu: { isOpen: false },
        W: { isOpen: false },
        Th: { isOpen: false },
        F: { isOpen: false },
        Sa: { isOpen: false }
    }
}

const fillWeek = (days) => {
    let result = {}
    for (let day of ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']) {
        result[day] = { isOpen: Object.keys(days).includes(day) }
    }
    return result
}

// returns true if the array arr contains an element that matches the value of openFrom and openTo with elem
const containsOpeningHours = (arr, elem) => {
    if (!arr.length) return false
    return arr.some((item) => item.openFrom === elem.openFrom && item.openTo === elem.openTo)
}

const hoursAsExtraInfo = (openFrom, openTo, extraInfo) => {
    if (!extraInfo) {
        return false
    } else {
        let result = false
        for (let index = 0; index < extraInfo.length; index++) {
            let timeGaps = extraInfo[index].split(' - ')
            result = result || ((timeGaps[0] === openFrom) && (timeGaps[1] === openTo))
        }
        return result
    }
}

const getTodayName = () => {
    const today = new Date().toLocaleDateString('en-US', {weekday: 'long'})
    switch (today) {
        case 'Monday': return 'M';
        case 'Tuesday': return 'Tu';
        case 'Wednesday': return 'W';
        case 'Thursday': return 'Th';
        case 'Friday': return 'F';
        case 'Saturday': return 'S';
        case 'Sunday': return 'Su';
        default: return '';
    }
}

export const parseOpeningHours = (values) => {
    let result = []
    values.forEach((element) => {
        let openedDays = Object.keys(element.days).map((day) => {
            return element.days[day].isOpen ? { id: `${day}`, data: { openFrom: element.openFrom, openTo: element.openTo, isOpen: true }} : null
        }).filter(el => el !== null)
        result = result.concat(openedDays)
    })

    let filteredResult = result.reduce((acc,item) => {
        let id = item.id;
        let data = item.data;
        let previousAcc
        if (acc[id]) {
            // it is already within acc
            previousAcc = acc[id]
            if (previousAcc.extraInfo) {
                previousAcc.extraInfo.push(`${acc[id].openFrom} - ${acc[id].openTo}`)
            } else {
                previousAcc.extraInfo = [`${acc[id].openFrom} - ${acc[id].openTo}`]
            }
            acc[id] = { ...data, extraInfo: previousAcc.extraInfo }
        } else {
            acc[id] = data;
        }
        return acc
    },{})

    for (let day of ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']) {
        if (!Object.keys(filteredResult).includes(day)) {
            filteredResult[day] = { openFrom: '', openTo: '', isOpen: false }
        }
    }

    return filteredResult
}

export const adaptOpeningHours = (values) => {
    let result = []
    Object.keys(values).map((el, index) => {
        if (containsOpeningHours(result, values[el])) {
            for (let i = 0; i < result.length; i++) {
                if (((result[i].openFrom === values[el].openFrom) && (result[i].openTo === values[el].openTo)) || hoursAsExtraInfo(result[i].openFrom, result[i].openTo, values[el].extraInfo)) {
                    result[i].days[el] = { isOpen: true }
                }
            }
        } else {
            result.push({ openFrom: values[el].openFrom, openTo: values[el].openTo, days: { [el]: { isOpen: true } } })
            if (values[el].extraInfo) {
                // loop through all the items in extraInfo, and if any of them is not found as a timeGap, it is added into result
                for (let i = 0; i < values[el].extraInfo.length; i++) {
                    let timeGaps = values[el].extraInfo[i].split(' - ')
                    if (!containsOpeningHours(result, { openFrom: timeGaps[0], openTo: timeGaps[1] })) {
                        result.push({ openFrom: timeGaps[0], openTo: timeGaps[1], days: { [el]: { isOpen: true } } })
                    }
                }
            }
        }
    })

    if (result.length === 0) {
        // there is no previous information of the distributor's schedule
        result.push(NODE_OPENING_HOURS)
    } else {
        // result needs to be filled with the days that are NOT open at that time
        for (let index = 0; index < result.length; index++) {
            result[index].days = fillWeek(result[index].days)
        }
    }
    return result
}

export const distributorSchema = (additionalContact, t) => {

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    let schemaAttributes = {
      name: Yup.string().required(t('distributor_name', { ns: 'validationMessages' })),
      email: Yup.string().email(t('valid_email', { ns: 'validationMessages' })).required(t('email', { ns: 'validationMessages' })),
      phone: Yup.string()
        .nullable(true)
        .notRequired()
        .matches(phoneRegExp, t('phone_number', { ns: 'validationMessages' }))
        .test('len', t('minimum_phone_length', { amount: 7, ns: 'validationMessages' }), (val) => val === undefined || ( val && val.toString().length >= 7)) 
    }

    if (additionalContact) {
        schemaAttributes.representativeName = Yup.string().required(t('representative_name', { ns: 'validationMessages' }));
        schemaAttributes.representativePhone = Yup.string()
            .nullable(true)
            .notRequired()
            .matches(phoneRegExp, t('phone_number', { ns: 'validationMessages' }))
            .test('len', t('minimum_phone_length', { amount: 7, ns: 'validationMessages' }), (val) => val === undefined || val.toString().length >= 7) 
    }

    return Yup.object().shape(schemaAttributes)
}

export const getFirstDay = (openingHours) => {
    const daysArr = Object.keys(openingHours)
    const today = getTodayName()
    return daysArr.includes(today) ? today : daysArr[0]
}

export const getDayKey = (day) => {
    switch (day) {
        case 'Su': return 'sunday'
        case 'M': return 'monday'
        case 'Tu': return 'tuesday'
        case 'W': return 'wednesday'
        case 'Th': return 'thursday'
        case 'F': return 'friday'
        case 'Sa': return 'saturday'
        default: return ''
    }
}

export const refreshValues = ({
    name,
    distrPhotoURL = null,
    email,
    phone,
    website,
    openingHours,
    representativeName = '',
    representativePhone = '',
    representativeEmail = '',
    customDistributor = null,
    shouldBeRefreshed
  }) => {
    return {
      name,
      distrPhotoURL: shouldBeRefreshed ? null : distrPhotoURL,
      email: shouldBeRefreshed ? '' : email,
      phone: shouldBeRefreshed ? '' : phone,
      website: shouldBeRefreshed ? '' : website,
      openingHours: shouldBeRefreshed ? [NODE_OPENING_HOURS] : openingHours,
      representativeName: shouldBeRefreshed ? '' : representativeName,
      representativePhone: shouldBeRefreshed ? '' : representativePhone,
      representativeEmail: shouldBeRefreshed ? '' : representativeEmail,
      customDistributor
    }
  }