import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'
import { getDatabase } from 'firebase/database'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { store } from '../index'
import { setNotification } from '../store/actions/notificationActions'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MSG_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const firebaseApp = initializeApp(firebaseConfig)

export const stripeConfig = {
  trial_period_days: process.env.REACT_APP_STRIPE_TRIAL_PERIOD_DAYS,
}

export const auth = getAuth(firebaseApp)

export const firestore = getFirestore(firebaseApp)

export const cloudFunctions = getFunctions(firebaseApp, 'europe-west1')

getAnalytics(firebaseApp)

export const db = getDatabase(firebaseApp)

// Push notifications
const messaging = getMessaging(firebaseApp)

const requestNotificationPermission = () => {
  if (Notification.permission === 'granted') return
  else if (Notification.permission !== 'denied')
    return Notification.requestPermission().then((permission) => {
      if (permission === 'granted') return
      else throw new Error('Notification permission denied')
    })
  else throw new Error('Notification permission already denied')
}

export const configurePushNotifications = async () => {
  try {
    if ('serviceWorker' in navigator) {
      // Ask for permission
      await requestNotificationPermission()
      // Get token
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
      })
      // Send the token to the server and save it to localStorage
      await httpsCallable(cloudFunctions, 'subscribeNewUser')({ token })
      return token
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err)
  }
}

onMessage(messaging, (payload) => {
  const { title, body } = payload.notification
  store.dispatch(setNotification({ title, body }))
})

if (window.location.hostname === '127.0.0.1') {
  connectAuthEmulator(auth, 'http://127.0.0.1:9099')
  connectFunctionsEmulator(cloudFunctions, '127.0.0.1', 5001)
  connectFirestoreEmulator(firestore, '127.0.0.1', 9199)
}

export default firebaseApp
