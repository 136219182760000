import * as actionTypes from '../actions/actionTypes'

export const requestPos = () => ({
  type: actionTypes.REQUEST_POS,
})

export const receivedPos = (pos) => ({
  type: actionTypes.RECEIVE_POS,
  pos: pos,
})
