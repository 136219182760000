import React from 'react'
import { Button, Portal, Segment } from 'semantic-ui-react'

const PortalPopup = (props) => {
  return (
    <Portal onClose={props.onCloseClick} open={props.isOpen}>
      <Segment className="portal-popup" style={{ backgroundColor: props.bgColor ? props.bgColor : '#fff6cd' }}>
        <p>{props.message}</p>
        <Button floated="right" content="Close" onClick={props.onCloseClick} />
      </Segment>
    </Portal>
  )
}

export default PortalPopup
