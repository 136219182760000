import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styles from '../../../assets/styles/modules/Home.module.scss'
import orderIconGreySrc from '../../../assets/images/navigation/orders.svg'
import orderIconGreenSrc from '../../../assets/images/home/orders-green.svg'
import orderIconDarkGreenSrc from '../../../assets/images/home/orders-dark-green.svg'
import { Link } from 'react-router-dom'
import Spinner from '../../UI/Spinner/Spinner'
import useFirestoreCollection from '../../../hooks/useFirestoreCollection'
import { getAllOrdersFromFirestore, listenToOrders } from '../../../store/actions/orderActions'

const Orders = () => {
  const dispatch = useDispatch()
  const { orders } = useSelector((state) => state.order)
  const { loading } = useSelector((state) => state.async)
  const { t } = useTranslation(['home', 'orders']);

  useFirestoreCollection({
    query: () => getAllOrdersFromFirestore(),
    data: (orders) => dispatch(listenToOrders(orders)),
    deps: [dispatch],
  })

  if (loading) return <Spinner content={t('loading', { ns: 'home' })} />

  const classes = [styles.Task]
  if (orders.length) classes.push(styles.Done)
  return (
    <div className={classes.join(' ')}>
      <Link className={styles.TaskLink} to="/orders">
        <div className={styles.TaskIcon}>
          {!orders.length ? (
            <img src={orderIconGreySrc} alt="" border="0" />
          ) : (
            <>
              <img className={styles.TaskIconImg} src={orderIconGreenSrc} alt="" border="0" />
              <img className={styles.TaskIconImgHover} src={orderIconDarkGreenSrc} alt="" border="0" />
            </>
          )}
        </div>
        {!orders.length ? (
          <>
            <strong className="xxx-large">4.</strong> <span>{t('create_an_order', { ns: 'orders' })}</span>
          </>
        ) : (
          t('manage_orders', { ns: 'home' })
        )}
      </Link>
    </div>
  )
}

export default Orders
