import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CacheBuster from 'react-cache-buster'
import Layout from './hoc/Layout/Layout'
import ErrorBoundary from './hoc/ErrorBoundary'
import PrivateRoute from './components/PrivateRoute/PrivateRoute'
import Login from './components/User/Login'
import Signup from './components/User/Signup'
import Distributors from './components/Distributors/Distributors'
import Stock from './components/Stock/Stock'
import AddStockItem from './components/Stock/StockItem/AddStockItem'
import EditStock from './components/Stock/EditStock/EditStock'
import EditStockItem from './components/Stock/StockItem/EditStockItem'
import ModalManager from './components/UI/Modal/ModalManager'
import Home from './components/Home/Home'
import AddDistributor from './components/Distributors/Distributor/AddDistributor'
import EditDistributor from './components/Distributors/Distributor/EditDistributor'
import Settings from './components/User/Settings/Settings'
import Menu from './components/Menu/Menu'
import AddMenuItem from './components/Menu/MenuItem/AddMenuItem/AddMenuItem'
import ViewMenuItem from './components/Menu/MenuItem/ViewMenuItem/ViewMenuItem'
import EditMenuItem from './components/Menu/MenuItem/EditMenuItem/EditMenuItem'
import EditCombo from './components/Menu/Combo/EditCombo'
import Orders from './components/Orders/Orders'
import OrderForm from './components/Orders/OrderForm'
import OrderView from './components/Orders/Order/OrderView'
import OrderSuccess from './components/Orders/OrderCreatedSuccess'
import ViewCombo from './components/Menu/Combo/ViewCombo'
import Terms from './components/Terms/Terms'
import Privacy from './components/Terms/Privacy'
import SplashScreen from './components/SplashScreen/SplashScreen'
import Locations from './components/Locations/Locations'
import AddLocation from './components/Locations/Location/AddLocation'
import EditLocation from './components/Locations/Location/EditLocation'
import OrderDistributorsForm from './components/Orders/OrderDistributorsForm'
import OrderSummary from './components/Orders/OrderSummary'
import ProviderInformation from './components/User/POS/ProviderInformation'
import CompanyDetails from './components/User/Settings/CompanyDetails'
import { version } from '../package.json'
import Spinner from './components/UI/Spinner/Spinner'

const App = (props) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const { t } = useTranslation(['common']);

  useEffect(() => {
    setTimeout(() => {
      setShowSplashScreen(false)
    }, 1000)
  }, [])

  window.onbeforeunload = () => localStorage.removeItem('/menu/add')

  if (showSplashScreen) {
    return <SplashScreen />
  }

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled
      isVerboseMode={false}
      loadingComponent={<Spinner content={t('please_wait', { ns: 'common' })} />}
    >
      <div className="App">
        <ModalManager />
        <Layout>
          <ErrorBoundary key={props.location?.pathname}>
            <Switch>
              <PrivateRoute exact path="/home" component={Home} />
              <PrivateRoute exact path="/distributors" component={Distributors} />
              <PrivateRoute path="/distributors/add" component={AddDistributor} />
              <PrivateRoute path="/distributors/edit/:id" component={EditDistributor} />
              <PrivateRoute exact path="/stock/edit/item" component={EditStockItem} />
              <PrivateRoute path="/stock/add" component={AddStockItem} />
              <PrivateRoute path="/stock/edit/location/:location" component={EditStock} />
              <PrivateRoute path="/stock" component={Stock} />
              <PrivateRoute path="/menu/add/:current_step" component={AddMenuItem} />
              <PrivateRoute exact path="/menu/combo/view/:id" component={ViewCombo} />
              <PrivateRoute path="/menu/combo/view/:id/location/:location" component={ViewCombo} />
              <PrivateRoute exact path="/menu/view/:id" component={ViewMenuItem} />
              <PrivateRoute path="/menu/view/:id/location/:location" component={ViewMenuItem} />
              <PrivateRoute exact path="/menu/combo/edit/:id" component={EditCombo} />
              <PrivateRoute path="/menu/combo/edit/:id/location/:location" component={EditCombo} />
              <PrivateRoute exact path="/menu/edit/:id/:current_step" component={EditMenuItem} />
              <PrivateRoute path="/menu/edit/:id/location/:location/:current_step" component={EditMenuItem} />
              <PrivateRoute path="/menu" component={Menu} />
              <PrivateRoute exact path="/orders/create" component={OrderForm} />
              <PrivateRoute path="/orders/create/location/:location" component={OrderForm} />
              <PrivateRoute path="/orders/view/:id" component={OrderView} />
              <PrivateRoute path="/orders/success" component={OrderSuccess} />
              <PrivateRoute path="/orders" component={Orders} />
              <PrivateRoute path="/order-distributors" component={OrderDistributorsForm} />
              <PrivateRoute path="/order-summary" component={OrderSummary} />
              <PrivateRoute exact path="/settings" component={Settings} />
              <PrivateRoute path="/settings/company-details" component={CompanyDetails} />
              <PrivateRoute path="/provider-information" component={ProviderInformation} />
              <PrivateRoute exact path="/locations" component={Locations} />
              <PrivateRoute path="/locations/add" component={AddLocation} />
              <PrivateRoute path="/locations/edit/:id" component={EditLocation} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/index.html">
                <Redirect to="/locations" />
              </Route>
              <PrivateRoute exact path="/">
                <Redirect to="/locations" />
              </PrivateRoute>  
            </Switch>
          </ErrorBoundary>
        </Layout>
      </div>
    </CacheBuster>
  )
}

export default withRouter(App)
