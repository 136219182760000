export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_SET_REDIRECT_PATH = 'AUTH_SET_REDIRECT_PATH'

export const GET_DISTRIBUTORS_SUCCESS = 'GET_DISTRIBUTORS_SUCCESS'
export const GET_DISTRIBUTOR_SUCCESS = 'GET_DISTRIBUTOR_SUCCESS'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const SET_NOTIFICATION = 'SET_NOTIFICATION'

export const ASYNC_ACTION_START = 'ASYNC_ACTION_START'
export const ASYNC_ACTION_FINISH = 'ASYNC_ACTION_FINISH'
export const ASYNC_ACTION_ERROR = 'ASYNC_ACTION_ERROR'

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'
export const SET_PAGE_SUBTITLE = 'SET_PAGE_SUBTITLE'

export const GET_STOCK_SUCCESS = 'GET_STOCK_SUCCESS'
export const FETCH_STOCK = 'FETCH_STOCK'

export const SET_SEARCH_DATA = 'SET_SEARCH_DATA'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
export const START_SEARCH = 'START_SEARCH'
export const FINISH_SEARCH = 'FINISH_SEARCH'
export const UPDATE_SELECTION = 'UPDATE_SELECTION'

export const GET_MENU_SUCCESS = 'GET_MENU_SUCCESS'
export const GET_MENU_ITEM_SUCCESS = 'GET_RECIPE_SUCCESS'

export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDER_SUGGESTIONS_SUCCESS = 'GET_ORDER_SUGGESTIONS_SUCCESS'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const ORDER_CREATED_SUCCESS = 'ORDER_CREATED_SUCCESS'
export const GET_NEW_ORDERS_SUCCESS = 'GET_NEW_ORDERS_SUCCESS'
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const ORDER_IN_PROGRESS = 'ORDER_IN_PROGRESS'

export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'

export const SET_FIRST_DOC_OF_COLLECTION = 'SET_FIRST_DOC_OF_COLLECTION'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_PAGE_COUNTERS = 'SET_PAGE_COUNTERS'
export const END_OF_COLLECTION = 'END_OF_COLLECTION'

export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS'
export const GET_SUBSCRIPTION_PLANS_SUCCESS = 'GET_SUBSCRIPTION_PLANS_SUCCESS'

export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS'
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS'
export const GET_LOCATION_IDS = 'GET_LOCATION_IDS'
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION'
export const SET_LOCATION_ARRAY = 'SET_LOCATION_ARRAY'

export const CHANGE_SORTING = 'CHANGE_SORTING'
export const SET_SORTING_DATA = 'SET_SORTING_DATA'

export const REQUEST_POS = 'REQUEST_POS'
export const RECEIVE_POS = 'RECEIVE_POS'
