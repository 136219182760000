import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  getUserProfileFromFirestore,
  listenToUserProfile,
  updateSaveLoginSetting,
} from '../../../store/actions/userActions'
import useFirestoreDoc from '../../../hooks/useFirestoreDoc'
import useFetchLocation from '../../../hooks/useFetchLocation'
import { useMultipleLocations } from '../../../hooks/useMultipleLocations'
import { useUserFlags } from '../../../hooks/useUserFlags'
import { Button, Grid, Header, Message, Checkbox } from 'semantic-ui-react'
import { setPageTitle, asyncActionFinish, asyncActionStart, openModal } from '../../../store/actions'
import Spinner from '../../UI/Spinner/Spinner'
import { Link } from 'react-router-dom'
import gbLogoSrc from '../../../assets/images/gb-logo-linear-green.svg'
import CustomMessage from '../../UI/Message/CustomMessage'
import { getSettingsMessage, updateUserFlags } from '../../../shared/utility'
import styles from '../../../assets/styles/modules/settings/Settings.module.scss'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../../config/firebase'
import { getUserIdToken } from '../../../store/actions/authActions'

const Settings = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = useSelector((state) => state.user)
  const { loading, message } = useSelector((state) => state.async)
  const { locations } = useSelector((state) => state.location)
  const modal = useSelector((state) => state.modal)
  const { fetchLocations } = useFetchLocation()
  const { t, i18n } = useTranslation(['settings', 'buttons', 'common'])
  const [userPOS, setUserPOS] = useState(null)

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  useEffect(() => {
    dispatch(setPageTitle(t('title')))

    fetchLocations(dispatch)
  }, [dispatch, modal])

  // Update user flags is needed
  useUserFlags()

  // Update user.hasLocations if needed
  useMultipleLocations()

  
  useEffect(() => {
    const setHasCheckedSettings = async () => {
      if (!user?.hasCheckedSettings) {
        await updateUserFlags({
          hasCheckedSettings: true,
        })
      }
    }

    const getUserPOS = async () => {
      try {
        let token = await getUserIdToken()
        const result = await httpsCallable(cloudFunctions, 'getConfigurationData')({ user_token: token })
        return result.data.pos
      } catch (error) {
        return null
      }
    }

    setHasCheckedSettings()
    getUserPOS().then(pos => setUserPOS(pos))
  }, [])

  const currentMessage = useMemo(() => {
    return getSettingsMessage(user, t)
  }, [user])

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />

  async function onSaveLoginToggle() {
    dispatch(asyncActionStart())
    await updateSaveLoginSetting(!user.saveLogin)
    dispatch(asyncActionFinish())
  }

  return (
    <>
      {<CustomMessage message={currentMessage} />}
      {message && <Message success content={message} />}
      <Header as="h2" dividing className={styles['Settings-Header']}>
        {t('your_account', { ns: 'settings' })}
      </Header>
      <Grid className={styles['Settings-Custom-Grid']}>
        <Grid.Row>
          <Grid.Column width={4}>
            {t('status', { ns: 'settings' })}
          </Grid.Column>
          <Grid.Column width={12}>
            {(user.isPremium)
              ? t('premium', { ns: 'settings' })
              : `${t('basic', { ns: 'settings' })} 
                        ${!user.isComplete ? `. ${t('complete_company_details', { ns: 'settings' })}.` : ''}`}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <strong>{t('email', { ns: 'settings' })}</strong>
          </Grid.Column>
          <Grid.Column width={9}><p className={styles['Email']}>{user.email}</p></Grid.Column>
          <Grid.Column width={3} textAlign="right">
            <span
              className="green-text pointer small"
              onClick={() => dispatch(openModal({ modalType: 'ChangeEmail', modalProps: {} }))}
            >
              {t('change', { ns: 'settings' })}
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <strong>{t('password', { ns: 'settings' })}</strong>
          </Grid.Column>
          <Grid.Column width={9}>**********</Grid.Column>
          <Grid.Column width={3} textAlign="right">
            <span
              className="green-text pointer small"
              onClick={() => dispatch(openModal({ modalType: 'ChangePassword', modalProps: {} }))}
            >
              {t('change', { ns: 'settings' })}
            </span>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <strong>{t('login_info', { ns: 'settings' })}</strong>
          </Grid.Column>
          <Grid.Column width={9}>
            {user.saveLogin && user.saveLogin === true ?
              <strong>{t('saved', { ns: 'settings' })}</strong>
            :
              <strong>{t('not_saved', { ns: 'settings' })}</strong>
            }
          </Grid.Column>
          <Grid.Column width={3} textAlign="right">
            <Checkbox toggle defaultChecked={user.saveLogin} onChange={onSaveLoginToggle} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header as="h2" dividing className={styles['Settings-Header']}>
        {t('company_details', { ns: 'settings' })}
      </Header>
      <Grid className={styles['Settings-Custom-Grid']}>
        <Grid.Row>
          <Grid.Column width={4}>
            <strong>{t('company_name', { ns: 'settings' })}</strong>
          </Grid.Column>
          <Grid.Column width={9}>{user.companyName}</Grid.Column>
          <Grid.Column width={3} textAlign="right">
            {user.isComplete ? (
              <span
                className="green-text pointer small"
                onClick={() => history.push('/settings/company-details')}
              >
                {t('edit_details', { ns: 'settings' })}
              </span>
            ) : (
              <Button
                basic
                color="green"
                size="tiny"
                type="button"
                onClick={() => history.push('/settings/company-details')}
                content="Add Details"
              />
            )}
          </Grid.Column>
        </Grid.Row>
        {user.isComplete && (
          <>
            <Grid.Row>
              <Grid.Column width={4}>
                <strong>{t('kennitala', { ns: 'settings' })}</strong>
              </Grid.Column>
              <Grid.Column width={12}>{user.kennitala}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <strong>{t('owner_manager', { ns: 'settings' })}</strong>
              </Grid.Column>
              <Grid.Column width={12}>{user.name}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <strong>{t('address', { ns: 'settings' })}</strong>
              </Grid.Column>
              <Grid.Column width={12}>
                {user.street.length > 0 && `${user.street}, ${user.postalCode} ${user.place}`}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>
                <strong>{t('phone', { ns: 'settings' })}</strong>
              </Grid.Column>
              <Grid.Column width={12}>{user.phone}</Grid.Column>
            </Grid.Row>
          </>
        )}
        {!user.isComplete && (
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              <Message content={t('add_company_details', { ns: 'settings' })} />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      <Header as="h2" dividing className={styles['Settings-Header']}>
        {t('POS', { ns: 'settings' })}
      </Header>
      <Grid className={styles['Settings-Custom-Grid']}>
        <Grid.Row>
          <Grid.Column width={4}>
            <strong>{t('provider', { ns: 'settings' })}</strong>
          </Grid.Column>
          <Grid.Column width={9}>{userPOS}</Grid.Column>
          <Grid.Column width={3} textAlign="right">
            <Link className='green-text pointer small' to="/provider-information">
              {!user.hasPOS || !userPOS ? t('add', { ns: 'buttons', lng: i18n.language }) : t('edit', { ns: 'buttons', lng: i18n.language })}
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <br />
      <div className={styles['Settings-Footer']}>
        <p style={{ marginBottom: '1em' }}>
          {t('support_contact')}&nbsp;
          <Link to="mailto:help@greenbytes.is">
            <strong>help@greenbytes.is</strong>
          </Link>
        </p>
        <div className="logo">
          <img src={gbLogoSrc} alt="greenbytes" style={{ width: '200px', margin: '0 auto' }} />
        </div>
        <p className="grey-darker-text pointer" style={{ fontWeight: '700', marginBottom: '1em' }} onClick={() => dispatch(openModal({ modalType: 'Terms', modalProps: {} }))}>
          {t('terms_of_service', { ns: 'settings' })}
        </p>
        <p className="grey-darker-text pointer" style={{ fontWeight: '700', marginBottom: '1em' }} onClick={() => dispatch(openModal({ modalType: 'Privacy', modalProps: {} }))}>
          {t('privacy_policy', { ns: 'settings' })}
        </p>
      </div>
    </>
  )
}

export default Settings
