import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, useParams, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ShowForm } from './ShowForm'
import { httpsCallable } from 'firebase/functions'
import { useTranslation } from 'react-i18next'
import { getStockFromFirestore, listenToStock } from '../../../../store/actions/stockActions'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../../../store/actions/userActions'
import useFirestoreDoc from '../../../../hooks/useFirestoreDoc'
import useFirestoreCollection from '../../../../hooks/useFirestoreCollection'
import useFetchLocation from '../../../../hooks/useFetchLocation'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { setPageTitle } from '../../../../store/actions'
import { cloudFunctions } from '../../../../config/firebase'
import Spinner from '../../../UI/Spinner/Spinner'
import { getPayload, INITIAL_VALUES } from './utils'

const AddMenuItem = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  let { current_step } = useParams()
  const { t } = useTranslation(['menu', 'validationMessages', 'common'])

  const { fetchLocations } = useFetchLocation()
  const formikRef = useRef(null)

  const { user } = useSelector((state) => state.user)
  const { selectedLocation } = useSelector((state) => state.location)
  const [loading, setLoading] = useState(true)
  const [menuItems, setMenuItems] = useState([])

  const isBatchItem = useRef(null)
  const menuItemName = useRef('')
  const itemAlreadyExists = useRef(false)
  const [isCombo, setIsCombo] = useState(false)

  useEffect(() => {
    dispatch(setPageTitle(t('add_menu', { ns: 'menu' })))
    fetchLocations(dispatch)
  }, [dispatch])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  useEffect(() => {
    const loadMenuItems = async () => {
      const menus = await httpsCallable(cloudFunctions, 'getRecipesByLocation')({ locationId: selectedLocation ? selectedLocation.id : null })
      setMenuItems(menus.data)
      setLoading(false)
    }

    loadMenuItems()
  }, [])

  if (!selectedLocation) {
    return <Redirect to="/menu" />
  }

  if (loading || !user) return <Spinner loading content={t('please_wait', { ns: 'common' })} />

  const initialValues = { ...INITIAL_VALUES }

  const menuItemSchema = (isBatchItem) => {
    let schemaAttributes = {
      name: Yup.string().required(t('menu_item', { ns: 'validationMessages' })),
      ingredients: Yup.array()
        .of(
          Yup.object().shape({
            amount: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(0, t('too_low', { ns: 'validationMessages' })).required(t('amount', { ns: 'validationMessages' })),
            unit_field: Yup.string().oneOf(['kg', 'g', 'L', 'mL', 'pcs']).required(t('unit', { ns: 'validationMessages' })),
          })
        )
        .required(t('add_ingredient', { ns: 'validationMessages' })),
      subrecipes: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(t('item', { ns: 'validationMessages' })),
          servings: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(1, t('too_low', { ns: 'validationMessages' })).required(t('servings', { ns: 'validationMessages' })),
        })
      ),
    }
    if (user.hasLocations) {
      schemaAttributes.locationIds = Yup.array().min(1, t('select_location', { ns: 'validationMessages' }))
    }
    if (isBatchItem) {
      schemaAttributes.servings = Yup.number()
          .typeError(t('number', { ns: 'validationMessages' }))
          .min(1, t('too_low', { ns: 'validationMessages' }))
          .required(t('number_of_servings', { ns: 'validationMessages' }))
    }
    // create and return the schema
    return Yup.object().shape(schemaAttributes)
  }

  if (user.hasLocations) {
    initialValues.locationIds = []
    initialValues.locations = {}
  }

  const cleanIdProductOnboarding = (arr, value) =>
    arr.map((el) => (el.id_product_onboarding === value ? { ...el, id_product_onboarding: null } : el))

  const filterFromOnBoarding = (ingredients) => {
    const ingredientsFromStock = Object.values(ingredients).filter((ingredient) => ingredient.fromStock)
    const newIngredientsForStock = Object.values(ingredients).filter((ingredient) => !ingredient.fromStock)

    let result = Object.assign(
      {},
      ...ingredientsFromStock.map((ingredient) => {
        let { generic_name, name, fromStock, unit_field, ...rest } = ingredient
        const newIngredient = {
          name: generic_name,
          ...rest,
        }
        return { [ingredient.id]: newIngredient }
      })
    )

    result.onboarding = newIngredientsForStock.map((ingredient) => {
      let { name, id, fromStock, distributor, product, unit_field, ...rest } = ingredient
      return { ...rest }
    })

    return result
  }

  const filterSubrecipes = (subrecipes) => {
    return Object.assign(
      {},
      ...subrecipes.map((subrecipe) => {
        let { name, ...rest } = subrecipe
        const newSubrecipe = {
          name: name.text ? name.text : name,
          ...rest,
        }
        return { [subrecipe.id]: newSubrecipe }
      })
    )
  }

  const batchItemValidation = (value) => {
    isBatchItem.current = value
  }

  const menuItemNameExists = (name) => {
    const filteredMenus = menuItems.map(e => e.name)
    return filteredMenus.includes(name)
  }

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={current_step === 'third-step' || isCombo ? menuItemSchema(isBatchItem?.current) : Yup.object({})}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        let ingredientsArrayFormat = cleanIdProductOnboarding(values.ingredients, t('type_your_own_ingredient', { ns: 'menu' }))
        let subrecipesArrayFormat = values.subrecipes

        if (values.ingredients.length > 0 || values.subrecipes.length > 0) {
          if (menuItemNameExists(values.name)) {
            setErrors({ recipes: t('name_already_exists', { name: values.name, ns: 'menu' }) })
            setSubmitting(false)
          } else {
            let ingredientsDictionary = filterFromOnBoarding(ingredientsArrayFormat)
            values.ingredients = { ...ingredientsDictionary }

            let subrecipesDictionary = filterSubrecipes(subrecipesArrayFormat)
            values.subrecipes = { ...subrecipesDictionary }
            const payload = getPayload(values)
            try {
              await httpsCallable(cloudFunctions, 'addMenuItem')({ values: payload })
              setSubmitting(false)
              values.ingredients = ingredientsArrayFormat
              values.subrecipes = subrecipesArrayFormat
              localStorage.removeItem('/menu/add')
              history.push('/menu')
            } catch (error) {
              setErrors({ recipes: error.message })
              setSubmitting(false)
              values.ingredients = ingredientsArrayFormat
              values.subrecipes = subrecipesArrayFormat
            }
          }
        } else {
          setErrors({
            recipes: t('add_at_least_one', { ns: 'menu' }),
          })
          setSubmitting(false)
        }
      }}
    >
      {({ values, errors, setFieldError, setFieldValue, resetForm, isSubmitting }) => {

        values.locationIds = [selectedLocation.id]
        values.locations = {[selectedLocation.id]: selectedLocation.name}
        if (menuItemName.current !== values.name) {
          menuItemName.current = values.name
          itemAlreadyExists.current = menuItemNameExists(menuItemName.current)
        }

        return (
          <ShowForm
            formikRef={formikRef}
            location={location}
            initialValues={initialValues}
            values={values}
            errors={errors}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
            resetForm={resetForm}
            isSubmitting={isSubmitting}
            batchItemValidation={batchItemValidation}
            setIsCombo={setIsCombo}
            baseUrl="/menu/add"
            shouldRefresh={location.state && location.state.from === 'menu'}
            itemNameExists={(current_step === 'first-step' || current_step === 'third-step') && itemAlreadyExists.current}
            locationName={selectedLocation.name}
            menuItems={menuItems}
            selectedLocation={selectedLocation}
          />
        )
      }}
    </Formik>
  )
}

export default AddMenuItem
