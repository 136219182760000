import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  getMenuItemFromFirestore,
  getUserProfileFromFirestore,
  listenToMenuItem,
  listenToUserProfile,
} from '../../../store/actions'
import useFirestoreDoc from '../../../hooks/useFirestoreDoc'
import { Button, Grid, Icon, Segment } from 'semantic-ui-react'
import { setPageTitle } from '../../../store/actions'
import styles from '../../../assets/styles/modules/menu/MenuItem.module.scss'
import Spinner from '../../UI/Spinner/Spinner'
import { getLocationsFromItem } from '../../../shared/utility'
import cx from 'classnames'

const ViewCombo = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { menuItem } = useSelector((state) => state.menu)
  const { user } = useSelector((state) => state.user)
  const { loading } = useSelector((state) => state.async)
  const { selectedLocation } = useSelector((state) => state.location)
  const { t } = useTranslation(['common', 'menu', 'buttons'])

  const { location, id } = match.params

  useFirestoreDoc({
    query: () => getMenuItemFromFirestore(id, location),
    data: (menuItem) => {
      dispatch(listenToMenuItem(menuItem))
      dispatch(setPageTitle(menuItem.name))
    },
    deps: [dispatch],
  })

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  useEffect(() => {
    if (menuItem) {
      dispatch(setPageTitle(menuItem.name))
    }
  }, [dispatch, menuItem])

  if (!id || (!!location !== false && (!selectedLocation || selectedLocation.id !== location))) {
    return <Redirect to="/menu" />
  }

  if (loading || !menuItem) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const notes = !!menuItem.notes?.length && (
    <Grid.Row style={{ paddingTop: 20 }}>
      <Grid.Column style={{ padding: '0px' }} width={16}>
        <hr style={{ margin: '0px -25px 17px -25px' }} />
        <span className="hint small">{t('notes', { ns: 'menu' })}</span>
        <p>{menuItem.notes}</p>
      </Grid.Column>
    </Grid.Row>
  )

  return (
    <Segment raised className={styles.RecipeDetail}>
      <Grid relaxed stackable={false} style={{ maxWidth: '85%' }}>
        {!!location && (
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              <strong>{t('location', { ns: 'menu' })}: </strong>
              {selectedLocation.name}
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row className={styles.Heading} style={{ padding: '11px 0px 2px' }}>
          <Grid.Column width={10} style={{ paddingLeft: '0px' }}>
            {t('included_menu_items', { ns: 'menu' })}
          </Grid.Column>
          <Grid.Column width={6} textAlign="right" style={{ padding: '0px' }}>
            {t('servings', { ns: 'menu' })}
          </Grid.Column>
        </Grid.Row>
        {Object.values(menuItem.subrecipes).map(({ id, name, servings }) => (
          <Grid.Row key={id} style={{ padding: '6px 0px 0px' }}>
            <Grid.Column width={10} style={{ paddingLeft: '0px' }}>
              {name}
            </Grid.Column>
            <Grid.Column width={6} textAlign="right" style={{ padding: '0px' }}>
              {servings} {t('srv', { ns: 'menu' })}
            </Grid.Column>
          </Grid.Row>
        ))}
        {notes}
        {user.hasLocations && menuItem.locations && (
          <Grid.Row>
            <Grid.Column width={1} style={{ padding: '0px' }}>
              <Icon name="map marker alternate" color="grey" />
            </Grid.Column>
            <Grid.Column width={15} style={{ paddingRight: '0px' }}>
              {getLocationsFromItem(menuItem.locations)}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      <div className="btn-container bottom flex center-content">
        <Button className={cx(styles.Button, styles.Green, styles.ViewCombo)} content={t('edit', { ns: 'buttons' })} color="green" size="large" onClick={() => history.push('/menu/combo/edit/' + id)} />
      </div>
    </Segment>
  )
}

export default ViewCombo
