import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { listenToStock, getStockFromFirestore } from '../../../store/actions'
import useFirestoreCollection from '../../../hooks/useFirestoreCollection'
import styles from '../../../assets/styles/modules/Home.module.scss'
import { Link } from 'react-router-dom'
import stockIconGreenSrc from '../../../assets/images/home/stock-green.svg'
import stockIconDarkGreenSrc from '../../../assets/images/home/stock-dark-green.svg'
import stockIconGreySrc from '../../../assets/images/navigation/stock.svg'
import Spinner from '../../UI/Spinner/Spinner'

const StockItems = () => {
  const dispatch = useDispatch()
  const { stock } = useSelector((state) => state.stock)
  const { loading } = useSelector((state) => state.async)
  const { t } = useTranslation(['home', 'stock'])

  useFirestoreCollection({
    query: () => getStockFromFirestore(),
    data: (stock) => dispatch(listenToStock(stock)),
    deps: [dispatch],
  })

  if (loading) return <Spinner content={t('loading', { ns: 'home' })} />

  const classes = [styles.Task]
  if (stock.length) classes.push(styles.Done)
  return (
    <div className={classes.join(' ')}>
      <Link className={styles.TaskLink} to="/stock">
        <div className={styles.TaskIcon}>
          {!stock.length ? (
            <img src={stockIconGreySrc} alt="" border="0" />
          ) : (
            <>
              <img className={styles.TaskIconImg} src={stockIconGreenSrc} alt="" border="0" />
              <img className={styles.TaskIconImgHover} src={stockIconDarkGreenSrc} alt="" border="0" />
            </>
          )}
        </div>
        {stock.length === 0 ? (
          <>
            <strong className="xx-large">2.</strong> <span>{t('add_stock_items', { ns: 'stock' })}</span>
          </>
        ) : (
          t('manage_stock', { ns: 'home' })
        )}
      </Link>
    </div>
  )
}

export default StockItems
