import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { EditShowForm } from './EditShowForm'
import { httpsCallable } from 'firebase/functions'
import { useTranslation } from 'react-i18next'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../../../store/actions/userActions'
import useFirestoreDoc from '../../../../hooks/useFirestoreDoc'
import useFetchLocation from '../../../../hooks/useFetchLocation'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { setPageTitle } from '../../../../store/actions'
import { cloudFunctions } from '../../../../config/firebase'
import Spinner from '../../../UI/Spinner/Spinner'
import { getLocationArray } from '../../../../shared/utility'
import { getPayload, INITIAL_VALUES } from '../AddMenuItem/utils'
import { addDistributorsToIngredients } from './utils'

const EditMenuItem = () => {
  const dispatch = useDispatch()
  const { state } = useLocation()
  const history = useHistory()
  const { user } = useSelector((state) => state.user)
  const { locations } = useSelector((state) => state.location)
  const { current_step, id, location: selectedLocation } = useParams()
  const { fetchLocations } = useFetchLocation()
  const [loading, setLoading] = useState(true)
  const [menuItems, setMenuItems] = useState([])
  const formikRef = useRef(null)
  const [isBatchItem, setIsBatchItem] = useState(false)
  const [initialValues, setInitialValues] = useState(INITIAL_VALUES)
  const [isCombo, setIsCombo] = useState(false)
  const [menuItemName, setMenuItemName] = useState('')
  const itemAlreadyExists = useRef(false)
  const menuItemOriginalName = useRef(null)
  const { t } = useTranslation(['menu', 'validationMessages', 'common'])
  const locationArr = getLocationArray(locations)

  useEffect(() => {
    const loadData = async () => {
      const recipe = await httpsCallable(cloudFunctions, 'getRecipeByIdAndLocation')({ locationId: selectedLocation, recipeId: id })
      if ((current_step === 'third-step') || (current_step === 'first-step')) {
        const {
          servings,
          isBatchItem,
          isCombo,
          isSubrecipe,
          name,
          ingredients,
          ingredientsInfo,
          subrecipes,
          notes,
          locationIds,
          locations,
        } = recipe.data
  
        const parsedIngredients = addDistributorsToIngredients(ingredients, ingredientsInfo)
  
        const initial = {
          type: isCombo ? 'combo' : isSubrecipe ? 'side' : 'single',
          name,
          ingredients: parsedIngredients,
          subrecipes: Object.values(subrecipes).map((ingredient) => ingredient),
          notes,
          locationIds: !locationIds && !!selectedLocation ? [selectedLocation] : locationIds,
          locations,
          isBatchItem: isBatchItem || false,
          servings: servings || 0,
        }
  
        menuItemOriginalName.current = name
        setInitialValues(initial)
        setMenuItemName(name)
      }

      setLoading(false)
    }
    
    const loadMenuItems = async () => {
      const menus = await httpsCallable(cloudFunctions, 'getRecipesByLocation')({ locationId: selectedLocation })
      setMenuItems(menus.data)
    }

    loadData()
    loadMenuItems()
  }, [current_step, state])

  useEffect(() => {
    dispatch(setPageTitle(t('edit_item_title', { ns: 'menu' })))
    fetchLocations(dispatch)
  }, [dispatch])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  if (loading || !user) return <Spinner loading content={t('please_wait', { ns: 'common' })} />

  const menuItemSchema = (isBatchItem) => {
    let schemaAttributes = {
      name: Yup.string().required(t('menu_item', { ns: 'validationMessages' })),
      ingredients: Yup.array()
        .of(
          Yup.object().shape({
            amount: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(0, t('too_low', { ns: 'validationMessages' })).required(t('amount', { ns: 'validationMessages' })),
            unit_field: Yup.string().oneOf(['kg', 'g', 'L', 'mL', 'pcs']).required(t('unit', { ns: 'validationMessages' })),
          })
        )
        .required(t('add_ingredient', { ns: 'validationMessages' })),
      subrecipes: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(t('item', { ns: 'validationMessages' })),
          servings: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(1, t('too_low', { ns: 'validationMessages' })).required(t('servings', { ns: 'validationMessages' })),
        })
      ),
    }
    if (user.hasLocations) {
      schemaAttributes.locationIds = Yup.array().min(1, t('select_location', { ns: 'validationMessages' }))
    }
    if (isBatchItem) {
      schemaAttributes.servings = Yup.number()
          .typeError(t('number', { ns: 'validationMessages' }))
          .min(1, t('too_low', { ns: 'validationMessages' }))
          .required(t('number_of_servings', { ns: 'validationMessages' }))
    }
    // create and return the schema
    return Yup.object().shape(schemaAttributes)
  }

  const cleanIdProductOnboarding = (arr, value) =>
    arr.map((el) => (el.id_product_onboarding === value ? { ...el, id_product_onboarding: null } : el))

  const filterFromOnBoarding = (ingredients) => {
    const ingredientsFromStock = Object.values(ingredients).filter((ingredient) => ingredient.fromStock)
    const newIngredientsForStock = Object.values(ingredients).filter((ingredient) => !ingredient.fromStock)

    let result = Object.assign(
      {},
      ...ingredientsFromStock.map((ingredient) => {
        let { generic_name, fromStock, unit_field, ...rest } = ingredient
        const newIngredient = {
          ...rest,
          name: ingredient.name === '' ? generic_name : ingredient.name,
        }
        return { [ingredient.id]: newIngredient }
      })
    )

    result.onboarding = newIngredientsForStock.map((ingredient) => {
      let { name, id, fromStock, distributor, product, unit_field, ...rest } = ingredient
      return { ...rest }
    })

    return result
  }

  const filterSubrecipes = (subrecipes) => {
    return Object.assign(
      {},
      ...subrecipes.map((subrecipe) => {
        let { name, ...rest } = subrecipe
        const newSubrecipe = {
          name: name.text ? name.text : name,
          ...rest,
        }
        return { [subrecipe.id]: newSubrecipe }
      })
    )
  }

  const batchItemValidation = (value) => {
    setIsBatchItem(value)
  }

  const menuItemNameExists = (name) => {
    const filteredMenus = menuItems.filter(item => item.name !== menuItemOriginalName.current).map(e => e.name)
    return filteredMenus.includes(name)
  }

  const getLocationName = (id, locations) => locations && locations.find((el) => el.id === id).text

  const baseUrl = !selectedLocation ? `/menu/edit/${id}` : `/menu/edit/${id}/location/${selectedLocation}`

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={current_step === 'third-step' || isCombo ? menuItemSchema(isBatchItem) : Yup.object({})}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        localStorage.removeItem(baseUrl)
        let ingredientsArrayFormat = cleanIdProductOnboarding(values.ingredients, t('type_your_own_ingredient', { ns: 'menu' }))
        let subrecipesArrayFormat = values.subrecipes

        if (menuItemNameExists(values.name)) {
          setErrors({ recipes: t('name_already_exists', { name: values.name, ns: 'menu' })})
          setSubmitting(false)
        } else {
          if (values.ingredients.length > 0 || values.subrecipes.length > 0) {
            let ingredientsDictionary = filterFromOnBoarding(ingredientsArrayFormat)
            values.ingredients = { ...ingredientsDictionary }
  
            let subrecipesDictionary = filterSubrecipes(subrecipesArrayFormat)
            values.subrecipes = { ...subrecipesDictionary }
            const payload = getPayload(values)
            try {
              await httpsCallable(
                cloudFunctions,
                'updateMenuItem'
              )({
                id,
                values: payload,
                initialValues,
                locationId: selectedLocation ? selectedLocation.id ? selectedLocation.id : selectedLocation : null, 
              })
              setSubmitting(false)
              values.ingredients = ingredientsArrayFormat
              values.subrecipes = subrecipesArrayFormat
              history.push('/menu')
            } catch (error) {
              setErrors({ recipes: error.message })
              setSubmitting(false)
            }
          } else {
            setErrors({
              recipes: t('add_at_least_one_item', { ns: 'menu' }),
            })
            setSubmitting(false)
          }
        }
      }}
    >
      {({ values, errors, setFieldError, setFieldValue, resetForm, isSubmitting }) => {

        values.locationIds = [selectedLocation.id ? selectedLocation.id : selectedLocation]
        values.locations = {[selectedLocation.id ? selectedLocation.id : selectedLocation]: getLocationName(selectedLocation.id ? selectedLocation.id : selectedLocation, locationArr)}
        if ((current_step === 'first-step' || current_step === 'third-step') && (menuItemName !== values.name)) {
          setMenuItemName(values.name)
          itemAlreadyExists.current = menuItemNameExists(values.name)
        }

        return (
          <EditShowForm
            formikRef={formikRef}
            state={state}
            initialValues={initialValues}
            values={values}
            errors={errors}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
            resetForm={resetForm}
            isSubmitting={isSubmitting}
            batchItemValidation={batchItemValidation}
            baseUrl={baseUrl}
            setIsCombo={setIsCombo}
            isEdit
            itemNameExists={(current_step === 'first-step' || current_step === 'third-step') && itemAlreadyExists.current}
            selectedLocation={selectedLocation}
            menuItems={menuItems}
          />
        )
      }}
    </Formik>
  )
}

export default EditMenuItem
