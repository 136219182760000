import { Icon } from 'semantic-ui-react'
import styles from './assets/styles/modules/warning/SecurityWarning.module.scss'

const SecurityWarning = ({ website, href }) => (
    <div className={styles.SecurityWarning}>
        <div className={styles.IconContainer}>
            <Icon name="warning sign" className={styles.Icon} />
        </div>
        <div className={styles.Content}>
            <h1>Privacy threat detected</h1>
            <p>We sincerely advise that you <span className={styles.Italic}>do not proceed</span>.</p>
            <p>This may be a "phishing" website.</p>
            <p>It may try to illegitimately get your personal information.</p>
            <p className={styles.Footer}>This website may try to mimic: <a href={href} target="_blank" className={styles.Website} rel="noreferrer">{website}</a></p>
        </div>
    </div>
)

export default SecurityWarning
