import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  error: null,
  loading: false,
  message: null,
}

export default function asyncReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.ASYNC_ACTION_START:
      return updateObject(state, { loading: true, error: null, message: null })
    case actionTypes.ASYNC_ACTION_FINISH:
      return updateObject(state, { loading: false, message: action.message })
    case actionTypes.ASYNC_ACTION_ERROR:
      return updateObject(state, { loading: false, error: action.error })
    default:
      return state
  }
}
