import React, { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Checkbox } from 'semantic-ui-react'
import { useField } from 'formik'
import styles from '../../../assets/styles/modules/UI/CheckboxInputButton.module.scss'

const CheckboxInputButton = ({ defaultValue, selected = false, ...props }) => {
  const [checked, setChecked] = useState(false)
  const [field, meta, helpers] = useField(props.name)
  const { t } = useTranslation(['distributor'])
  const cleanProps = { ...props }
  delete cleanProps.prefix

  useEffect(() => {
    helpers.setValue(checked)
  }, [checked])

  useEffect(() => {
    helpers.setValue(selected)
  }, [selected])

  const getDayKey = (day) => {
    switch (day) {
      case 'Su': return 'sunday'
      case 'M': return 'monday'
      case 'Tu': return 'tuesday'
      case 'W': return 'wednesday'
      case 'Th': return 'thursday'
      case 'F': return 'friday'
      case 'Sa': return 'saturday'
      default: return ''
    }
  }

  return (
    <>
      <Trans
        t={t}
        ns={'distributor'}
        i18nKey={getDayKey(props.prefix)}
      >
        <span style={{ cursor: 'pointer' }}
          className={ checked || selected ? styles.FieldChecked : styles.FieldUnchecked }
          onClick={() => setChecked((prevChecked) => !prevChecked)}
        >
          {props.prefix}
        </span>
      </Trans>
      <Checkbox
        className={styles.Checkbox}
        {...props}
        checked={checked}
      />
    </>
  )
}

export default CheckboxInputButton
