import { SET_SORTING_DATA, CHANGE_SORTING } from './actionTypes'

export const setSortingData = (data, mainColumn = null) => {
  return {
    type: SET_SORTING_DATA,
    data,
    mainColumn,
  }
}

export const changeSorting = (sortByColumn, direction) => {
  return {
    type: CHANGE_SORTING,
    sortByColumn,
    direction,
  }
}
