import React from 'react'
import { NavLink } from 'react-router-dom'

const NavigationItem = (props) => {
  let className = 'nav-item ' + props.iconClass

  return (
    <li className={className}>
      <NavLink to={props.link} exact={props.exact}>
        {props.children}
      </NavLink>
    </li>
  )
}

export default NavigationItem
