import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { Formik, Form } from 'formik'
import { Button, FormField, Message } from 'semantic-ui-react'

import * as Yup from 'yup'
import TextInput from '../UI/Form/TextInput'
import CheckboxInput from '../UI/Form/CheckboxInput'
import { createUserInFirebase, authSuccess } from '../../store/actions/authActions'
import styles from '../../assets/styles/modules/containers/Auth.module.scss'
import { openModal } from '../../store/actions'

const Signup = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['validationMessages', 'settings', 'buttons'])

  if (localStorage.getItem('isSignedIn') === 'true') {
    return <Redirect to="/settings?welcome=true" />
  }

  const formFields = {
    companyName: '',
    email: '',
    password: '',
    confirmPassword: '',
    terms: false,
    hasPOS: false,
    hasChannels: false,
    hasMenu: false,
  }

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required(t('company_name', { ns: 'validationMessages' })),
    email: Yup.string().email(t('valid_email', { ns: 'validationMessages' })).required(t('email', { ns: 'validationMessages' })),
    password: Yup.string()
      .required(t('password', { ns: 'validationMessages' }))
      .min(6, t('password_characters', { amount: 6, ns: 'validationMessages' })),
    confirmPassword: Yup.string()
      .required(t('confirm_password', { ns: 'validationMessages' }))
      .test('passwords-match', function (value) {
        return this.parent.password === value
      }),
    terms: Yup.bool().oneOf([true], t('accept_terms', { ns: 'validationMessages' })),
  })

  return (
    <div className={styles.Auth}>
      <Formik
        initialValues={formFields}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const user = await createUserInFirebase(values)
            setSubmitting(false)
            dispatch(authSuccess(user))
            history.push('/settings?welcome=true')
          } catch (error) {
            setErrors({ auth: error.message })
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, touched }) => (
          <>
            {errors.auth && <Message error content={errors.auth} />}
            <Form autocomplete="off" className="ui form">
              <TextInput name="companyName" placeholder={t('company_name', { ns: 'settings' })} type="text" />
              <TextInput name="email" placeholder={t('email', { ns: 'settings' })} type="email" />
              <TextInput name="password" placeholder={t('password', { ns: 'settings' })} type="password" />
              <TextInput name="confirmPassword" placeholder={t('confirm_password', { ns: 'settings' })} type="password" />
              <FormField>
                <CheckboxInput
                  type="checkbox"
                  name="terms"
                  label={
                    <Trans
                      t={t}
                      ns={'settings'}
                      i18nKey="agree_terms"
                    >
                      <label className={'form-check-label'}>
                        By signing up I agree to the
                        <span
                          className="pointer link"
                          onClick={(e) => {
                            e.stopPropagation()
                            e.nativeEvent.stopImmediatePropagation()
                            dispatch(openModal({ modalType: 'Terms', modalProps: {} }))
                          }}
                        >
                          Terms of Service
                        </span>
                        and
                        <span
                          className="pointer link"
                          onClick={(e) => {
                            e.stopPropagation()
                            e.nativeEvent.stopImmediatePropagation()
                            dispatch(openModal({ modalType: 'Privacy', modalProps: {} }))
                          }}
                        >
                          Privacy Policy
                        </span>
                      </label>
                    </Trans>
                  }
                />
              </FormField>
              <Button
                loading={isSubmitting}
                disabled={!isValid || !dirty || isSubmitting}
                type="submit"
                color="green"
                content={t('sign_up', { ns: 'buttons' }).toUpperCase()}
                fluid
                size="large"
              />
            </Form>
          </>
        )}
      </Formik>
      <br />
      <div className="align-center">
        <p>
          {t('already_have_an_account', { ns: 'settings' })}{' '}
          <Link className='green-darker-text' to="/login">
            <strong>{t('log_in', { ns: 'buttons' })}</strong>
          </Link>
        </p>
      </div>
    </div>
  )
}

export default Signup
