import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { listenToStock, getStockFromFirestore } from '../../store/actions/stockActions'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../store/actions/userActions'
import useFirestoreDoc from '../../hooks/useFirestoreDoc'
import useFetchLocation from '../../hooks/useFetchLocation'
import useFirestoreCollection from '../../hooks/useFirestoreCollection'
import { setPageTitle, asyncActionStart, asyncActionError, asyncActionFinish } from '../../store/actions'
import { setSortingData, changeSorting } from '../../store/actions/sortingActions'
import useSearchCallback from '../../hooks/useSearchCallback'
import { Button, Checkbox, Message, Search, Table, Grid } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import SortingField from '../UI/Sorting/SortingField'
import GreenbytesModal from '../UI/GreenbytesModal/GreenbytesModal'
import StockItem from './StockItem/StockItem'
import PortalPopup from '../UI/Portal/PortalPopup'
import { cloudFunctions } from '../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import styles from '../../assets/styles/modules/stock/Stock.module.scss'
import cx from 'classnames'

const Stock = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['stock', 'buttons', 'common'])
  const { fetchLocations } = useFetchLocation()
  const target = history.location?.state?.target || localStorage.getItem('target')

  const { user } = useSelector((state) => state.user)
  const { stock } = useSelector((state) => state.stock)
  const { selectedLocation, locationArray, locations } = useSelector((state) => state.location)
  const { loading, error, success } = useSelector((state) => state.async)
  const { value, searchResults } = useSelector((state) => state.search)
  const { data, sortByColumn, direction } = useSelector((state) => state.sorting)

  const [confirmArchiveOpen, setConfirmArchiveOpen] = useState(null)
  const [itemForAction, setItemForAction] = useState({})
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(null)
  const [showArchived, setShowArchived] = useState(false)
  const [portalOpen, setPortalOpen] = useState(false)
  const [portalMessage, setPortalMessage] = useState('')

  useEffect(() => {
    dispatch(setPageTitle(t('stock')))
    fetchLocations(dispatch)
  }, [dispatch])

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(target)
      if (!element) { return }
      element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      history.replace({state: {}})
      localStorage.removeItem('target')
    }, 500);

    return () => clearTimeout(timer)
  }, [history, target])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  useFirestoreCollection({
    query: () => getStockFromFirestore(selectedLocation ? selectedLocation.id : null),
    data: (stock) => {
      dispatch(listenToStock(stock))
      dispatch(setSortingData([...stock], 'name'))
      dispatch(changeSorting('name', 'asc'))
    },
    deps: [dispatch, selectedLocation],
  })

  const handleSearchChange = useSearchCallback(stock)

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const handleSortBy = (columnName) => {
    const newDirection = sortByColumn === columnName ? (direction && direction === 'asc' ? 'desc' : 'asc') : 'asc'
    dispatch(changeSorting(columnName, newDirection))
  }

  const handleArchiveClick = (itemId, itemName) => {
    setItemForAction({ id: itemId, name: itemName })
    setConfirmArchiveOpen(true)
  }

  const handleDeleteClick = (itemId, itemName) => {
    setItemForAction({ id: itemId, name: itemName })
    setConfirmDeleteOpen(true)
  }

  const archiveStockItemHandler = async () => {
    setConfirmArchiveOpen(false)
    try {
      dispatch(asyncActionStart())
      await httpsCallable(
        cloudFunctions,
        'archiveStockItem'
      )({
        id: itemForAction.id,
        locationId: selectedLocation ? selectedLocation.id : null,
      })
      dispatch(asyncActionFinish())
      setPortalMessage(`"${itemForAction.name}" was archived.`)
      setPortalOpen(true)
    } catch (error) {
      dispatch(
        asyncActionError(
          error,
          `"${itemForAction.name}" could not be archived.`,
          `archiveStockItemHandler: ${error.message}`
        )
      )
      setPortalMessage(error.message)
      setPortalOpen(true)
    }
  }

  const restoreStockItemHandler = async (itemId) => {
    try {
      dispatch(asyncActionStart())
      await httpsCallable(
        cloudFunctions,
        'restoreStockItem'
      )({
        id: itemId,
        locationId: selectedLocation ? selectedLocation.id : null,
      })
      setPortalMessage(t('item_was_restored', { ns: 'stock' }))
      setPortalOpen(true)
    } catch (error) {
      dispatch(
        asyncActionError(error, t('could_not_be_restored', { ns: 'stock' }), `restoreStockItemHandler: ${error.message}`)
      )
      setPortalMessage(error.message)
      setPortalOpen(true)
    }
  }

  const deleteStockItemHandler = async () => {
    setConfirmDeleteOpen(false)
    try {
      dispatch(asyncActionStart())
      console.log(loading)
      await httpsCallable(
        cloudFunctions,
        'deleteStockItem'
      )({
        id: itemForAction.id,
        locationId: selectedLocation ? selectedLocation.id : null,
      })
      dispatch(asyncActionFinish(t('was_deleted', { ns: 'stock', item: itemForAction.name })))
      setPortalMessage(t('was_deleted', { ns: 'stock', item: itemForAction.name }))
      setPortalOpen(true)
    } catch (error) {
      dispatch(
        asyncActionError(error, `"${itemForAction.name}" could not be deleted.`, `archiveStockItem: ${error.message}`)
      )
      setPortalMessage(error.message)
      setPortalOpen(true)
    }
  }

  const handleCancelAction = () => {
    setItemForAction({})
    setConfirmArchiveOpen(false)
    setConfirmDeleteOpen(false)
  }

  const handleClosePortal = () => {
    setPortalOpen(false)
  }

  const activeLocations = () => (
    locationArray.map(loc => {
      const found = locations.find(element => element.id === loc.id && element.isActive);
      return found ? {...loc} : null
    }).filter(el => el !== null)
  )

  if (!selectedLocation) {
    return <Redirect to="/locations" />
  }

  return (
    <>
      <div className="btn-container top fixed center-content search">
        <Search
          id="ingredientSearch"
          loading={loading}
          onSearchChange={handleSearchChange}
          results={[]}
          showNoResults={false}
          value={value}
          placeholder={t('search_for_ingredient')}
        />
      </div>
      {error && <Message error>{error}</Message>}
      {success && <Message success content={success} />}

      <div className={styles.StockList}>
        <span>{t('items', { amount: stock.filter((item) => (!showArchived ? item.isArchived !== true : true)).length })}</span>
        <Grid className={styles.TopInputsGrid}>
          <Grid.Row className={styles.TopInputsRow}>
            <Grid.Column verticalAlign="middle" className={styles.TopInputsColumn}>
              <Checkbox
                label={t('show_archived')}
                onChange={() => setShowArchived(!showArchived)}
                style={{ marginRight: '10px' }}
                checked={showArchived}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {stock.length === 0 ? (
          <Message>
            <Trans
                t={t}
                ns={'stock'}
                i18nKey="add_new_item_to_get_started"
            >
              <Message.Content className="align-center">
                Hmm... looks like nothing's here.
                <br />
                <Button
                  style={{ margin: '10px 0' }}
                  color="green"
                  basic
                  onClick={() => history.push('/stock/add', { selectedLocation, locations })}>
                    Add New Item
                </Button>
                <br />
                to get started!
              </Message.Content>
            </Trans>
          </Message>
        ) : (
          selectedLocation == null && (
            <Message>
              <Message.Content className="align-center">{t('select_a_location', { ns: 'stock' })}</Message.Content>
            </Message>
          )
        )}

        {selectedLocation && data.length > 0 && (
          <Table sortable striped className={cx(styles.StockListTable, 'stock-list-table')}>
            <Table.Header className='stock-table-headers'>
              <Table.Row>
                <Table.HeaderCell className={cx(styles.MobileHeader, 'mobile-header')}>
                  <strong>{t('item')}</strong>
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortByColumn === 'name' ? (direction === 'asc' ? 'ascending' : 'descending') : null}
                  onClick={() => handleSortBy('name')}
                >
                  <SortingField
                    title={t('item')}
                    fieldName="name"
                    direction={direction === 'asc' ? 'ascending' : 'descending'}
                    active={sortByColumn === 'name' ? true : false}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="center">
                  <span>{locations.length > 0 && !selectedLocation ? t('locations') : t('amount')}</span>
                </Table.HeaderCell>
                <Table.HeaderCell className='desktop-header'>{t('item_no')}</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sortByColumn === 'distributor' ? (direction === 'asc' ? 'ascending' : 'descending') : null}
                  onClick={() => handleSortBy('distributor')}
                >
                  <SortingField
                    title={t('distributor')}
                    fieldName="distributor"
                    direction={direction === 'asc' ? 'ascending' : 'descending'}
                    active={sortByColumn === 'distributor' ? true : false}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data.map((item) => {
                if (searchResults.length && !searchResults.find((ing) => ing.id === item.id)) {
                  return null
                } else {
                  if (!item.isArchived || (item.isArchived && showArchived)) {
                    return (
                      <StockItem
                        key={item.id}
                        item={item}
                        selectedLocation={selectedLocation}
                        hasLocations={locations.length > 0}
                        locations={locations.length > 0 ? locationArray.filter((i) => i.id !== 0) : []}
                        onArchiveClick={handleArchiveClick}
                        onDeleteClick={handleDeleteClick}
                        onRestoreClick={restoreStockItemHandler}
                        locationsUnfiltered={locations}
                      />
                    )
                  }
                  return null
                }
              })}
            </Table.Body>
            <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
              <span className={stock.length > 0 && (!locations.length > 0 || selectedLocation) ? styles.ButtonWrapper : styles.ButtonWrapperCentered}>
              <Button
                className={cx(styles.Button, styles.Green)}
                color="green"
                basic
                size="big"
                onClick={() => history.push('/stock/add', { selectedLocation, locations })}
              >
                {t('add_item', { ns: 'buttons' }).toUpperCase()}
              </Button>
              {stock.length > 0 && (!locations.length > 0 || selectedLocation) && (
                <Button
                  className={cx(styles.Button, styles.Green)}
                  color="green"
                  size="big"
                  onClick={() => {
                    if (locations.length > 0) {
                      history.push(props.match.path + '/edit/location/' + selectedLocation.id)
                    } else {
                      history.push(props.match.path + '/edit')
                    }
                  }}
                >
                  {t('update', { ns: 'buttons' }).toUpperCase()}
                </Button>
              )}
              </span>
            </div>
          </Table>
        )}
        <GreenbytesModal
          confirmDeleteOpen={confirmArchiveOpen}
          setConfirmDeleteOpen={setConfirmArchiveOpen}
          text={
            locations.length > 0 && selectedLocation
              ? t('want_to_archive_location', { ns: 'stock', item_name: itemForAction.name, location_name: selectedLocation.name })
              : t('want_to_archive', { ns: 'stock', item_name: itemForAction.name })
          }
          confirmButtonText={'archive'}
          cancelButtonText={'cancel'}
          handleConfirmClick={archiveStockItemHandler}
          cancelButtonAction={handleCancelAction}
        />
        <GreenbytesModal
          confirmDeleteOpen={confirmDeleteOpen}
          setConfirmDeleteOpen={setConfirmDeleteOpen}
          text={
            locations.length > 0 && selectedLocation
              ? t('want_to_delete_location', { ns: 'stock', item_name: itemForAction.name, location_name: selectedLocation.name })
              : t('want_to_delete', { ns: 'stock', item_name: itemForAction.name })
          }
          confirmButtonText={'delete'}
          cancelButtonText={'cancel'}
          handleConfirmClick={deleteStockItemHandler}
          cancelButtonAction={handleCancelAction}
        />
        <PortalPopup isOpen={portalOpen} message={portalMessage} onCloseClick={handleClosePortal} />
      </div>
    </>
  )
}

export default Stock
