import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { asyncActionError, asyncActionStart, asyncActionFinish } from '../store/actions'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../config/firebase'

async function updateUserHasLocations(hasLocations) {
  try {
    await httpsCallable(cloudFunctions, 'updateLocationsSetting')({ hasLocations })
  } catch (error) {
    return { error }
  }
}

export const useMultipleLocations = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user)
  const { locations } = useSelector((state) => state.location)

  useEffect(() => {
    let hasActiveLocations = true
    if (user && !user.hasLocations && locations.length > 0) {
      hasActiveLocations = true
      const { error } = updateUserHasLocations(hasActiveLocations)
      if (error) dispatch(asyncActionError(error))
    } else if (user && user.hasLocations && locations.length === 0) {
      hasActiveLocations = false
      const { error } = updateUserHasLocations(hasActiveLocations)
      if (error) dispatch(asyncActionError(error))
    }
  }, [user])

  return {
    useMultipleLocations,
  }
}
