import * as actionTypes from '../actions/actionTypes'

// state is either { title, body } or null
const initialState = null

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_NOTIFICATION:
      return payload ? { ...payload } : null
    default:
      return state
  }
}

export default reducer
