import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import styles from '../../../../assets/styles/modules/UI/AddDistributorButton.module.scss'

const AddDistributorButton = ({ fieldArrayHelper }) => {
  const { t } = useTranslation(['stock'])

  return (
    <>
      <Button
        className={styles.MobileButton}
        type="button"
        basic
        color="green"
        size="big"
        onClick={() => fieldArrayHelper.push({ name: '', itemNumber: '' })}
        content={
          <>
            <Icon color="green" name="plus circle" /> {t('add_another_distributor', { ns: 'stock' })}
          </>
        }
      />
      <p className={styles.DesktopButton}>
        <span className="pointer" onClick={() => fieldArrayHelper.push({ name: '', itemNumber: '' })}>
          {t('add_another_distributor', { ns: 'stock' })} <Icon color="green" name="plus circle" />
        </span>
      </p>
    </>
  )
}

export default AddDistributorButton
