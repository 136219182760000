import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import authReducer from './store/reducers/authReducer'
import modalReducer from './store/reducers/modalReducer'
import asyncReducer from './store/reducers/asyncReducer'
import navigationReducer from './store/reducers/navigationReducer'
import searchReducer from './store/reducers/searchReducer'
import orderReducer from './store/reducers/orderReducer'
import userReducer from './store/reducers/userReducer'
import paginationReducer from './store/reducers/paginationReducer'
import sortingReducer from './store/reducers/sortingReducer'
import distributorReducer from './store/reducers/distributorReducer'
import stockReducer from './store/reducers/stockReducer'
import menuReducer from './store/reducers/menuReducer'
import locationReducer from './store/reducers/locationReducer'
import subscriptionReducer from './store/reducers/subscriptionReducer'
import posReducer from './store/reducers/posReducer'
import notificationReducer from './store/reducers/notificationReducer'

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth: authReducer,
    modal: modalReducer,
    async: asyncReducer,
    nav: navigationReducer,
    search: searchReducer,
    order: orderReducer,
    user: userReducer,
    pagination: paginationReducer,
    sorting: sortingReducer,
    distributor: distributorReducer,
    stock: stockReducer,
    menu: menuReducer,
    location: locationReducer,
    subscription: subscriptionReducer,
    pos: posReducer,
    notification: notificationReducer,
  })

export default createRootReducer
