import { useState } from 'react'
import { asyncActionStart, asyncActionFinish, listenToLocations } from '../store/actions'
import { cloudFunctions } from '../config/firebase'
import { httpsCallable } from 'firebase/functions'

export default function useFetchLocation() {

  const [loading, setLoading] = useState(true)

  const fetchLocations = async (dispatch, onlyActive = false) => {
    dispatch(asyncActionStart())
    const response = await httpsCallable(cloudFunctions, 'getLocations')({ onlyActive: onlyActive })
    if (response.data.error) {
      dispatch(listenToLocations([]))
    } else {
      dispatch(listenToLocations(response.data))
    }
    dispatch(asyncActionFinish())
    setLoading(false)
  }

  const fetchLocationsPOS = async (token, locationId, dispatch, setLoadingChannels, setChannels, receivedPos) => {
    setLoadingChannels(true)
    const response = await httpsCallable(
      cloudFunctions,
      'getLocationsPOS'
    )({
      locationId,
      user_token: token,
    })
    if (response.data.error) {
      setLoadingChannels(false)
      dispatch(receivedPos([]))
    } else {
      dispatch(receivedPos(response.data.locations))
      setChannels(
        response.data.channels.map((channel) => {
          return {
            key: channel.UUID,
            text: channel.Channel,
            value: channel.UUID,
          }
        })
      )
      setLoadingChannels(false)
    }
    setLoading(false)
  }

  return {
    fetchLocations,
    fetchLocationsPOS,
    loading
  }
}
