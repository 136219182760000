import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Prompt } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import StockItem from './StockItem'
import { getStockFromFirestore, listenToStock } from '../../../store/actions/stockActions'
import { asyncActionError, setPageTitle } from '../../../store/actions'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../../config/firebase'
import useFirestoreCollection from '../../../hooks/useFirestoreCollection'
import { Button, Message, Search, Table, Modal } from 'semantic-ui-react'
import Spinner from '../../UI/Spinner/Spinner'
import { Form, Formik, FieldArray } from 'formik'
import useSearchCallback from '../../../hooks/useSearchCallback'
import * as Yup from 'yup'
import styles from '../../../assets/styles/modules/stock/EditStock.module.scss'
import GreenbytesModal from '../../UI/GreenbytesModal/GreenbytesModal'
import cx from 'classnames'

// This component is unreacheable now
const EditStock = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['validationMessages', 'common', 'stock'])
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const { stock } = useSelector((state) => state.stock)
  const { loading, error } = useSelector((state) => state.async)
  const { value, searchResults } = useSelector((state) => state.search)
  const { selectedLocation } = useSelector((state) => state.location)

  useEffect(() => {
    dispatch(setPageTitle(t('update', { ns: 'stock' })))
  }, [dispatch])

  useFirestoreCollection({
    query: () => getStockFromFirestore(props.match.params.location),
    data: (stock) => dispatch(listenToStock(stock)),
    deps: [dispatch],
  })

  const handleSearchChange = useSearchCallback(stock)

  if (loading) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const initialValues = {
    items: stock.map((item) => ({
      id: item.id,
      name: item.name,
      itemNumber: item.itemNumber ? item.itemNumber : '',
      amount: item.amount,
      isArchived: item.isArchived ? item.isArchived : false,
      hasMultipleDistributors: !!item.distributors,
    })),
  }

  Yup.addMethod(Yup.array, 'unique', function (message) {
    return this.test('unique', message, function (list) {
      const mapper = (x) => x.name
      const set = [...new Set(list.map(mapper))]
      const isUnique = list.length === set.length
      if (isUnique) {
        return true
      }
      const idx = list.findIndex((l, i) => mapper(l) !== set[i])
      return this.createError({
        path: `items.[${idx}].name`,
        message: message,
      })
    })
  })

  return (
    <>
      <div className="btn-container top fixed center-content search">
        <Search
          id="ingredientSearch"
          loading={loading}
          onSearchChange={handleSearchChange}
          results={[]}
          showNoResults={false}
          value={value}
          placeholder={t('search_for_ingredient', { ns: 'stock' })}
        />
      </div>

      <div className="counters">
        <span>{t('items', { ns: 'stock', amount: stock.length })}</span>
      </div>
      {error && <Message>{t('something_wrong', { ns: 'common' , error })}</Message>}
      {!!props.match.params.location !== false && selectedLocation && (
        <Message>
          <Message.Content className="align-center">
            <Trans
              t={t}
              ns={'stock'}
              i18nKey={'editing_the_stock'}
            >
              You're editing the stock of <strong>{{ location: selectedLocation.name }}</strong>. Changes made here will not affect your other locations.
            </Trans>
          </Message.Content>
        </Message>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true)
          try {
            await httpsCallable(
              cloudFunctions,
              'updateStockItems'
            )({
              values,
              initialValues,
              locationId: props.match.params.location,
            })
            history.push('/stock')
          } catch (error) {
            setErrors({ stock: error.message })
            setSubmitting(false)
            dispatch(asyncActionError(error, 'Stock items could not be updated.', `updateStockItems: ${error.message}`))
          }
        }}
        validationSchema={Yup.object().shape({
          items: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().required(t('item_name', { ns: 'validationMessages' })),
                amount: Yup.number()
                  .typeError(t('invalid_number', { ns: 'validationMessages' }))
                  .min(0, t('below_zero', { ns: 'validationMessages' }))
                  .required(t('amount', { ns: 'validationMessages' })),
              })
            )
            .unique(t('item_name_exists', { ns: 'validationMessages' })),
        })}
        enableReinitialize={true}
      >
        {({ isSubmitting, errors, dirty, setFieldValue, values, isValid, handleReset }) => (
          <>
            {errors.stock && <Message error content={errors.stock} style={{ marginBottom: 10 }} />}
            <Form autocomplete="off" className={cx(styles.EditStockContainer, 'ui form')}>
              <FieldArray name="items">
                <Table striped compact className="edit-stock-table" style={{ width: '100%' }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={5}>{t('item', { ns: 'stock' })}</Table.HeaderCell>
                      <Table.HeaderCell width={4}>{t('item', { ns: 'stock' })} #</Table.HeaderCell>
                      <Table.HeaderCell width={3} textAlign="center">
                        {t('status', { ns: 'stock' })}
                      </Table.HeaderCell>
                      <Table.HeaderCell colSpan={4}>{t('amount_in_stock', { ns: 'stock' })}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {values.items.map((item, index) => {
                      if (
                        stock[index].isArchived ||
                        (searchResults.length && !searchResults.find((ing) => ing.id === item.id))
                      ) {
                        return null
                      } else {
                        return (
                          <StockItem
                            key={index}
                            index={index}
                            item={item}
                            unit={stock[index].unit}
                            fieldValueSetter={setFieldValue}
                            currentValues={values}
                          />
                        )
                      }
                    })}
                  </Table.Body>
                </Table>
              </FieldArray>
              <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapper}>
                  <Button
                    disabled={isSubmitting}
                    type="button"
                    basic
                    color="orange"
                    content={t('cancel', { ns: 'buttons' }).toUpperCase()}
                    size="big"
                    onClick={async () => {
                      await handleReset()
                      history.push('/stock')
                    }}
                  />
                  <Button
                    loading={isSubmitting}
                    onClick={() => {
                      setConfirmDeleteOpen(!isValid)
                    }}
                    type="submit"
                    color="green"
                    content={t('save', { ns: 'buttons' }).toUpperCase()}
                    size="big"
                  />
                </span>
              </div>
              <Prompt
                when={dirty && !isSubmitting}
                message={t('leave_without_saving', { ns: 'stock' })}
              />
            </Form>
          </>
        )}
      </Formik>
      <GreenbytesModal
        confirmDeleteOpen={confirmDeleteOpen}
        setConfirmDeleteOpen={setConfirmDeleteOpen}
        text={t('invalid_items', { ns: 'stock' })}
        confirmButtonText={'ok'}
      />
    </>
  )
}

export default EditStock
