import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Table, Button, Popup, Icon } from 'semantic-ui-react'
import styles from '../../../assets/styles/modules/stock/StockItem.module.scss'
import GreenbytesModal from '../../UI/GreenbytesModal/GreenbytesModal'
import { formatNumber, getLocationsFromItem } from '../../../shared/utility'
import useBreakpoint from '../../../hooks/useBreakpoint'
import cx from 'classnames'

const StockItem = ({
  hasLocations,
  item,
  onArchiveClick,
  onDeleteClick,
  onRestoreClick,
  selectedLocation,
  locationsUnfiltered,
}) => {
  const history = useHistory()
  const [classes, setClasses] = useState(item.isArchived ? [styles.Archived] : [])
  const breakpoint = useBreakpoint()
  const { t } = useTranslation(['stock', 'buttons'])
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const triggerButton = (
    <Button
      basic
      className={cx(styles.ActionButton, 'stock-trigger-button')}
      size="small"
      content={breakpoint === 'xs' ? '...' : t('actions', { ns: 'buttons' })}
      onFocus={() => setClasses(classes.concat(['active']))}
      onBlur={() => setClasses(classes.filter((c) => c !== 'active'))}
    />
  )

  const popupContent = (
    <>
      {breakpoint !== 'xs' ? (
        <div className={styles.Actions}>
          <Button
            icon
            size="small"
            basic
            onClick={() => {
              if (!hasLocations) {
                onDeleteClick(item.id, item.name, item.locations)
              } else {
                if (item.locations && Object.keys(item.locations).length > 0) {
                  setConfirmDeleteOpen(true)
                } else {
                  onDeleteClick(item.id, item.name, item.locations)
                }
              }
            }}
            title={t('delete', { ns: 'buttons' })}
          >
            <Icon name="trash" />
            <span>{t('delete', { ns: 'buttons' })}</span>
          </Button>
          {!item.isArchived && (
            <Button
              size="small"
              icon
              basic
              onClick={() => {
                onArchiveClick(item.id, item.name)
              }}
              title={t('archive', { ns: 'buttons' })}
            >
              <Icon name="archive" />
              <span>{t('archive', { ns: 'buttons' })}</span>
            </Button>
          )}
          {item.isArchived && (
            <Button size="small" icon basic onClick={() => onRestoreClick(item.id, item.name)} title="Restore">
              <Icon name="undo" />
              <span>{t('restore')}</span>
            </Button>
          )}
          {selectedLocation && (
            <Button
              size="small"
              icon
              basic
              color="green"
              title={t('edit', { ns: 'buttons' })}
              onClick={() => {
                localStorage.setItem('target', item.id)
                history.push('/stock/edit/item', { selectedLocation, locations: locationsUnfiltered, item, target: item.id })
              }}
              disabled={!selectedLocation}
            >
              <Icon name="pencil" color="green" />
              <span>{t('edit', { ns: 'buttons' })}</span>
            </Button>
          )}
        </div>
      ) : (
        <div className={styles.MobileActions}>
          <span>{t('actions', { ns: 'buttons' })}</span>
          <div
            className={styles.MobileActionButton}
            onClick={() => {
              if (!hasLocations) {
                onDeleteClick(item.id, item.name, item.locations)
              } else {
                if (item.locations && Object.keys(item.locations).length > 0) {
                  setConfirmDeleteOpen(true)
                } else {
                  onDeleteClick(item.id, item.name, item.locations)
                }
              }
            }}
          >
            <Icon name="trash" />
            <span>{t('delete', { ns: 'buttons' })}</span>
          </div>
          {!item.isArchived && (
            <div
              className={styles.MobileActionButton}
              onClick={() => {
                onArchiveClick(item.id, item.name)
              }}
            >
              <Icon name="archive" />
              <span>{t('archive', { ns: 'buttons' })}</span>
            </div>
          )}
          {item.isArchived && (
            <div className={styles.MobileActionButton} onClick={() => onRestoreClick(item.id, item.name)}>
              <Icon name="undo" />
              <span>{t('restore')}</span>
            </div>
          )}
          {selectedLocation && (
            <div
              className={styles.MobileActionButton}
              onClick={() => {
                localStorage.setItem('target', item.id)
                history.push('/stock/edit/item', { selectedLocation, locations: locationsUnfiltered, item, target: item.id })
              }}
            >
              <Icon name="pencil" />
              <span>{t('edit', { ns: 'buttons' })}</span>
            </div>
          )}
        </div>
      )}
    </>
  )

  return (
    <>
      {breakpoint !== 'xs' ? (
        <Table.Row id={item.id} data-id="desktop-stock-item" key={item.id} className={classes.join(' ')}>
          <Table.Cell>
            <span>{item.name}</span>
          </Table.Cell>
          {(!hasLocations || selectedLocation) && (
            <Table.Cell textAlign="center">
              <label>Amount</label>
              <span>
                {formatNumber(item.amount, item.unit)} {item.unit}
              </span>
            </Table.Cell>
          )}
          {hasLocations && !selectedLocation && (
            <Table.Cell className={styles.Locations}>
              <label>Locations</label>
              {getLocationsFromItem(item.locations)}
            </Table.Cell>
          )}
          <Table.Cell>{item.itemNumber && <p>{item.itemNumber}</p>}</Table.Cell>
          <Table.Cell>
            <label>Distributor</label>
            {Object.values(item.distributors).length > 1 ? (
              <span>{t('multiple_distributors')}</span>
            ) : (
              <span>{Object.values(item.distributors)[0]?.name}</span>
            )}
          </Table.Cell>
          <Table.Cell textAlign="right">
            <Popup
              style={{ zIndex: '3', maxWidth: '350px', backgroundColor: '#F9FAF' }}
              on="click"
              position="left center"
              trigger={triggerButton}
              content={popupContent}
            />
            <div className="clear" />
          </Table.Cell>
        </Table.Row>
      ) : (
        <Table.Row id={item.id} data-id="mobile-stock-item" key={item.id} className={classes.join(' ')}>
          <Table.Cell>
            <span>{item.name}</span>
          </Table.Cell>
          <Table.Cell>
            <p>
              {Object.values(item.distributors).length > 1
                ? t('multiple_distributors')
                : Object.values(item.distributors)[0]?.name}
            </p>
            <Popup
              style={{ zIndex: '3', maxWidth: '350px', backgroundColor: '#F9FAF', padding: '10px 16px' }}
              on="click"
              size="huge"
              position="left center"
              trigger={triggerButton}
              content={popupContent}
            />
          </Table.Cell>
          <Table.Cell>
            <p>{t('item_no', { ns: 'stock' })} {item.itemNumber}</p>
          </Table.Cell>
          <Table.Cell>
            {
              selectedLocation ?
              <p>Location: {selectedLocation.name}</p>
              :
              <p>Location: {Object.values(item.locations)[0] || "Unknow"}</p>
            }
            
          </Table.Cell>
        </Table.Row>
      )}
      <GreenbytesModal
        confirmDeleteOpen={confirmDeleteOpen}
        setConfirmDeleteOpen={setConfirmDeleteOpen}
        text={t('delete_item')}
        confirmButtonText={'ok'}
      />
    </>
  )
}

export default StockItem
