import React from 'react'
import { useTranslation } from 'react-i18next'
import splashStyles from '../../assets/styles/modules/Splash.module.scss'
import splashLogoSrc from '../../assets/images/gb_logo_loading.gif'

const SplashScreen = () => {
  const { t } = useTranslation(['greenbytes'])

  return (
    <div className={splashStyles.SplashScreen}>
      <div className={splashStyles.Branding}>
        <div className={splashStyles.Logo}>
          <img src={splashLogoSrc} border="0" alt="GreenBytes" />
        </div>
        <div className={splashStyles.Slogan}>{t('slogan', { ns: 'greenbytes' })}</div>
      </div>
    </div>
  )
}

export default SplashScreen
