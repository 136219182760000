import * as actionTypes from './actionTypes'
import { query, collection, where } from 'firebase/firestore'
import { firestore } from '../../config/firebase'
import { getUserId } from './authActions'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../config/firebase'

export const listenToSubscriptions = (subscriptions) => {
  return {
    type: actionTypes.GET_SUBSCRIPTIONS_SUCCESS,
    subscriptions: subscriptions,
  }
}

export function getSubscriptionsFromFirestore() {
    return query(
        collection(firestore, `users/${getUserId()}/subscriptions`),
        where('status', 'in', ['active', 'trialing'])
    );
}

export const listenToSubscriptionPlans = (plans) => {
  return {
    type: actionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS,
    plans: plans,
  }
}

export const getSubscriptionPlansFromFirestore = () => {
  return httpsCallable(cloudFunctions, 'getSubscriptionPlansPricesFromFirestore')()
}
