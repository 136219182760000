import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  user: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_PROFILE_SUCCESS:
      const isComplete =
        action.user && action.user.companyName && action.user.street && action.user.postalCode && action.user.place
          ? true
          : false

      return updateObject(state, {
        user: updateObject(action.user, { isComplete: isComplete }),
      })
    default:
      return state
  }
}

export default reducer
