import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Link, useLocation } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button, Checkbox, FormField, Label, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import TextInput from '../../components/UI/Form/TextInput'
import styles from '../../assets/styles/modules/containers/Auth.module.scss'
import { openModal } from '../../store/actions'
import { signInWithEmail } from '../../store/actions/authActions'

const Login = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['validationMessages'])
  const { isAuthenticated } = useSelector((state) => state.auth)
  const { message } = useSelector((state) => state.async)
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)

  if (isAuthenticated) {
    if (searchParams.get('redirectUrl')) {
      return <Redirect to={`${searchParams.get('redirectUrl')}`} />
    } 
    return <Redirect to="/" />
  }

  return (
    <div className={styles.Auth}>
      {message && <Message success content={message} />}
      <Formik
        initialValues={{ email: '', password: '', saveLogin: 0 }}
        validationSchema={Yup.object({
          email: Yup.string().email(t('valid_email', { ns: 'validationMessages' })).required(t('email', { ns: 'validationMessages' })),
          password: Yup.string().required(t('password', { ns: 'validationMessages' })),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await signInWithEmail(values)
          } catch (error) {
            setErrors({ auth: error.message })
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, isValid, dirty, errors, setFieldValue }) => (
          <Form autocomplete="off" className="ui form">
            {errors.auth && <Label basic color="red" content={errors.auth} style={{ marginBottom: 10 }} />}
            <TextInput name="email" placeholder="Email" type="email" />
            <TextInput name="password" placeholder="Password" type="password" />
            <p
              className="pointer blue-text"
              onClick={() => dispatch(openModal({ modalType: 'ForgotPassword', modalProps: {} }))}
            >
              Forgot password?
            </p>
            <FormField>
              <Checkbox
                name="saveLogin"
                label="Remember log in credentials."
                toggle
                onClick={(e, d) => {
                  setFieldValue('saveLogin', !!d.checked)
                }}
              />
            </FormField>
            <Button
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type="submit"
              color="green"
              content="LOG IN"
              fluid
              size="large"
            />
          </Form>
        )}
      </Formik>
      <br />
      <div className="flex center-content gap-10">
        <p>Don't have an account? </p>
        <Link to="/signup">
          <p className="green-darker-text">
            <strong>Make one</strong>
          </p>
        </Link>
      </div>
    </div>
  )
}

export default Login
