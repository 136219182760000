import { SET_SORTING_DATA, CHANGE_SORTING } from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'
import _ from 'lodash'

const initialState = {
  sortByColumn: null,
  direction: null,
  mainColumn: null,
  data: [],
}

const sortingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SORTING_DATA:
      return updateObject(state, { data: action.data, mainColumn: action.mainColumn })
    case CHANGE_SORTING:
      let sortedData = []

      if (state.mainColumn && action.sortByColumn !== state.mainColumn) {
        sortedData = _.orderBy(state.data, [action.sortByColumn, state.mainColumn], [action.direction, 'asc'])
      } else {
        sortedData = _.orderBy(state.data, [action.sortByColumn], [action.direction])
      }

      return updateObject(state, {
        sortByColumn: action.sortByColumn,
        data: sortedData,
        direction: action.direction,
      })
    default:
      return state
  }
}

export default sortingReducer
