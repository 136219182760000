import { db } from '../config/firebase'
import { ref, set } from 'firebase/database'
import moment from 'moment'
import { getUserId } from '../store/actions/authActions'

export function writeLog(level = 'DEBUG', message) {
  const now = new Date()
  //console.log(level + ': ' + message);
  set(ref(db, `${moment(now).format('YYYY-MM-DD')}/${moment(now).format('HH-mm-ss_x')}`), {
    level: level,
    message: message,
    createdAt: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    uid: getUserId(),
  })
}
