import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import createRootReducer from './reducers'

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
  const composeEnhancers = composeWithDevTools({
    trace: true,
  })

  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      composeEnhancers(
        applyMiddleware(
          routerMiddleware(history), // for dispatching history actions
          thunk
        )
      )
    )
  )
  return store
}
