import * as actionTypes from './actionTypes'
import { writeLog } from '../../shared/firebaseLogger'

export function asyncActionStart() {
  return {
    type: actionTypes.ASYNC_ACTION_START,
  }
}

export function asyncActionFinish(message = '') {
  return {
    type: actionTypes.ASYNC_ACTION_FINISH,
    message: message,
  }
}

export function asyncActionError(error, userMessage = '', func = '') {
  writeLog('ERROR', func + error.message)
  return {
    type: actionTypes.ASYNC_ACTION_ERROR,
    error: userMessage.length > 0 ? userMessage : error.message,
  }
}
