import React, { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Icon, Image, Message } from 'semantic-ui-react'
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'
import styles from '../../assets/styles/modules/image-upload/ProfileImage.module.scss'
import { auth } from '../../config/firebase'
import Spinner from '../UI/Spinner/Spinner'
import moment from 'moment'

const ProfileImage = (props) => {
  const { t } = useTranslation(['errorMessages'])
  const imgSrc = props.currentImage ? props.currentImage : props.placeholder
  const profileImgClasses = []
  if (props.currentImage) {
    profileImgClasses.push(styles.UploadedImage)
  }
  const [uploading, setUploading] = useState(false)
  const [uploadError, setUploadError] = useState(null)

  useEffect(() => {
    return () => {
      setUploading(false)
      setUploadError(null)
    }
  }, [])

  const onDrop = useCallback(
    (uploadedFiles) => {
      for (const file of uploadedFiles) {
        if (['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
          setUploading(true)
          const user = auth.currentUser
          const storage = getStorage()

          // Build filename with timestamp because updating an existing image breaks previous download URLs
          const filenameArr = file.name.split('.')
          const ext = filenameArr.pop()
          const filename = filenameArr.join('-') + '_' + moment().format('YYYYMMDDHHmmss') + '.' + ext
          const storageRef = ref(storage, `user_images/${user.uid}/${filename}`)
          const uploadTask = uploadBytesResumable(storageRef, file)

          uploadTask.on(
            'state_changed',
            (snapshot) => {
              setUploading(true)
            },
            (error) => {
              setUploading(false)
              setUploadError(t('image_upload', { message: error.message, ns: 'errorMessages' }))
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                setUploading(false)
                props.fieldValueSetter(props.fieldName, downloadURL)
              })
            }
          )
        } else {
          setUploadError(t('image_upload', { message: 'Invalid file extension', ns: 'errorMessages' }))
        }
      }
    }, [])

  const { getRootProps } = useDropzone({ onDrop })

  if (uploading) return <div className={styles.ProfileImage}><Spinner content="Uploading image..." /></div>

  return (
    <div className={styles.ProfileImage} {...getRootProps()}>  
      { uploadError ? 
        <Message error content={uploadError} style={{ minHeight: '100%', borderRadius: '50%', textAlign: 'center', display: 'flex', alignItems: 'center' }} />
      :
        <Image className={profileImgClasses.join('')} src={imgSrc} alt="" />
      }
      <Icon name="add circle" size="large" className={styles.AddIcon} color="green" />
    </div>
  )
}

export default ProfileImage
