import React from 'react'
import styles from '../../../assets/styles/modules/UI/SortingField.module.scss'

const SortingField = (props) => {
  const classes = [styles.SortingField]
  if (props.active) classes.push(styles.Active)

  /*let sortOrderArrow = <Icon name='long arrow alternate down' />;
    if (props.active && (props.direction === 'desc')) {
        sortOrderArrow = <Icon name='long arrow alternate up' />;
    }*/

  return (
    <div className={classes.join(' ')}>
      <strong>{props.title}</strong>
    </div>
  )
}

export default SortingField
