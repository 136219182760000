import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ModalWrapper from '../../UI/Modal/ModalWrapper'
import { Button, Label } from 'semantic-ui-react'
import { Form, Formik } from 'formik'
import TextInput from '../../UI/Form/TextInput'
import * as Yup from 'yup'

import { changeUserEmail } from '../../../store/actions/userActions'
import btnStyles from '../../../assets/styles/modules/UI/Button.module.scss'
import { asyncActionFinish, closeModal } from '../../../store/actions'

const ChangeEmail = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['validationMessages', 'changeEmail', 'buttons'])

  const formFields = {
    newEmail: '',
    currentPassword: '',
  }
  const validationSchema = Yup.object().shape({
    newEmail: Yup.string().email(t('valid_email', { ns: 'validationMessages' })).required(t('current_email', { ns: 'validationMessages' })),
    currentPassword: Yup.string().required(t('current_password', { ns: 'validationMessages' })),
  })

  return (
    <ModalWrapper size="mini" header={t('change', { ns: 'changeEmail'})}>
      <Formik
        initialValues={formFields}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await changeUserEmail(values)
            setSubmitting(false)
            dispatch(asyncActionFinish(t('email_has_been_changed', { ns: 'changeEmail'})))
            dispatch(closeModal())
          } catch (error) {
            setErrors({
              auth: t('email_could_not_be_changed', { ns: 'changeEmail'})
            })
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form autocomplete="off" className="ui form">
            {errors.auth && <Label basic color="red" content={errors.auth} style={{ marginBottom: 10 }} />}
            <TextInput name="newEmail" placeholder={t('new_email', { ns: 'changeEmail'})} type="text" />
            <TextInput name="currentPassword" placeholder={t('current_password', { ns: 'changeEmail'})} type="password" />
            <br />
            <br />
            <div className="btn-container bottom fixed flex space-between full-width gap-16">
              <Button
                disabled={isSubmitting}
                className={btnStyles.Left}
                type="button"
                color="red"
                basic
                size="large"
                onClick={() => dispatch(closeModal())}
              >
                {t('cancel', { ns: 'buttons' }).toUpperCase()}
              </Button>
              <Button
                loading={isSubmitting}
                className={btnStyles.Right}
                type="submit"
                color="green"
                size="large"
              >
                {t('submit', { ns: 'buttons' }).toUpperCase()}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}

export default ChangeEmail
