import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import useFirestoreDoc from '../../../hooks/useFirestoreDoc'
import { listenToOrder, getOrderFromFirestore } from '../../../store/actions/orderActions'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../../config/firebase'
import { Message, Button } from 'semantic-ui-react'
import { setPageTitle } from '../../../store/actions/navigationActions'
import Spinner from '../../UI/Spinner/Spinner'
import { asyncActionError, asyncActionStart, asyncActionFinish } from '../../../store/actions'
import OrderDetails from './OrderDetails'
import OrderConfirmation from './OrderConfirmation'
import moment from 'moment'
import { getUserProfileFromFirestore } from '../../../store/actions/userActions'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const OrderView = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [stock, setStock] = useState([])
  const { t } = useTranslation(['orders', 'common'])

  const { order } = useSelector((state) => state.order)
  const { error, loading } = useSelector((state) => state.async)
  const [success, setSuccess] = useState(null)
  const [orderConfirmationSection, setOrderConfirmationSection] = useState('general')

  useEffect(() => {
    dispatch(setPageTitle(t('order_details', { ns: 'orders' })))
  }, [dispatch])

  useFirestoreDoc({
    query: () => getOrderFromFirestore(props.match.params.id),
    data: (order) => dispatch(listenToOrder(order)),
    deps: [dispatch],
  })

  useEffect(() => {
    const getUserStock = async () => {
      let locationId = order && order.deliveryLocation ? order.deliveryLocation.locationId : null
      const response = await httpsCallable(
        cloudFunctions,
        'getUserStock'
      )({
        locationId,
      })

      setStock(response.data)
    }

    getUserStock()
  }, [order])

  if (!props.match.params.id) return <Redirect to="/orders" />
  if (loading || !order) return <Spinner content={t('please_wait', { ns: 'common' })} />

  async function updateOrderStatusHandler(status) {
    try {
      dispatch(asyncActionStart())
      await httpsCallable(cloudFunctions, 'updateOrderStatus')({ orderId: props.match.params.id, status })
    } catch (error) {
      dispatch(asyncActionError(error, t('status_could_not_be_changed', { ns: 'orders' }), 'OrderView::updateOrderStatus: '))
    }
    dispatch(asyncActionFinish())
  }

  async function updateOrderItems(updatedItems) {
    try {
      dispatch(asyncActionStart())
      await httpsCallable(
        cloudFunctions,
        'updateOrderItems'
      )({ orderId: props.match.params.id, items: updatedItems.items })
    } catch (error) {
      dispatch(asyncActionError(error, t('items_could_not_be_changed', { ns: 'orders' }), 'OrderView::updateOrderItems: '))
    }
    dispatch(asyncActionFinish())
  }

  async function onResendOrderEmailClickHandler() {
    try {
      dispatch(asyncActionStart())
      const userData = await getUserProfileFromFirestore().get()
      const request = {
        emailTo: order.distributorEmail,
        recipient: order.distributorName,
        order: {
          createdAt: moment(order.createdAt.toDate()).format('DD.MM.YYYY'),
          deliveryDate: order.deliveryDate ? moment(order.deliveryDate.toDate()).format('DD.MM.YYYY') : '',
          items: order.items,
          orderTotal: order.orderTotal,
          distributor: {
            name: order.distributorName,
            email: order.distributorEmail,
          },
          clientData: userData.data(),
        },
      }
      //Send order email
      await httpsCallable(cloudFunctions, 'resendOrderEmail')({ request })
      await httpsCallable(cloudFunctions, 'updateOrderStatus')({ orderId: order.id, status: 'sent' })
      setSuccess(t('email_sent_to_the_distributor', { ns: 'orders' }))
      dispatch(asyncActionFinish())
    } catch (error) {
      await httpsCallable(cloudFunctions, 'updateOrderStatus')({ orderId: order.id, status: 'error' })
      dispatch(asyncActionError(error, t('email_could_not_be_sent', { ns: 'orders' }), 'OrderView::resendOrderEmail: '))
    }

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  async function updateStockItems(updatedItems) {
    const locationId = order.deliveryLocation.locationId
    const initialValues = {
      items: stock.map((item) => ({
        id: item.id,
        name: item.name,
        itemNumber: item.itemNumber ? item.itemNumber : '',
        amount: item.amount,
        isArchived: item.isArchived ? item.isArchived : false,
        previousTotalAmount: item.amount,
      })),
    }
    const values = {
      items: initialValues.items.map((item) => {
        const foundItem = updatedItems.items.find((updatedItem) => updatedItem.itemId === item.id)
        return foundItem ? { ...item, amount: foundItem.amount } : { ...item }
      }),
    }

    await httpsCallable(
      cloudFunctions,
      'updateStockItems'
    )({
      values,
      initialValues,
      locationId,
    })
  }

  return (
    <>
      {error && <Message warning content={error} />}
      {success && <Message positive content={success} />}
      {orderConfirmationSection === 'general' && (
        <OrderDetails
          order={order}
          onChangeStatusClick={updateOrderStatusHandler}
          onResendEmailClick={onResendOrderEmailClickHandler}
          changeConfirmationSection={(section) => setOrderConfirmationSection(section)}
        />
      )}
      {orderConfirmationSection === 'confirmation' && (
        <OrderConfirmation
          order={order}
          onChangeStatusClick={updateOrderStatusHandler}
          changeConfirmationSection={(section) => setOrderConfirmationSection(section)}
          updateStockItems={updateStockItems}
          updateOrderItems={updateOrderItems}
        />
      )}
      {(order.status === 'processing' || order.status === 'delivered') && (
        <div className="flex center-content gap-16">
          <Button content={t('to_all_orders', { ns: 'orders' })} color="green" onClick={() => history.push('/orders')} />
        </div>
      )}
    </>
  )
}

export default OrderView
