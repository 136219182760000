import React, { useState, useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CustomDropdown from '../UI/Form/CustomDropdown'
import { Grid, Icon } from 'semantic-ui-react'

const StockWithDropdown = ({
  distributors,
  fieldArrayHelper,
  fieldValueSetter,
  isLoadingDistributors,
  index,
  updateProducts,
  values,
  editStockFlow = false,
}) => {
  const [distributor, setDistributor] = useState(null)
  const [isDistributorSelected, setIsDistributorSelected] = useState(false)
  const [productOptions, setProductOptions] = useState([])
  const [distributorChanged, setDistributorChanged] = useState(false)
  const [isLoadingProducts, setIsLoadingProducts] = useState(false)
  const [disableDropdown, setDisableDropdown] = useState(false)
  const { t } = useTranslation(['stock'])
  const history = useHistory()

  useEffect(() => {
    setProductOptions(updateProducts(distributor, setIsLoadingProducts, setDisableDropdown))
  }, [distributor, updateProducts])

  useEffect(() => {
    setIsDistributorSelected(!!values[`${index}`].distributor)
  }, [index, values])

  useEffect(() => {
    if (distributorChanged) {
      values[index].product = ''
      values[index].id_product_onboarding = ''
      values[index].typed_product = ''
      values[index].generic_name = ''
      setDistributorChanged(false)
      setIsLoadingProducts(true)
      setDisableDropdown(true)
    }
  }, [distributorChanged])

  const getPlaceholder = () => {
    let item = values.onboarding ? values.onboarding[index] : {}
    item = Object.keys(item).length === 0 && values[index] ? values[index] : {}
    if (!item.id_product_onboarding && (item.generic_name || item.typed_product)) {
      if (item.typed_product)
        return item.typed_product.split(' - Item Number')[0]
      else
        return item.generic_name.split(' - Item Number')[0]
    } else {
      return t('product')
    }
  }

  const getDistributorPlaceholder = () => {
    let item = values.onboarding ? values.onboarding[index] : {}
    item = Object.keys(item).length === 0 && values[index] ? values[index] : {}
    return item.distributor ? item.distributor : t('distributor')
  }

  return (
    <Grid.Row className="custom-row">
      <div className='distributor-row'>
        <CustomDropdown
          name={`onboarding.${index}.id_distributor_onboarding`}
          placeholder={getDistributorPlaceholder}
          options={distributors}
          fieldValueSetter={fieldValueSetter}
          fieldValueToSet={`onboarding.${index}.distributor`}
          setDistributor={setDistributor}
          compact
          loading={isLoadingDistributors}
          disabled={isLoadingDistributors}
          customDistributor={`onboarding.${index}.customDistributor`}
          firstStockPage={true}
          setDistributorChanged={setDistributorChanged}
        />
        <Icon
          className="pointer"
          name="trash"
          color="grey"
          alt="Remove"
          title="Remove"
          disabled={values.length === 1}
          onClick={() => {
            if (values.length > 1) fieldArrayHelper.remove(index)
          }}
        />
      </div>
      <CustomDropdown
        name={`onboarding.${index}.id_product_onboarding`}
        placeholder={getPlaceholder}
        disabled={disableDropdown}
        options={productOptions}
        fieldValueSetter={fieldValueSetter}
        fieldValueToSet={`onboarding.${index}.product`}
        shouldBeChecked={`onboarding.${index}.shouldBeChecked`}
        compact
        loading={isLoadingProducts}
        stockPage={true}
        alterText={true}
        showItemNumber={true}
        fieldItemNumberToSet={`onboarding.${index}.itemNumber`}
        distributorSelected={!!values[`${index}`].id_distributor_onboarding || !!values[`${index}`].distributor}
        currentStep={history.location?.pathname}
        editStockFlow={editStockFlow}
      />
    </Grid.Row>
  )
}

export default StockWithDropdown
