const ONBOARDING_INGREDIENT = 'onboarding'

/** returns true if locations are valid or user doesn't have locations */
const validateLocations = (hasLocations, locations) => (hasLocations && locations.length) || !hasLocations

/** returns true if ingredints array is greater than 0 and first ingredient added has a name */
const validateIngredients = (ingredients) => {
  if (ingredients.length) {
    let validated = true
    for (let i = 0; i < ingredients.length; i++) {
      validated = validated && (ingredients[i].name || ingredients[i].generic_name)
    }
    return validated
  } else {
    return false
  }
}

export const shouldDisableNextStep = (current_step, values, user) => {
  switch (current_step) {
    case 'second-step':
      return Object.values(values.ingredients).some(
        (ingredient) => !ingredient.fromStock && !ingredient.product && !ingredient.id_product_onboarding
      )
    default:
      /** add this validation when we get locations working local validateLocations(user.hasLocations, values.locations) && */
      return !(values.name && validateIngredients(Object.values(values.ingredients)))
  }
}

const getIdDistributorOnboarding = (distributor) => {
  const { id_distributor_onboarding, id } = distributor
  if (id_distributor_onboarding) {
    return id_distributor_onboarding
  } else {
    if (id) return id
  }
  return distributor
}

const getIngredients = (ingredients) => {
  let payloadIngredients = {}
  Object.keys(ingredients).forEach((ingredient) => {
    if (ingredient === ONBOARDING_INGREDIENT) {
      payloadIngredients[ingredient] = ingredients[ingredient].map(
        ({ amount, generic_name, id_distributor_onboarding, id_product_onboarding, shouldBeChecked, unit, itemNumber, customDistributor }) => {
          let typedProduct = !id_product_onboarding.id ? id_product_onboarding : null
          typedProduct = ((itemNumber !== undefined) && (itemNumber !== '')) ? `${typedProduct} - Item Number: ${itemNumber}` : typedProduct
          return {
            amount,
            generic_name,
            id_distributor_onboarding: getIdDistributorOnboarding(id_distributor_onboarding),
            id_product_onboarding: id_product_onboarding.name ? id_product_onboarding.name : id_product_onboarding.id,
            typed_product: typedProduct,
            shouldBeChecked,
            unit: unit.value,
            customDistributor: id_distributor_onboarding.customDistributor ? id_distributor_onboarding.customDistributor : customDistributor,
            id_product: id_product_onboarding.name ? id_product_onboarding.id : null,
          }
        }
      )
    } else {
      payloadIngredients[ingredient] = {
        ...ingredients[ingredient],
        name: ingredients[ingredient].name.text ? ingredients[ingredient].name.text : ingredients[ingredient].name,
        unit: ingredients[ingredient].unit.text,
      }
    }
  })

  return payloadIngredients
}

export const getPayload = (values) => {
  let ingredients = getIngredients(values.ingredients)
  return {
    ...values,
    ingredients,
  }
}

export const INITIAL_VALUES = {
  type: 'single',
  name: '',
  ingredients: [
    {
      id: '',
      generic_name: '',
      amount: '',
      unit: '',
    },
  ],
  subrecipes: [],
  notes: '',
}
