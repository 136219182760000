export const TYPE_YOUR_OWN_INGREDIENT = (t) => ({
  id: 'custom_ingredient',
  value: '',
  text: t('type_your_own_ingredient', { ns: 'menu' })
})
export const TYPE_YOUR_OWN_PRODUCT = (t) => ({
  value: '',
  text: t('type_your_own_product', { ns: 'menu' })
})
export const TYPE_YOUR_OWN_DISTRIBUTOR = (t) => ({
  value: '',
  text: t('type_your_own_distributor', { ns: 'menu' })
})


