import React from 'react'
import { useDispatch } from 'react-redux'
import ModalWrapper from '../UI/Modal/ModalWrapper'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Button, Label } from 'semantic-ui-react'
import { asyncActionFinish, closeModal } from '../../store/actions'
import { sendForgetPasswordEmail } from '../../store/actions/authActions'
import buttonStyles from '../../assets/styles/modules/UI/Button.module.scss'
import TextInput from '../UI/Form/TextInput'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['validationMessages'])

  return (
    <ModalWrapper size="mini" header="Reset Your Password">
      <Formik
        initialValues={{ email: '' }}
        validationSchema={Yup.object({
          email: Yup.string().email(t('valid_email', { ns: 'validationMessages' })).required(t('email', { ns: 'validationMessages' })),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await sendForgetPasswordEmail(values.email)
            dispatch(asyncActionFinish('An email to reset your password has been sent.'))
            dispatch(closeModal())
          } catch (error) {
            setErrors({ auth: error.message })
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form autocomplete="off" className="ui form">
            {errors.auth && <Label basic color="red" content={errors.auth} style={{ marginBottom: 10 }} />}
            <TextInput name="email" placeholder="Your Email" type="email" />
            <p>Enter your email address and we'll send you an email with instructions to reset your password.</p>
            <div className="btn-container bottom fixed flex space-between full-width">
              <Button
                type="button"
                basic
                color="red"
                size="large"
                onClick={() => dispatch(closeModal())}
                className={buttonStyles.BackButton}
              >
                Back
              </Button>
              <Button
                type="submit"
                loading={isSubmitting}
                color="green"
                size="large"
                className={buttonStyles.ConfirmButton}
              >
                Send email
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}

export default ForgotPassword
