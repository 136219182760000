import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Message } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import Location from '../../components/Locations/Location/Location'
import { setPageTitle } from '../../store/actions'
import { useMultipleLocations } from '../../hooks/useMultipleLocations'
import useFetchLocation from '../../hooks/useFetchLocation'
import { useUserFlags } from '../../hooks/useUserFlags'
import CustomMessage from '../UI/Message/CustomMessage'
import { getLocationsMessage } from '../../shared/utility'
import cx from 'classnames'

import styles from '../../assets/styles/modules/locations/Location.module.scss'

const Locations = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { locations } = useSelector((state) => state.location)
  const { user } = useSelector((state) => state.user)
  const { error } = useSelector((state) => state.async)
  const [noContentMessage, setNoContentMessage] = useState(false)
  const { fetchLocations, loading } = useFetchLocation()
  const target = history.location?.state?.target || localStorage.getItem('target')
  const { t } = useTranslation(['location', 'common']);

  useEffect(() => {
    if (!locations.length && !loading) setNoContentMessage(true)
    else setNoContentMessage(false)
  }, [loading, locations])

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(target)
      if (!element) { return }
      element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      history.replace({state: {}})
      localStorage.removeItem('target')
    }, 1000);

    return () => clearTimeout(timer)
  }, [history, target])

  useEffect(() => {
    dispatch(setPageTitle(t('locations', { ns: 'location' })))
    fetchLocations(dispatch)
  }, [dispatch])

  // Update user flags is needed
  useUserFlags()

  // Update user.hasLocations if needed
  useMultipleLocations()

  if (loading) return <Spinner content={t('please_wait', { ns: 'common' })} />

  return (
    <>
      {user?.hasPOS && <CustomMessage message={getLocationsMessage(user, t)} />}
      <div className={styles.Locations}>
        {error && <Message>{t('something_wrong', { ns: 'common' , error })}</Message>}
        {noContentMessage ? 
          <p className="align-center">
            {t('nothing_here', { ns: 'common' })}
            <br />
            {t('add_location_now', { ns: 'location' })}
          </p>
          :
          <>
            <h2 className={styles.SelectLocationTitle}>{t('select_a_location', { ns: 'location' })}</h2>
            {locations.map((location) => (
              <Location key={location.id} location={location} />
            ))}
          </>
        }
      </div>
      <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
        <span className={styles.ButtonWrapperCentered}>
          <Button
            className={cx(styles.Button, styles.Green)}
            color="green"
            onClick={() => history.push(props.match.path + '/add')
          }>
            {t('add_location', { ns: 'location' })}
          </Button>
        </span>
      </div>
    </>
  )
}

export default Locations
