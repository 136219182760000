import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Grid, Label } from 'semantic-ui-react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import TextInput from '../../UI/Form/TextInput'
import * as Yup from 'yup'
import { updateUserProfile } from '../../../store/actions/userActions'
import btnStyles from '../../../assets/styles/modules/UI/Button.module.scss'
import { setPageTitle } from '../../../store/actions'
import { cloudFunctions } from '../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import styles from '../../../assets/styles/modules/settings/CompanyDetails.module.scss'

const CompanyDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['validationMessages'])

  const { locations } = useSelector((state) => state.location)
  const { user } = useSelector((state) => state.user)

  useEffect(() => {
    dispatch(setPageTitle('Company Details'))
  }, [dispatch])

  const createDefaultLocation = async (companyDetails) => {
    const values = {
      name: user.companyName,
      street: companyDetails.street,
      postcode: companyDetails.postalCode,
      place: companyDetails.place,
      email: user.email,
      isActive: false,
      copyData: 1,
      kennitala: companyDetails.kennitala,
      channels: [],
    }
    await httpsCallable(cloudFunctions, 'addLocation')({ values })
  }

  const formFields = {
    companyName: user?.companyName,
    name: user?.name ? user.name : '',
    street: user?.street ? user.street : '',
    postalCode: user?.postalCode ? user.postalCode : '',
    place: user?.place ? user.place : '',
    phone: user?.phone ? user.phone : '',
    kennitala: user?.kennitala ? user.kennitala : '',
  }
  const validationSchema = Yup.object({
    companyName: Yup.string().required(t('company_name', { ns: 'validationMessages' })),
    street: Yup.string().required(t('street', { ns: 'validationMessages' })),
    postalCode: Yup.string().required(t('postal_code', { ns: 'validationMessages' })),
    place: Yup.string().required(t('place', { ns: 'validationMessages' })),
    kennitala: Yup.string().required(t('kennitala', { ns: 'validationMessages' })),
  })

  return (
    <div>
      <Formik
        initialValues={formFields}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          let hasError = true
          try {
            await updateUserProfile(values)
            if (locations.length === 0) {
              await createDefaultLocation(values)
            }
            setSubmitting(false)
            hasError = false
          } catch (error) {
            setErrors({ profile: error.message })
            setSubmitting(false)
          }finally{
            if(!hasError) history.push('/settings')
          }
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form autocomplete="off" className="ui form">
            {errors.profile && <Label basic color="red" content={errors.profile} style={{ marginBottom: 10 }} />}
            <Grid className={styles['Company-Details-Grid']}>
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextInput name="companyName" textLabel="Company Name" type="text" disabled={isSubmitting} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextInput
                    name="kennitala"
                    placeholder="Kennitala"
                    type="kennitala"
                    disabled={isSubmitting}
                    textLabel="Kennitala"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextInput name="name" textLabel="Owner/Manager" type="text" disabled={isSubmitting} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextInput
                    name="street"
                    textLabel="Contact Details"
                    type="text"
                    placeholder="Street Address"
                    disabled={isSubmitting}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <TextInput name="postalCode" type="text" placeholder="Postal Code" disabled={isSubmitting} />
                </Grid.Column>
                <Grid.Column>
                  <TextInput name="place" type="text" placeholder="Place" disabled={isSubmitting} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <TextInput
                    className='left-icon-input'
                    name="phone"
                    icon="phone"
                    iconPosition="left"
                    placeholder="Phone"
                    type="text"
                    disabled={isSubmitting}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <div className="flex center-content gap-16">
              <Button
                loading={isSubmitting}
                className={btnStyles.Right}
                type="submit"
                color="green"
                size="big"
              >
                SUBMIT
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default CompanyDetails
