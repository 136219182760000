import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Modal } from 'semantic-ui-react'
import { closeModal } from '../../../store/actions'

const ModalWrapper = ({ children, size, header, scrollable = true }) => {
  const dispatch = useDispatch()

  // Added callback to get rid of state update on unmounted cmp error
  const closeCallback = useCallback(() => {
    setTimeout(function () {
      dispatch(closeModal())
    }, 0)
  }, [dispatch])

  return (
    <Modal open={true} onClose={closeCallback} size={size}>
      {header && <Modal.Header>{header}</Modal.Header>}
      {scrollable ? <Modal.Content scrolling>{children}</Modal.Content> : <Modal.Content>{children}</Modal.Content>}
    </Modal>
  )
}

export default ModalWrapper
