import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { setPageTitle, setPageSubtitle } from '../../../store/actions'
import useFirestoreDoc from '../../../hooks/useFirestoreDoc'
import { asyncActionError, asyncActionFinish } from '../../../store/actions'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../../store/actions/userActions'
import { Formik, Form } from 'formik'
import { Grid, Button, Message, Confirm, Modal } from 'semantic-ui-react'
import SelectInput from '../../UI/Form/SelectInput'
import buttonStyles from '../../../assets/styles/modules/UI/Button.module.scss'
import styles from '../../../assets/styles/modules/settings/ProviderInformation.module.scss'
import cx from 'classnames'
import MarkdownToHtml from '../../UI/Markdown/MarkdownToHtml'
import ProviderRequiredInputs from './ProviderRequiredInputs'
import { getAvailableProviders } from '../../../shared/utility'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../../config/firebase'
import { getUserIdToken } from '../../../store/actions/authActions'
import * as Yup from 'yup'

const ProviderInformation = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user)
  const { message, error } = useSelector((state) => state.async)
  const [providerOptions, setProviderOptions] = useState([])
  const [posInstructions, setPosInstructions] = useState({})
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  const [openActivateModal, setOpenActivateModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [userPOS, setUserPOS] = useState(null)
  const history = useHistory()
  const { t } = useTranslation(['validationMessages', 'pos', 'buttons'])

  useEffect(() => {
    dispatch(setPageTitle(t('pos', { ns: 'pos' }).toUpperCase()))
    dispatch(setPageSubtitle(t('connect_point_of_sales', { ns: 'pos' })))
  }, [dispatch])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  useEffect(() => {
    const setup = async () => {
      const token = await getUserIdToken()
      const { data } = await httpsCallable(cloudFunctions, 'getAvailableProviders')({ user_token: token })
      const options = getAvailableProviders(data)
      setProviderOptions(options)
      setIsLoadingOptions(options.length > 0)
      setPosInstructions(data.available_pos)
    }

    const getUserPOS = async () => {
      try {
        let token = await getUserIdToken()
        const result = await httpsCallable(cloudFunctions, 'getConfigurationData')({ user_token: token })
        return result.data.pos
      } catch (error) {
        return null
      }
    }

    setup()
    getUserPOS().then(pos => setUserPOS(pos))
  }, [])

  const initialValues = {
    POS: user?.POS || '',
    UUID: '',
    username: '',
    password: '',
    url: '',
    apiKey: '',
    companyName: user?.companyName || '',
  }

  const validationSchema = Yup.object({
    UUID: Yup.string().when(['POS'], {
      is: (POS) => POS === 'SALESCLOUD',
      then: Yup.string().required(t('uuid', { ns: 'validationMessages' })),
      otherwise: Yup.string().notRequired(),
    }),
    username: Yup.string().when(['POS'], {
      is: (POS) => POS === 'REGLA',
      then: Yup.string().required(t('username', { ns: 'validationMessages' }) ),
      otherwise: Yup.string().notRequired(),
    }),
    password: Yup.string().when(['POS'], {
      is: (POS) => POS === 'REGLA',
      then: Yup.string().required(t('password', { ns: 'validationMessages' })),
      otherwise: Yup.string().notRequired(),
    }),
    url: Yup.string().when(['POS'], {
      is: (POS) => POS === 'DK',
      then: Yup.string().required(t('url', { ns: 'validationMessages' })),
      otherwise: Yup.string().notRequired(),
    }),
    apiKey: Yup.string().when(['POS'], {
      is: (POS) => POS === 'DK',
      then: Yup.string().required(t('api_key', { ns: 'validationMessages' })),
      otherwise: Yup.string().notRequired(),
    }),
  })

  const handleOnSubmit = async (values, { setSubmitting }) => {
    const token = await getUserIdToken()
    const { data } = await httpsCallable(
      cloudFunctions,
      'setUserConfiguration'
    )({
      ...values,
      user_token: token,
      hasPOS: user.hasPOS,
    })

    if (data.error) {
      dispatch(asyncActionFinish(''))
      dispatch(asyncActionError({ message: data.error }))
      setSubmitting(false)
      return
    }

    dispatch(asyncActionError({ message: '' }))
    await httpsCallable(
      cloudFunctions,
      'updateUserProviderInformation'
    )({
      hasPOS: true,
      POS: values.POS,
    })
    setSubmitting(false)
    setOpenSuccessModal(true)
    setTimeout(() => {
      setOpenSuccessModal(false)
      history.push('/settings')
    }, 5000)
  }

  return (
    <div className={styles['Provider-Information']}>
      {error && <Message error content={error} />}
      {message && <Message success content={message} />}
      {userPOS && (
        <div className="current-provider">
          <Grid className={styles['Provider-Grid']}>
            <Grid.Row centered>
              <Grid.Column textAlign="center">
                {t('pos_saved', { ns: 'pos' })}
                <br />
                { userPOS && <span>{t('currently_connected_to', { ns: 'pos' })} <strong>{userPOS}</strong></span> }
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column textAlign="center">
                <Button
                  type="button"
                  basic
                  color="green"
                  content={t('change_pos', { ns: 'pos' })}
                  onClick={() => setOpenActivateModal(true)}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      )}
      {!userPOS && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values, isValid }) => (
            <Form autocomplete="off" className="ui form">
              <Grid className={styles['Provider-Grid']}>
                <Grid.Row centered>
                  <Grid.Column>
                    <SelectInput
                      name="POS"
                      placeholder={t('choose_the_system', { ns: 'pos' })}
                      options={providerOptions}
                      loading={!providerOptions.length}
                      disabled={isSubmitting}
                      compact
                      clearable
                    />
                  </Grid.Column>
                </Grid.Row>

                {values.POS && (
                  <Grid.Row centered>
                    <Grid.Column>
                      <MarkdownToHtml markdown={posInstructions[values.POS]} />
                    </Grid.Column>
                  </Grid.Row>
                )}

                {isLoadingOptions && <ProviderRequiredInputs POS={values.POS} isSubmitting={isSubmitting} />}

                {providerOptions.length > 0 && (
                  <Grid.Row centered style={{ marginTop: values.POS ? '36px' : '0px' }}>
                    {values.POS ? (
                      <Grid.Column width={6} textAlign="center">
                        <Button
                          disabled={!isValid || isSubmitting}
                          loading={isSubmitting}
                          type="submit"
                          color="green"
                          content={t('save', { ns: 'buttons' })}
                        />
                      </Grid.Column>
                    ) : (
                      <Grid.Column width={14} textAlign="center">
                        <Trans
                            t={t}
                            ns={'pos'}
                            i18nKey="dont_see_pos_system"
                        >
                          <p>
                            Don't see the POS system you use?
                            <br />
                            <a href="mailto:greenbytes@greenbytes.is" style={{ textDecoration: 'underline' }}>
                              Let us know
                            </a>{' '}
                            and we'll try to get it set it up!
                          </p>
                        </Trans>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      )}
      <Confirm
        header="GreenBytes Premium"
        content={
          <Grid>
            <Grid.Row centered>
              <Grid.Column width={16} textAlign="center">
                <p style={{ margin: '24px' }}>
                  {t('about_to_disconnect', { ns: 'pos' })}
                  <br />
                  <br />
                  <strong>{t('complete_action', { ns: 'pos' })}</strong>
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
        confirmButton={t('continue', { ns: 'buttons' })}
        cancelButton={t('back', { ns: 'buttons' })}
        open={openActivateModal}
        onConfirm={async () => {
          setOpenActivateModal(false)
        }}
        onCancel={() => setOpenActivateModal(false)}
        closeOnDimmerClick={false}
      />
      <Modal
        open={openSuccessModal}
        closeOnDimmerClick={false}
      >
        <Modal.Header className={styles['Modal-Header']}>
          {t('pos_connected', { ns: 'pos' })}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p className={styles['Modal-Description']}>
              {t('pos_successfully_connected', { ns: 'pos' })}
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button 
            onClick={() => {
              setOpenSuccessModal(false)
              history.push('/settings')
            }}
            className={styles['Confirm-Button']}
            positive
          >
            {t('go_to_settings', { ns: 'pos' })}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  )
}

export default ProviderInformation
