import * as actionTypes from './actionTypes'

export function openModal(payload) {
  return {
    type: actionTypes.OPEN_MODAL,
    payload,
  }
}

export function closeModal() {
  return {
    type: actionTypes.CLOSE_MODAL,
  }
}
