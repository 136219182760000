import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  subscriptions: [],
  plans: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUBSCRIPTIONS_SUCCESS:
      return updateObject(state, { subscriptions: action.subscriptions })
    case actionTypes.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return updateObject(state, { plans: action.plans })
    default:
      return state
  }
}

export default reducer
