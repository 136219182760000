import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setPageTitle } from '../store/actions'
import { writeLog } from '../shared/firebaseLogger'

import { Message } from 'semantic-ui-react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.path && this.props.path && prevProps.path !== this.props.path) {
      this.setState({ hasError: false })
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    //console.error(error);
    this.props.resetPageTitle()
    writeLog('ERROR', error.message)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Message warning>Oops, something went wrong.</Message>
    }
    return this.props.children
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPageTitle: () => dispatch(setPageTitle(null)),
  }
}

export default connect(null, mapDispatchToProps)(ErrorBoundary)
