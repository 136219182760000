import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  distributors: [],
  distributor: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DISTRIBUTORS_SUCCESS:
      return updateObject(state, { distributors: action.distributors })
    case actionTypes.GET_DISTRIBUTOR_SUCCESS:
      return updateObject(state, { distributor: action.distributor })
    default:
      return state
  }
}

export default reducer
