import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import NavigationItems from './NavigationItems/NavigationItems'
import Backdrop from '../UI/Backdrop/Backdrop'
import logoSrc from '../../assets/images/gb-logo-rect-green.svg'
import gbLogoSrc from '../../assets/images/gb-logo-linear-green.svg'
import settingsIconSrc from '../../assets/images/navigation/settings.svg'
import { signOutFromFirebase } from '../../store/actions/authActions'
import { setPageTitle } from '../../store/actions/navigationActions'
import { setSelectedLocation } from '../../store/actions/locationActions'

const Sidebar = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation(['sidebar']);

  const { currentUser } = useSelector((state) => state.auth)

  async function handleSignOut() {
    try {
      dispatch(setPageTitle(''))
      dispatch(setSelectedLocation(null))
      await signOutFromFirebase()
      history.replace('/login')
    } catch (error) {
      //@todo error handling
    }
  }

  let classes = ['sidebar', 'closed']
  if (props.open) {
    classes = ['sidebar', 'open']
  }
  return (
    <>
      <Backdrop show={props.open} clicked={props.closed} />
      <div className={classes.join(' ')} onClick={props.closed}>
        <Link to="/home" style={{ color: '#000000' }}>
          <div className="client-details">
            <div className="logo-container">
              <div className="logo client">
                <img src={currentUser.photoURL ? currentUser.photoURL : logoSrc} alt="client logo" />
              </div>
            </div>
            <div className="name">{currentUser.displayName ? currentUser.displayName : ''}</div>
          </div>
        </Link>
        <nav>
          <NavigationItems />
        </nav>
        <div className="btm-container">
          <div className="branding">
            <span>{t('powered_by', { ns: 'sidebar' })}</span>
            <div className="logo">
              <img src={gbLogoSrc} alt="greenbytes" />
            </div>
          </div>
          <div className="links">
            <div className="nav-item settings">
              <Link className="flex center-items" to="/settings">
                <img src={settingsIconSrc} alt="Settings" />
                <span>{t('settings', { ns: 'sidebar' })}</span>
              </Link>
            </div>
            <div className="nav-item logout pointer flex center-items">
              <span onClick={handleSignOut}>{t('log_out', { ns: 'sidebar' })}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
