import * as actionTypes from './actionTypes'

export const setSearchData = (searchData) => {
  return {
    type: actionTypes.SET_SEARCH_DATA,
    searchData,
  }
}

export const clearSearch = () => {
  return {
    type: actionTypes.CLEAR_SEARCH,
  }
}

export const startSearch = (query) => {
  return {
    type: actionTypes.START_SEARCH,
    query,
  }
}

export const finishSearch = (results) => {
  return {
    type: actionTypes.FINISH_SEARCH,
    results,
  }
}

export const updateSelection = (selection) => {
  return {
    type: actionTypes.START_SEARCH,
    selection,
  }
}
