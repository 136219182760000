import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styles from '../../assets/styles/modules/Home.module.scss'
import { setPageTitle } from '../../store/actions'
import useFetchLocation from '../../hooks/useFetchLocation'
import Distributors from './Task/Distributors'
import StockItems from './Task/StockItems'
import Menu from './Task/Menu'
import Orders from './Task/Orders'
import { Grid } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import OpenOrders from './Notifications/OpenOrders'
import { configurePushNotifications } from '../../config/firebase'

const Home = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.auth)
  const { loading } = useSelector((state) => state.async)
  const { fetchLocations } = useFetchLocation()
  const { t } = useTranslation(['home'])

  useEffect(() => {
    const unsubscribe = () => dispatch(setPageTitle(''))
    return () => {
      unsubscribe()
    }
  })

  useEffect(() => {
    fetchLocations(dispatch)
  }, [dispatch])

  useEffect(() => {
    configurePushNotifications()
  }, [])

  return (
    <div className={styles.Home}>
      <h2 className={styles.Greeting}>{t('title', { name: currentUser.displayName, ns: 'home' })}</h2>
      {loading && <Spinner content={t('loading', { ns: 'home' })} />}

      <OpenOrders />

      <Grid className={styles.TaskList} stackable={true}>
        <Grid.Row>
          <Grid.Column width={3} className={styles.TaskContainer}>
            <Distributors />
          </Grid.Column>
          <Grid.Column width={1} className={styles.VerticalMobileLine}>
            <hr />
            <div className={styles.VL} />
          </Grid.Column>
          <Grid.Column width={3} className={styles.TaskContainer}>
            <StockItems />
          </Grid.Column>
          <Grid.Column width={1} className={styles.VerticalMobileLine}>
            <hr />
            <div className={styles.VL} />
          </Grid.Column>
          <Grid.Column width={3} className={styles.TaskContainer}>
            <Menu />
          </Grid.Column>
          <Grid.Column width={1} className={styles.VerticalMobileLine}>
            <hr />
            <div className={styles.VL} />
          </Grid.Column>
          <Grid.Column width={3} className={styles.TaskContainer}>
            <Orders />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default Home
