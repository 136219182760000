import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Label, Segment, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getOrderStatus, getStatusIcon } from '../../../shared/utility'
import styles from '../../../assets/styles/modules/orders/Order.module.scss'
import placeholderSrc from '../../../assets/images/distributors/placeholder.svg'

const Order = ({ order, cursor = 0 }) => {
  const history = useHistory()
  const { t } = useTranslation(['orders'])

  return (
    <Segment
      raised
      id={order.id}
      className={styles.Order}
      onClick={() => {
        localStorage.setItem('cursor', cursor)
        localStorage.setItem('target', order.id)
        history.push('/orders/view/' + order.id, { cursor, target: order.id })
      }}>
      <Grid>
        <Grid.Row className={styles.CustomDesktopRow} style={{ padding: '0 10px 0 0' }}>
          <Grid.Column stretched width={4} className={styles.Logo} verticalAlign="middle">
            <img src={order.distrPhotoURL ? order.distrPhotoURL : placeholderSrc} alt={order.distributorName} />
          </Grid.Column>
          <Grid.Column width={6} verticalAlign="middle" className={styles.OrderDetails}>
            <p className="large">{order.distributorName}</p>
            <p className="small hint">{t('order_number', { number: order.orderId, ns: 'orders' })}</p>
            {order.deliveryDate ? (
              <p className="small">
                <span>{t('requested_delivery', { ns: 'orders' })}: </span>
                <span className="green-text">{moment(order.deliveryDate).format('DD MMM YYYY')}</span>
              </p>
            ) : (
              <p className="small">
                <span>{t('estimated_delivery', { ns: 'orders' })} </span>
                <span className="green-text">{moment(order.createdAt).add(1, 'days').format('DD MMM YYYY')}</span>
              </p>
            )}
          </Grid.Column>
          <Grid.Column width={4} style={{ padding: '0px' }} verticalAlign="middle" textAlign="right">
            {order.deliveryLocation && <Label style={{ marginBottom: '10px' }}>{order.deliveryLocation.name}</Label>}
            <p className="orange-text">{moment(order.createdAt).format('DD MMM')}</p>
            <p className="green-text bold">{t(`order_${order.status}`, { ns: 'orders' })}</p>
          </Grid.Column>
          <Grid.Column width={2} verticalAlign="middle" textAlign="right" style={{ padding: '0px 10px 0px 0px' }}>
            <div className={styles.StatusIcon}>{getStatusIcon(order.status)}</div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className={styles.CustomMobileRow}>
          <Grid.Column>
            <p className={styles.DistributorName}>{order.distributorName}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.CustomMobileRow}>
          <Grid.Column className={styles.Logo}>
            <img src={order.distrPhotoURL ? order.distrPhotoURL : placeholderSrc} alt={order.distributorName} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.CustomMobileRow}>
          <Grid.Column className="order-information">
            <div className='order-detail'>
              <Icon color="grey" name="box" size='large' />
              <p>{t('order_number', { number: order.orderId, ns: 'orders' })}</p>
            </div>
            <div className='order-detail'>
              <Icon color="grey" name="calendar" size='large' />
              {order.deliveryDate ? (
                <div>
                  <p>{t('requested_delivery', { ns: 'orders' })}:</p>
                  <p>{moment(order.deliveryDate).format('DD MMM YYYY')}</p>
                </div>
              ) : (
                <div>
                  <p>{t('estimated_delivery', { ns: 'orders' })} </p>
                  <p className="green-text">{moment(order.createdAt).add(1, 'days').format('DD MMM YYYY')}</p>
                </div>
              )}
            </div>
            { order.deliveryLocation && 
              <div className='order-detail'>
                <Icon color="grey" name="map marker alternate" size='large' />
                <p>{`${order.deliveryLocation.name}: ${order.deliveryLocation.place}, ${order.deliveryLocation.street}, ${order.deliveryLocation.postcode}`}</p>
              </div>
            }
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.CustomMobileRow}>
          <Grid.Column className="order-status">
            <p className="green-text bold">{t(`order_${order.status}`)}</p>
            <p className="hint">{moment(order.createdAt).format('DD MMM')}</p>
          </Grid.Column>
          <Grid.Column>
            <div className={styles.StatusIcon}>{getStatusIcon(order.status)}</div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default Order
