import React from 'react'
import { Message } from 'semantic-ui-react'
import styles from '../../../assets/styles/modules/UI/CustomMessage.module.scss'

const CustomMessage = ({ message }) => {
  return message ? (
    <Message
      success
      className={styles.MessageContainer}
      header={message.header}
      content={<p>{message.body}</p>}
    />
  ) : null
}

export default CustomMessage
