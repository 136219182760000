import * as actionTypes from '../actions/actionTypes'

const initialState = {
  posArray: [],
  locationsArray: [],
}

export const getLocationArray = (locations) => {
  const locationArr = []
  locations?.map((loc) =>
    locationArr.push({
      id: loc.UUID,
      value: loc.UUID,
      text: loc.Location,
    })
  )
  return locationArr
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_POS:
      return state
    case actionTypes.RECEIVE_POS:
      const pos = getLocationArray(action.pos)
      return { ...state, posArray: pos, locationsArray: action.pos }
    default:
      return state
  }
}

export default reducer
