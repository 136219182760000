import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styles from '../../../assets/styles/modules/Home.module.scss'
import { Link } from 'react-router-dom'
import distributorIconDarkGreenSrc from '../../../assets/images/home/distributors-dark-green.svg'
import distributorIconGreenSrc from '../../../assets/images/home/distributors-green.svg'
import distributorIconGreySrc from '../../../assets/images/navigation/distributors.svg'
import Spinner from '../../UI/Spinner/Spinner'
import useFirestoreCollection from '../../../hooks/useFirestoreCollection'
import { getDistributorsFromFirestore, listenToDistributors } from '../../../store/actions'

const Distributors = () => {
  const dispatch = useDispatch()
  const { distributors } = useSelector((state) => state.distributor)
  const { loading } = useSelector((state) => state.async)
  const { t } = useTranslation(['home', 'distributor']);
  
  useFirestoreCollection({
    query: () => getDistributorsFromFirestore(),
    data: (distributors) => dispatch(listenToDistributors(distributors)),
    deps: [dispatch],
  })

  if (loading) return <Spinner content={t('loading', { ns: 'home' })} />

  const classes = [styles.Task]
  if (distributors.length) classes.push(styles.Done)

  return (
    <div className={classes.join(' ')}>
      <Link className={styles.TaskLink} to="/distributors">
        <div className={styles.TaskIcon}>
          {!distributors.length ? (
            <img src={distributorIconGreySrc} alt="" border="0" />
          ) : (
            <>
              <img className={styles.TaskIconImg} src={distributorIconGreenSrc} alt="" border="0" />
              <img className={styles.TaskIconImgHover} src={distributorIconDarkGreenSrc} alt="" border="0" />
            </>
          )}
        </div>
        {distributors.length === 0 ? (
          <>
            <strong className="xx-large">1.</strong> <span>{t('add_distributors', { ns: 'distributor' })}</span>
          </>
        ) : (
          t('manage_distributors', { ns: 'home' })
        )}
      </Link>
    </div>
  )
}

export default Distributors
