import React from 'react'
import { Grid } from 'semantic-ui-react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import TextInput from '../../UI/Form/TextInput'
import styles from '../../../assets/styles/modules/orders/OrderDetails.module.scss'
import cx from 'classnames'

const OrderSign = ({ setSignError, updateOrderSign }) => {

  const { t } = useTranslation(['orders', 'validationMessages'])

  const initialValues = {
    sign: ''
  }

  const hasErrors = (errors) => {
    return Object.keys(errors).length > 0
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={(values) => {
        const errors = {}
        if (values.sign === '') {
          errors.sign = t('order_sign', { ns: 'validationMessages' })
        } else {
          updateOrderSign(values.sign)
        }
        setSignError(hasErrors(errors))
        return errors
      }}
    >
      {({ values, setFieldValue, setFieldError }) => (
        <Form
          name="order-summary-form"
          className="ui form"
        >
          <>
            <div className={styles.OrderDetails}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16} className={cx('grid-custom-column', styles.OrderSign)}>
                    <p className={styles.OrderSignTitle}>{t('sign_order', { ns: 'orders'})}</p>
                    <TextInput name="sign" placeholder={t('sign_here', { ns: 'orders'})} type="text" />
                  </Grid.Column>
                </Grid.Row>
            </Grid>
            </div>
          </>
        </Form>
      )}
    </Formik>
  )
}

export default OrderSign