import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  orders: [],
  orderSuggestions: {},
  order: null,
  newOrders: [],
  moreOrders: false,
  prevOrders: false,
  firstOrder: null,
  orderInProgress: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDERS_SUCCESS:
      return updateObject(state, {
        orders: action.orders,
        newOrders: [],
      })
    case actionTypes.GET_ORDER_SUGGESTIONS_SUCCESS:
      return updateObject(state, { orderSuggestions: action.suggestions })
    case actionTypes.GET_ORDER_SUCCESS:
      return updateObject(state, { order: action.order })
    case actionTypes.ORDER_CREATED_SUCCESS:
      return updateObject(state, { newOrders: action.newOrders })
    case actionTypes.GET_NEW_ORDERS_SUCCESS:
      return updateObject(state, { orders: action.orders })
    case actionTypes.ORDER_IN_PROGRESS:
      return updateObject(state, { orderInProgress: action.order })
    default:
      return state
  }
}

export default reducer
