import * as actionTypes from '../actions/actionTypes'

const initialState = null

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.OPEN_MODAL:
      const { modalType, modalProps } = payload
      return { modalType, modalProps }
    case actionTypes.CLOSE_MODAL:
      return null
    default:
      return state
  }
}

export default reducer
