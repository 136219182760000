import React from 'react'
import { FormField, Label, Input, Icon, Popup } from 'semantic-ui-react'
import { useField } from 'formik'
import cx from 'classnames'
import styles from '../../../assets/styles/modules/UI/TextInput.module.scss'

const TextInput = ({ defaultValue, fullHeight = false, ...props }) => {
  const [field, meta] = useField(props)

  const cleanProps = { ...props }
  delete cleanProps.style
  delete cleanProps.className
  delete cleanProps.textLabel

  return (
    <FormField error={meta.touched && !!meta.error} style={props.style} className={props.className}>
      {meta.touched && meta.error ? (
        <Label pointing="below" content={meta.error} />
      ) : (
        props.textLabel && (
          <label>
            {props.textLabel}
            {props.hint && (
              <Popup
                position="top center"
                content={props.hint}
                trigger={<Icon color="grey" name="question circle" />}
              />
            )}
          </label>
        )
      )}
      <Input
        type="text"
        size="large"
        className={cx(styles.Input, fullHeight && styles.FullHeight)}
        {...field}
        value={defaultValue || field.value?.text || field.value}
        {...cleanProps}
        disabled={props.disabled}
      />
      {props.dropdownChanged && (
        <Icon
          className={cx('pointer', props.firstStockPage? styles.FirstTextInputStock : props.stockPage ? styles.TextInputStock : styles.TextInput)}
          name="window close outline"
          color="grey"
          size="large"
          alt="Close input"
          title="Close input"
          onClick={() => props.dropdownChanged('close-input')}
        />
      )}
    </FormField>
  )
}

export default TextInput
