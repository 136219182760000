import useWindowSize from './useWindowSize'

export default function useBreakpoint() {
  const { width } = useWindowSize()
  switch (true) {
    case width <= 480:
      return 'xs'
    case width <= 768:
      return 's'
    default:
      return 'l'
  }
}
