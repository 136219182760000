import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, Button, Icon, Message, Checkbox } from 'semantic-ui-react'
import { getOrderStatus, getStatusIcon } from '../../../shared/utility'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import styles from '../../../assets/styles/modules/orders/OrderDetails.module.scss'

const OrderDetails = ({ order, onChangeStatusClick, changeConfirmationSection, onResendEmailClick }) => {
  const { loading } = useSelector((state) => state.async)
  const { t } = useTranslation(['orders', 'stock', 'buttons'])

  return (
    <div className={styles.OrderDetails}>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h2>{order.distributorName}</h2>
            <p>{order.distributorEmail}</p>
            <p>{t('order_number', { number: order.orderId, ns: 'orders' })}</p>
          </Grid.Column>
        </Grid.Row>
        {(order.status === 'error' || order.status === 'processing') && (
          <Grid.Row>
            <Grid.Column className="message-col" textAlign="center">
              <Message warning>
                {order.status === 'error' && (
                  <p>
                    <strong>{t('email_not_sent', { ns: 'orders' })}</strong>
                  </p>
                )}
                <Button
                  className={styles.OrderAction}
                  loading={loading}
                  color="yellow"
                  basic
                  type="button"
                  onClick={onResendEmailClick}
                >
                  <Icon name="send" />
                  {t('re_send_order_email', { ns: 'buttons' })}
                </Button>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}

        <Grid.Row className="grid-headers">
          <Grid.Column>
            <strong>{t('item', { ns: 'orders' })}</strong>
          </Grid.Column>
          <Grid.Column>
            <strong>{t('product_number', { ns: 'orders' })}</strong>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <strong>{t('amount', { ns: 'orders' })}</strong>
          </Grid.Column>
          <Grid.Column textAlign="center">
            <strong>{t('total_amount', { ns: 'orders' })}</strong>
          </Grid.Column>
        </Grid.Row>

        {order.items &&
          order.items.map((item, index) => (
            <>
              <Grid.Row key={'item' + index} className="grid-item">
                <Grid.Column>{item.name}</Grid.Column>
                <Grid.Column>{item.itemNumber}</Grid.Column>
                <Grid.Column textAlign="center" className={item.amountReceived ? styles.strikethrough : ''}>
                  {item.amount} {item.orderUnit}
                </Grid.Column>
                <Grid.Column textAlign="center" className={item.amountReceived ? styles.strikethrough : ''}>
                  {item.totalAmount} {item.unit}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row key={'mobile.item' + index} className="mobile-item-number">
                <p>{t('item_no', { ns: 'stock' })} {item.itemNumber}</p>
              </Grid.Row>
              {item.amountReceived && (
                <Grid.Row key={'item.amount' + index} >
                  <Grid.Column width={9}></Grid.Column>
                  <Grid.Column width={3} textAlign="center">
                    {item.amountReceived.amount} {item.orderUnit}
                  </Grid.Column>
                  <Grid.Column width={4} textAlign="center">
                    {item.amountReceived.totalAmount} {item.unit}
                  </Grid.Column>
                </Grid.Row>
              )}
            </>
          ))}
      </Grid>
      <hr className={styles.Divider} />
      <Grid>
        <Grid.Row className="checkbox-row">
          <Grid.Column>
            <Checkbox label={t('pickup', { ns: 'orders' })} checked={order.pickUp} disabled />
          </Grid.Column>
          <Grid.Column>
            <Checkbox label={t('delivery', { ns: 'orders' })} checked={!order.pickUp} disabled />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="order-status">
          <Grid.Column width={7}>
            <p>
              <span className="hint">{t('order_status', { ns: 'orders' })}: </span>
              {t(`order_${order.status}`, { ns: 'orders' })}
            </p>
          </Grid.Column>
        </Grid.Row>
        {!order.pickUp && (
          <Grid.Row className="delivery-information">
            <Grid.Column>
              <p className="hint">{t('requested_delivery_date', { ns: 'orders' })}</p>
              <p>{moment(order.deliveryDate).format('D MMM YYYY')}</p>
            </Grid.Column>
            <Grid.Column>
              <p className="hint">{t('delivery_location', { ns: 'orders' })}</p>
              {order.deliveryLocation && (
                <p>
                  {order.deliveryLocation.street},&nbsp;
                  {order.deliveryLocation.postcode} {order.deliveryLocation.place}
                </p>
              )}
            </Grid.Column>
          </Grid.Row>
        )}
        {order.notes && (
          <Grid.Row>
            <Grid.Column width={16}>
              <p className="hint">{t('notes', { ns: 'orders' })}:</p>
              <p>{order.notes}</p>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          {onChangeStatusClick !== undefined &&
          !(order.status === 'processing' || order.status === 'delivered') && (
            <Grid.Column width={16} textAlign="center">
              {order.status !== 'confirmed' && (
                <Button
                  className={cx(styles.Ui, styles.Button, styles.OrderAction)}
                  loading={loading}
                  color="green"
                  basic
                  type="button"
                  onClick={() => onChangeStatusClick('confirmed')}
                >
                  <Icon name="check" />
                  {t('mark_as_confirmed', { ns: 'orders' })}
                </Button>
              )}
              <Button
                className={cx(styles.Ui, styles.Button, styles.OrderAction)}
                disabled={order.status === 'error'}
                loading={loading}
                color="green"
                type="button"
                onClick={() => changeConfirmationSection('confirmation')}
              >
                <Icon name="box" />
                {order.pickUp ? t('mark_as_picked_up', { ns: 'orders' }) : t('mark_as_delivered', { ns: 'orders' })}
              </Button>
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
      { order.sign && 
        <Grid className={styles.OrderSignSection}>
          <Grid.Row className={styles.OrderSignSectionRow}>
            <Grid.Column width={16}>
              <strong>{t('order_signed_by', { ns: 'orders' })}</strong>
            </Grid.Column>
            <Grid.Column width={16}>
              <p>{order.sign}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
      <span className={styles.StatusIcon}>{getStatusIcon(order.status, 'big')}</span>
    </div>
  )
}

export default OrderDetails
