import * as actionTypes from './actionTypes'

export function setPageTitle(title) {
  return {
    type: actionTypes.SET_PAGE_TITLE,
    title,
  }
}

export function setPageSubtitle(subtitle) {
  return {
    type: actionTypes.SET_PAGE_SUBTITLE,
    subtitle,
  }
}
