import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Segment } from 'semantic-ui-react'
import styles from '../../../assets/styles/modules/menu/MenuItem.module.scss'
import btnStyles from '../../../assets/styles/modules/UI/Button.module.scss'
import useBreakpoint from '../../../hooks/useBreakpoint'
import { useTranslation } from 'react-i18next'

const MenuItem = (props) => {
  const history = useHistory()
  const breakpoint = useBreakpoint()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation(['menu', 'buttons'])
  const { item } = props

  const ingredients = []

  Object.keys(props.item.ingredients).forEach((key) => {
    ingredients.push(props.item.ingredients[key].name)
  })

  const subrecipes = []
  if (props.item.subrecipes !== undefined) {
    Object.keys(props.item.subrecipes).forEach((key) => {
      ingredients.push(props.item.subrecipes[key].name)
    })
  }

  const classNames = [styles.Recipe]
  if (props.item.isArchived) {
    classNames.push(styles.Archived)
  }

  return (
    <Segment id={item.id} raised className={classNames.join(' ')}>
      <div className={styles.Detail}>
        <div className={styles.Title}>
          <span>{props.item.name}</span>
        </div>
        {breakpoint !== 'xs' && (
          <>
            {ingredients.length > 0 && <p>{ingredients.map(e => e && e.split(' - Item Number')[0]).join(', ')}</p>}
            {subrecipes.length > 0 && <p>{subrecipes.map(e => e && e.split(' - Item Number')[0]).join(', ')}</p>}
          </>
        )}
      </div>
      <div className={styles.BtnContainer}>
        {props.item.isArchived ? (
          <>
            <Button
              disabled={loading}
              type="button"
              color="orange"
              basic
              compact
              content={t('delete', { ns: 'buttons' })}
              floated="left"
              onClick={() => props.onDeleteClick(props.item.id)}
            />
            <Button
              loading={loading}
              type="button"
              className={btnStyles.LightGreen}
              compact
              content={t('restore_item', { ns: 'buttons' })}
              floated="right"
              onClick={async () => {
                setLoading(true)
                await props.onRestoreClick(props.item.id)
                setLoading(false)
              }}
            />
          </>
        ) : (
          <Button
            type="button"
            color="green"
            basic
            compact
            content={t('view_details', { ns: 'menu' })}
            onClick={() => {
              if (props.item.isCombo) {
                if (!!props.selectedLocation === false) {
                  localStorage.setItem('target', item.id)
                  history.push(`/menu/combo/view/${props.item.id}`, { target: item.id })
                } else {
                  localStorage.setItem('target', item.id)
                  history.push(`/menu/combo/view/${props.item.id}/location/${props.selectedLocation.id}`, { target: item.id })
                }
              } else {
                if (!!props.selectedLocation === false) {
                  localStorage.setItem('target', item.id)
                  history.push(`/menu/view/${props.item.id}`, { target: item.id })
                } else {
                  localStorage.setItem('target', item.id)
                  history.push(`/menu/view/${props.item.id}/location/${props.selectedLocation.id}`, { target: item.id })
                }
              }
            }}
          />
        )}
      </div>
    </Segment>
  )
}

export default MenuItem
