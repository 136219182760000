import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Button, Grid, Message, Label } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Form, FieldArray } from 'formik'
import TextInput from '../../../UI/Form/TextInput'
import TextAreaInput from '../../../UI/Form/TextAreaInput'
import Ingredient from '../../Ingredient/Ingredient'
import IngredientWithDropdown from '../../Ingredient/IngredientWithDropdown'
import RadioInput from '../../../UI/Form/RadioInput'
import SubMenuItem from '../../SubMenuItem/SubMenuItem'
import AddIngredientButton from '../../Form/Button/AddIngredientButton'
import AddSubMenuItemButton from '../../Form/Button/AddSubMenuItemButton'
import { makeStockArray, getCurrentDistributorProducts, getLocationId, getSubMenuItemArray, getUnitArray, getDistributorsOnboarding } from '../../../../shared/utility'
import { httpsCallable } from 'firebase/functions'
import cx from 'classnames'
import styles from '../../../../assets/styles/modules/menu/AddMenuItem.module.scss'
import { cloudFunctions } from '../../../../config/firebase'
import CheckboxInput from '../../../UI/Form/CheckboxInput'
import { shouldDisableNextStep } from './utils'
import { PersistFormikValues } from 'formik-persist-values'
import SelectInput from '../../../UI/Form/SelectInput'
import Spinner from '../../../UI/Spinner/Spinner'
import { TYPE_YOUR_OWN_DISTRIBUTOR } from '../../../../store/wordings'

export function ShowForm({
  isSubmitting,
  errors,
  values,
  setFieldValue,
  resetForm,
  formikRef,
  state,
  initialValues,
  batchItemValidation,
  setIsCombo,
  baseUrl,
  shouldRefresh = false,
  itemNameExists,
  locationName='',
  menuItems,
  selectedLocation
}) {
  const { user } = useSelector((state) => state.user)
  const [stock, setStock] = useState([])
  const [distributors, setDistributors] = useState([])
  const [userProducts, setUserProducts] = useState([])
  const [isLoadingDistributors, setIsLoadingDistributors] = useState(true)
  const [isLoadingUserProducts, setIsLoadingUserProducts] = useState(true)
  const [distributorProducts, setDistributorProducts] = useState({}) // Object with { distributorId: [productsArray] }
  const { t } = useTranslation(['menu', 'buttons', 'common'])
  const buttonRef = useRef(null);
  const toggleEnterRef = useRef(false);

  const stockArr = makeStockArray(stock, t, values.locationIds)
  const sidesArr = getSubMenuItemArray(menuItems, 'isSubrecipe')
  const allMenuItemsArr = getSubMenuItemArray(menuItems)

  const history = useHistory()
  let { current_step } = useParams()

  useEffect(() => {
    if (state && state.values) {
      // Set formik persisted values to each field
      Object.keys(state.values).forEach((value) => {
        setFieldValue(value, state.values[`${value}`])
      })
    }
  }, [state, setFieldValue])

  useEffect(() => {
    if (shouldRefresh) {
      resetForm(initialValues)
      localStorage.removeItem(baseUrl)
    }
  }, [shouldRefresh, resetForm, initialValues, baseUrl])

  useEffect(() => {
    const getDistributors = async () => {
      setDistributors((await getDistributorsOnboarding()).concat([TYPE_YOUR_OWN_DISTRIBUTOR(t)]))
      setIsLoadingDistributors(false)
    }

    const getUserProducts = async () => {
      const response = await httpsCallable(cloudFunctions, 'getUserProducts')()
      const data = response.data.map((el) => {
        return { value: el.id, text: el.Name }
      })
      setUserProducts(data)
      setIsLoadingUserProducts(false)
    }

    const getUserStock = async () => {
      const response = await httpsCallable(cloudFunctions, 'getUserStock')({ locationId: selectedLocation ? selectedLocation.id : null })
      setStock(response.data)
    }

    getDistributors()
    getUserProducts()
    getUserStock()

    return () => {
      buttonRef.current = null;
      toggleEnterRef.current = null;
    }
  }, [])

  const shouldDisableNext = useMemo(
    () => shouldDisableNextStep(current_step, values, user),
    [current_step, user, values]
  )

  const updateProducts = (distributorId) => {
    getDistributorProducts(distributorId)
    return distributorProducts[`${distributorId}`]?.length ? distributorProducts[`${distributorId}`] : []
  }

  const getDistributorProducts = async (distributorId) => {
    const distributor = distributors.filter(dist => dist.name === distributorId)
    if (!distributorProducts[`${distributorId}`]) {
      const custom = distributor.length && distributor[0].customDistributor
      const data = await getCurrentDistributorProducts(custom ? distributor[0].id : distributorId, custom ? getLocationId(selectedLocation) : null, t)
      setDistributorProducts((prev) => {
        return { ...prev, [distributorId]: data }
      })
    }
  }

  const onChangeIsBatchItem = ({ value: isBatchItem }) => {
    batchItemValidation(isBatchItem)
    values.isBatchItem = isBatchItem
  }

  if (isSubmitting) return <Spinner loading content={t('please_wait', { ns: 'common' })} />

  return (
    <>
      {errors.recipes && <Message error content={errors.recipes} />}
      <Form autocomplete="off" name={baseUrl} className="ui form">
        <Grid className={styles.CustomGrid}>
          <Grid.Row
            className={current_step === 'second-step' ? cx(styles.ui, styles.grid, styles.row, styles.Hide) : ''}
          >
            <Grid.Column width={16}>
              { itemNameExists && <Message error content={`A menu item with name '${values.name}' already exists`} /> }
              { values.type === 'side' ?
                <TextInput
                  placeholder={values.name ? values.name : t('item_name', { ns: 'menu' })}
                  name="name"
                  type="text"
                />
              :
                <SelectInput
                  name="name"
                  placeholder={values.name ? values.name : t('item_name', { ns: 'menu' })}
                  options={userProducts}
                  fieldValueSetter={setFieldValue}
                  fieldValueToSet="name"
                  onlyValue={true}
                  loading={isLoadingUserProducts}
                  disabled={isLoadingUserProducts}
                  compact
                />
              }
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={
              current_step === 'second-step'
                ? cx(styles.ui, styles.grid, styles.row, styles.Hide)
                : styles.RadioInputsContainer
            }
          >
            <Grid.Column className={styles.Subtitle}>
              <strong>{t('item_type', { ns: 'menu' })}:</strong>
            </Grid.Column>
            <Grid.Column>
              <RadioInput
                className={styles.ItemType}
                name="type"
                value="single"
                label={t('single_item', { ns: 'menu' })}
                onClick={() => {
                  setFieldValue('subrecipes', [])
                  setFieldValue('ingredients', [
                    {
                      id: '',
                      generic_name: '',
                      amount: '',
                      unit: '',
                    },
                  ])
                  setIsCombo(false)
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <RadioInput
                className={styles.ItemType}
                name="type"
                value="side"
                label={t('side_extra', { ns: 'menu' })}
                onClick={() => {
                  setFieldValue('ingredients', [
                    {
                      id: '',
                      generic_name: '',
                      amount: '',
                      unit: '',
                    },
                  ])
                  setFieldValue('subrecipes', [])
                  setIsCombo(false)
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <RadioInput
                className={styles.ItemType}
                name="type"
                value="combo"
                label={t('combo', { ns: 'menu' })}
                onClick={() => {
                  setFieldValue('ingredients', [])
                  setFieldValue('subrecipes', [{ id: '', name: '', servings: '' }])
                  setIsCombo(true)
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={
              current_step === 'second-step'
                ? cx(styles.ui, styles.grid, styles.row, styles.Hide)
                : styles.IngredientsSection
            }
          >
            {values.type !== 'combo' && (
              <>
                <Grid.Column width={5} style={{ margin: '12px 0' }} className={cx(styles.Subtitle, styles.HideMobile)}>
                  {t('ingredients', { ns: 'menu' })}
                </Grid.Column>
                <Grid.Column width={16} className={styles.MobileTitle}>
                  <p>{t('ingredients', { ns: 'menu' })}</p>
                </Grid.Column>
                <Grid.Column className={styles.MobileCheckbox}>
                  <p>{t('batch', { ns: 'menu' })}</p>
                  <CheckboxInput
                    type="checkbox"
                    toggle
                    name="isBatchItem"
                    label={t('batch_item', { ns: 'menu' })}
                    onChange={onChangeIsBatchItem}
                    isBatchItem={true}
                  />
                  {values.isBatchItem && (
                    <div className="mobile-servings-input">
                      <Grid.Column
                        width={13}
                        textAlign="right"
                        style={{ paddingRight: '8px' }}
                        className={styles.Subtitle}
                      >
                        {t('how_many_servings', { ns: 'menu' })}
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <TextInput className={errors?.servings ? 'error' : ''} name="servings" type="text" />
                      </Grid.Column>
                      <Grid.Column width={1}></Grid.Column>
                    </div>
                  )}
                </Grid.Column>
              </>
            )}
            {values.isBatchItem && (
              <>
                <Grid.Column
                  width={13}
                  textAlign="right"
                  style={{ paddingRight: '8px' }}
                  className={cx(styles.Subtitle, styles.DesktopServingsInput)}
                >
                  {t('how_many_servings', { ns: 'menu' })}&nbsp;&nbsp;
                </Grid.Column>
                <Grid.Column width={3} className={styles.DesktopServingsInput}>
                  <TextInput className={errors?.servings ? 'error' : ''} name="servings" type="text" />
                </Grid.Column>
                <Grid.Column width={1} className={styles.DesktopServingsInput}></Grid.Column>
              </>
            )}
          </Grid.Row>
          {values.type !== 'combo' && (
            <>
              <FieldArray
                name="ingredients"
                render={(arrayHelpers) => (
                  <>
                    {Object.values(values.ingredients).map((ingredient, index) => {
                      const unitArr = getUnitArray(
                        ingredient?.id_product_onboarding?.availableUnits || ingredient?.generic_name?.availableUnits
                      )
                      return current_step === 'second-step' ? (
                        <IngredientWithDropdown
                          key={`withdropdown-${ingredient.id}`}
                          index={index}
                          distributors={distributors}
                          unitArr={unitArr}
                          fieldArrayHelper={arrayHelpers}
                          fieldValueSetter={setFieldValue}
                          values={values.ingredients}
                          updateProducts={updateProducts}
                          isLoadingDistributors={isLoadingDistributors}
                          currentStep={current_step}
                        />
                      ) : (
                        <Ingredient
                          key={`Ingredient-${index}`}
                          index={index}
                          stockArr={stockArr}
                          unitArr={unitArr}
                          fieldArrayHelper={arrayHelpers}
                          fieldValueSetter={setFieldValue}
                          values={values.ingredients}
                          showColumns={current_step === 'third-step'}
                          unitError={errors.ingredients && errors.ingredients[index]}
                          isSubmitting={isSubmitting}
                          buttonRef={buttonRef}
                          toggleEnterRef={toggleEnterRef}
                        />
                      )
                    })}
                    <Grid.Row
                      style={{ paddingTop: '0px' }}
                      className={
                        current_step !== 'first-step' ? cx(styles.ui, styles.grid, styles.row, styles.Hide) : ''
                      }
                    >
                      <Grid.Column width={16}>
                        <AddIngredientButton fieldArrayHelper={arrayHelpers} buttonRef={buttonRef} toggleEnterRef={toggleEnterRef} />
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              />
              {current_step !== 'second-step' && (
                <FieldArray
                  name="subrecipes"
                  render={(arrayHelpers) => (
                    <>
                      <Grid.Row style={{ paddingTop: '0px' }}>
                        <Grid.Column width={16} className={styles.Subtitle}>
                          <strong>{t('sides_extras', { ns: 'menu' })}</strong>
                        </Grid.Column>
                      </Grid.Row>
                      {Object.keys(values.subrecipes).map((subrecipe, i) => (
                        <SubMenuItem
                          key={`side-extras-${i}`}
                          index={subrecipe}
                          items={sidesArr}
                          fieldArrayHelper={arrayHelpers}
                          fieldValueSetter={setFieldValue}
                          values={values.subrecipes}
                          buttonRef={buttonRef}
                          toggleEnterRef={toggleEnterRef}
                        />
                      ))}
                      <Grid.Row style={{ paddingTop: '0px' }}>
                        <Grid.Column width={16}>
                          <AddSubMenuItemButton label={t('add_side_extra', { ns: 'menu' })} fieldArrayHelper={arrayHelpers} buttonRef={buttonRef} toggleEnterRef={toggleEnterRef} />
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )}
                />
              )}
            </>
          )}
          {values.type === 'combo' && current_step !== 'second-step' && (
            <FieldArray
              name="subrecipes"
              render={(arrayHelpers) => (
                <>
                  <Grid.Row style={{ paddingTop: '0px' }}>
                    <Grid.Column width={16} className={styles.Subtitle}>
                      {t('included_menu_items', { ns: 'menu' })}
                    </Grid.Column>
                  </Grid.Row>
                  {Object.keys(values.subrecipes).map((recipe, i) => (
                    <SubMenuItem
                      key={i}
                      index={recipe}
                      items={allMenuItemsArr}
                      values={values.subrecipes}
                      fieldArrayHelper={arrayHelpers}
                      fieldValueSetter={setFieldValue}
                      buttonRef={buttonRef}
                      toggleEnterRef={toggleEnterRef}
                    />
                  ))}
                  <Grid.Row style={{ paddingTop: '0px' }}>
                    <Grid.Column width={16}>
                      <AddSubMenuItemButton label={t('add_menu_item', { ns: 'menu' })} fieldArrayHelper={arrayHelpers} buttonRef={buttonRef} toggleEnterRef={toggleEnterRef} />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            />
          )}
          <Grid.Row
            style={{ paddingTop: '0px' }}
            className={current_step === 'second-step' ? cx(styles.ui, styles.grid, styles.row, styles.Hide) : ''}
          >
            <Grid.Column width={16}>
              <TextAreaInput name="notes" placeholder={t('notes', { ns: 'menu' })} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
          <span className={styles.ButtonWrapper}>
            <Button
              className={cx(styles.Button, styles.Green)}
              floated="left"
              type="button"
              basic
              color="green"
              size="big"
              onClick={() => {
                resetForm(initialValues)
                localStorage.removeItem(baseUrl)
                history.push('/menu')
              }}
              content={t('cancel', { ns: 'buttons' }).toUpperCase()}
            />
            {current_step === 'third-step' || values.type === 'combo' ? (
              <Button className={cx(styles.Button, styles.Green)} loading={isSubmitting} type="submit" color="green" content={t('save', { ns: 'buttons' }).toUpperCase()} floated="right" size="big" disabled={itemNameExists} />
            ) : (
              <Button
                className={cx(styles.Button, styles.Green)}
                type="button"
                color="green"
                content={t('next', { ns: 'buttons' }).toUpperCase()}
                floated="right"
                size="big"
                onClick={() => {
                  values.ingredients = Object.values(values.ingredients).filter((ingredient) => ingredient.name !== '')
                  const nextStep = current_step === 'first-step' ? 'second-step' : 'third-step'
                  formikRef.current = values
                  if (nextStep === 'second-step') {
                    history.push('/menu')
                    history.push(`${baseUrl}/first-step`, { from: '' })
                  }
                  history.push(`${baseUrl}/${nextStep}`, { values, from: '' })
                }}
                disabled={shouldDisableNext || itemNameExists}
              />
            )}
          </span>
        </div>
        <PersistFormikValues name={baseUrl} />
      </Form>
    </>
  )
}
