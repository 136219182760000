import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'
import { LOCATION_CHANGE } from 'connected-react-router'

const initialState = {
  isAuthenticated: false,
  currentUser: null,
  prevLocation: null,
  currentLocation: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_SUCCESS:
      return updateObject(state, {
        isAuthenticated: true,
        currentUser: {
          uid: action.user.uid,
          displayName: action.user.displayName,
          photoURL: action.user.photoURL,
        },
      })
    case actionTypes.AUTH_LOGOUT:
      return updateObject(state, {
        isAuthenticated: false,
        currentUser: null,
      })
    case LOCATION_CHANGE:
      return updateObject(state, {
        prevLocation: state.currentLocation,
        currentLocation: action.payload.location,
      })
    default:
      return state
  }
}

export default reducer
