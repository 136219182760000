import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Icon, Segment } from 'semantic-ui-react'
import styles from '../../../assets/styles/modules/locations/Location.module.scss'
import placeholderSrc from '../../../assets/images/locations/placeholder.svg'
import { setSelectedLocation } from '../../../store/actions/locationActions'

const Location = ({ location }) => {
  const history = useHistory()
  const { t } = useTranslation(['buttons', 'location']);
  const dispatch = useDispatch()
  const { selectedLocation } = useSelector((state) => state.location)

  const classes = [styles.Location]
  if (location.isActive === false) {
    classes.push(styles.Inactive)
  }

  if (selectedLocation && selectedLocation.id === location.id) {
    classes.push(styles.Selected)
  }

  return (
    <Segment id={location.id} raised className={classes.join(" ")} onClick={() => dispatch(setSelectedLocation({ id: location.id, name: location.name }))}>
      <Grid className={styles['Location-Grid']}>
        <Grid.Row>
          <Grid.Column className={styles.Details} style={{ padding: '0 10px' }}>
            <h3 className={styles.Name}>{location.name}{ selectedLocation && selectedLocation.id === location.id && <span className={styles.Badge}>{t('selected', { ns: 'location' })}</span>}</h3>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column className="location-logo">
            <img src={location.locationPhotoURL ? location.locationPhotoURL : placeholderSrc} alt={location.name} />
          </Grid.Column>

          <Grid.Column className="location-information">
            <div className='information-item'>
              <div>
                <Icon name="map marker alternate" color="grey" size='large' />
              </div>
              <p>
                {location.street}, {location.postcode} {location.place}
              </p>
            </div>
            <div className='information-item'>
              <div>
                <Icon name="mail" color="grey" size='large' />
              </div>
              <p>{location.email}</p>
            </div>
            <div className='information-item'>
              <div>
                <Icon className={styles.PhoneIcon} name="phone" color="grey" size='large' />
              </div>
              <p>{location.phone}</p>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button
              className={styles.Button}
              color="green"
              basic
              content={t('edit', { ns: 'buttons' })}
              floated="right"
              onClick={() => {
                localStorage.setItem('target', location.id)
                history.push(`locations/edit/${location.id}`, { target: location.id })
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default Location
