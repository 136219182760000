import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { listenToMenuItems, getMenuItemsFromFirestore } from '../../store/actions/menuActions'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../store/actions/userActions'
import useFirestoreDoc from '../../hooks/useFirestoreDoc'
import useFetchLocation from '../../hooks/useFetchLocation'
import useFirestoreCollection from '../../hooks/useFirestoreCollection'
import { useUserFlags } from '../../hooks/useUserFlags'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../config/firebase'
import { asyncActionError, asyncActionFinish, asyncActionStart, setLocationIds } from '../../store/actions'
import { Button, Message, Checkbox, Menu } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import MenuItem from '../../components/Menu/MenuItem/MenuItem'
import { setPageTitle } from '../../store/actions/navigationActions'
import PortalPopup from '../UI/Portal/PortalPopup'
import GreenbytesModal from '../UI/GreenbytesModal/GreenbytesModal'
import styles from '../../assets/styles/modules/menu/Menu.module.scss'
import useBreakpoint from '../../hooks/useBreakpoint'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

const MenuItems = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { menuItems } = useSelector((state) => state.menu)
  const { user } = useSelector((state) => state.user)
  const { selectedLocation, locationArray, locationIds, locations } = useSelector((state) => state.location)
  const { loading, error, message } = useSelector((state) => state.async)
  const { fetchLocations } = useFetchLocation()
  const { t } = useTranslation(['menu', 'common'])

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const [showArchived, setShowArchived] = useState(false)
  const [activeItem, setActiveItem] = useState(null)
  const [portalOpen, setPortalOpen] = useState(false)
  const [portalMessage, setPortalMessage] = useState('')
  const breakpoint = useBreakpoint()
  const target = history.location?.state?.target || localStorage.getItem('target')

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(target)
      if (!element) { return }
      element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      history.replace({state: {}})
      localStorage.removeItem('target')
    }, 500);

    return () => clearTimeout(timer)
  }, [history, target])

  useEffect(() => {
    const items = { ...localStorage }
    Object.keys(items).forEach(item => {
      if (item.match(/menu/gi)) localStorage.removeItem(item)
    })
  }, [])

  useEffect(() => {
    dispatch(setPageTitle(t('title', { ns: 'menu' })))
    fetchLocations(dispatch)
    dispatch(setLocationIds(selectedLocation ? new Array(selectedLocation.id) : []))
  }, [dispatch, selectedLocation])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  useFirestoreCollection({
    query: () => getMenuItemsFromFirestore(selectedLocation ? selectedLocation.id : null),
    data: (menuItems) => dispatch(listenToMenuItems(menuItems)),
    deps: [dispatch, selectedLocation],
  })

  // Update user flags is needed
  useUserFlags()

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const onShowArchivedClickHandler = () => {
    setShowArchived(!showArchived)
  }

  const onDeleteClickHandler = (itemId) => {
    setConfirmOpen(true)
    setItemToDelete(itemId)
  }

  const onRestoreClickHandler = async (itemId) => {
    try {
      dispatch(asyncActionStart)
      await httpsCallable(cloudFunctions, 'restoreMenuItem')({ id: itemId, location: locationIds })
      dispatch(asyncActionFinish(t('item_was_restored', { ns: 'menu' })))
    } catch (error) {
      dispatch(asyncActionError(error, t('item_could_not_be_restored', { ns: 'menu' }), `restoreMenuItem: ${error.message}`))
      setPortalMessage(error.message)
      setPortalOpen(true)
    }
  }

  async function handleDelete() {
    try {
      await httpsCallable(cloudFunctions, 'deleteMenuItem')({ id: itemToDelete, location: locationIds })
    } catch (error) {
      setPortalMessage(error.message)
      setPortalOpen(true)
    }
    setConfirmOpen(false)
  }

  const handleClosePortal = () => {
    setPortalOpen(false)
  }

  const countItems = (items, type) => {
    switch (type) {
      case 'single':
        return items.filter(item => !item.isCombo && !item.isSubrecipe).length
      case 'side':
        return items.filter(item => item.isSubrecipe).length
      case 'combo':
        return items.filter(item => item.isCombo).length
      default:
        return items.length
    }
  }

  const activeLocations = () => (
    locationArray.map(loc => {
      const found = locations.find(element => element.id === loc.id && element.isActive);
      return found ? {...loc} : null
    }).filter(el => el !== null)
  )

  if (loading) return <Spinner content={t('please_wait', { ns: 'common' })} />

  if (!selectedLocation) {
    return <Redirect to="/locations" />
  }
  
  return (
    <>
      <div className={styles.RecipeList}>
        { !user.hasPOS &&
          <div className={styles.NoPOSMessage}>
            {t('no_POS_connected', { ns: 'menu' })}
          </div>
        }
        {error && <Message error content={error} />}
        {message && <Message success content={message} />}
        <div className={styles.Filters}>
          <Menu className={styles.Menu}>
            <Menu.Item className={styles.MenuItem} name="all" active={!activeItem} onClick={() => setActiveItem(null)}>
              {t('all', { ns: 'menu' })}
            </Menu.Item>
            <Menu.Item
              className={styles.MenuItem}
              name="single"
              active={activeItem === 'single'}
              onClick={() => setActiveItem('single')}
            >
              {t('single_items', { ns: 'menu' })}
            </Menu.Item>
            <Menu.Item
              className={styles.MenuItem}
              name="side"
              active={activeItem === 'side'}
              onClick={() => setActiveItem('side')}
            >
              {t('sides_extras', { ns: 'menu' })}
            </Menu.Item>
            <Menu.Item
              className={styles.MenuItem}
              name="combo"
              active={activeItem === 'combo'}
              onClick={() => setActiveItem('combo')}
            >
              {t('combos', { ns: 'menu' })}
            </Menu.Item>
          </Menu>
          {menuItems?.length && <p className={styles.ItemCount}>{t('items', { amount: countItems(menuItems, activeItem), ns: 'menu' })}</p>}
          {menuItems?.length === 0 &&
            <Message>
              <Message.Content className="align-center">
                {t('nothing_here', { ns: 'common' })}
                <br />
                {t('add_items_to_get_started', { ns: 'menu' })}
              </Message.Content>
            </Message>
          }
          <div className={styles.ArchivedLocationsContainer}>
            <Checkbox className={styles.Checkbox} label={t('show_archived', { ns: 'menu' })} onClick={() => onShowArchivedClickHandler()} />
          </div>
        </div>
        { selectedLocation == null && 
          <Message>
            <Message.Content className="align-center">{t('select_a_location', { ns: 'menu' })}</Message.Content>
          </Message>
        }
        { selectedLocation !== null && menuItems.map((item) => {
          if (
            (item.isArchived && !showArchived) ||
            (activeItem === 'side' && !item.isSubrecipe) ||
            (activeItem === 'combo' && !item.isCombo) ||
            (activeItem === 'single' && (item.isSubrecipe || item.isCombo))
          ) {
            return null
          } else {
            return (
              <MenuItem
                key={item.id}
                item={item}
                onDeleteClick={onDeleteClickHandler}
                onRestoreClick={onRestoreClickHandler}
                selectedLocation={selectedLocation}
                hasLocations={locations.length > 0}
              />
            )
          }
        })}
      </div>
      <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
        <span className={styles.ButtonWrapperCentered}>
          <Button
            className={cx(styles.Button, styles.Green)}
            color="green"
            onClick={() => history.push('/menu/add/first-step', { from: 'menu' })}
          >
            {t('add_new_item', { ns: 'menu' })}
          </Button>
        </span>
      </div>
      <GreenbytesModal
        confirmDeleteOpen={confirmOpen}
        setConfirmDeleteOpen={setConfirmOpen}
        text={t('delete_this_recipe', { ns: 'menu' })}
        confirmButtonText={'delete'}
        cancelButtonText={'cancel'}
        handleConfirmClick={handleDelete}
        cancelButtonAction={() => setItemToDelete(null)}
      />
      <PortalPopup isOpen={portalOpen} message={portalMessage} onCloseClick={handleClosePortal} />
    </>
  )
}

export default MenuItems
