export const addDistributorsToIngredients = (ingredients, ingredientsInfo) =>
  Object.values(ingredients).map((ingredient) => {
    const distributors = ingredientsInfo[ingredient.id].items
    const availableUnits = ingredientsInfo[ingredient.id].availableUnits
    return {
      ...ingredient,
      fromStock: true,
      unit_field: ingredient.unit,
      unit: { key: ingredient.unit, text: ingredient.unit, value: ingredient.unit },
      generic_name: {
        ...distributors[0],
        text: distributors[0].generic_name,
        has_multiple_distributors: distributors.length > 1,
        availableUnits: availableUnits.length ? availableUnits : null, // Will be filled with default units when getUnitArray is called
      },
    }
  })
