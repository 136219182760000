import React from 'react'
import { FormField, Label, Dropdown } from 'semantic-ui-react'
import { useField } from 'formik'
import styles from '../../../assets/styles/modules/UI/MultiselectInput.module.scss'

const MultiselectInput = (props) => {
  const [field, meta, helpers] = useField(props.name)

  return (
    <FormField error={meta.touched && !!meta.error}>
      {meta.touched && meta.error ? (
        <Label pointing="below" content={meta.error} />
      ) : props.label ? (
        <label>{props.label}</label>
      ) : null}
      <Dropdown
        clearable
        className={styles.Multiselect}
        options={props.options}
        search
        selection
        multiple
        loading={props.loading}
        disabled={props.disabled}
        placeholder={props.placeholder}
        value={field.value}
        onChange={(e, { value }) => {
          helpers.setValue(value)
          if (props.fieldValueSetter) {
            const channels = value.map((UUID) => {
              const ch = props.options.find((option) => option.value === UUID)
              return ch ? ch.value : ''
            })
            props.fieldValueSetter(props.name, [...channels])
          }
        }}
      />
    </FormField>
  )
}

export default MultiselectInput
