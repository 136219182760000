import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../store/actions/userActions'
import useFirestoreDoc from '../../hooks/useFirestoreDoc'
import usePagination from '../../hooks/usePagination'
import useFetchLocation from '../../hooks/useFetchLocation'
import { fetchOrdersForPagination, listenToOrders } from '../../store/actions/orderActions'
import { setPageTitle } from '../../store/actions'
import Order from './Order/Order'
import { Button, Message, Icon, Grid } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import styles from '../../assets/styles/modules/orders/OrderList.module.scss'
import { setSelectedLocation } from '../../store/actions/locationActions'
import cx from 'classnames'

const Orders = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['orders', 'common'])

  // Orders per page
  const limit = 10
  const currentCursor = history.location?.state?.cursor || parseInt(localStorage.getItem('cursor'))
  const target = history.location?.state?.target || localStorage.getItem('target')

  const { orders } = useSelector((state) => state.order)
  const { user } = useSelector((state) => state.user)
  const { selectedLocation, locationArray, locations } = useSelector((state) => state.location)
  const [currentLocation, setCurrentLocation] = useState(selectedLocation)
  const { loading, error } = useSelector((state) => state.async)
  const { firstDocOfCollection, lastDocOfPage, moreResults, counters, endOfCollection } = useSelector(
    (state) => state.pagination
  )
  const { fetchLocations } = useFetchLocation()

  const [loadNewPageAt, setLoadNewPageAt] = useState({ cursorDoc: null, reverseOrder: false })
  const [cursor, setCursor] = useState(currentCursor || 0)

  useEffect(() => {
    if (locationArray.length === 1) {
      const id = locationArray[0].id
      const name = locationArray[0].text
      setCurrentLocation({ id, name })
      dispatch(setSelectedLocation({ id, name }))
    }
  }, [dispatch, locationArray])

  useEffect(() => {
    setCurrentLocation(selectedLocation)
  }, [selectedLocation])

  useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(target)
      if (!element) { return }
      element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      history.replace({state: {}})
      localStorage.removeItem('target')
      localStorage.removeItem('cursor')
    }, 500);

    return () => clearTimeout(timer)
  }, [history, target])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  useEffect(() => {
    dispatch(setPageTitle(t('all_orders', { ns: 'orders' })))

    fetchLocations(dispatch, true)
  }, [dispatch])

  usePagination({
    query: () => fetchOrdersForPagination(limit, loadNewPageAt.cursorDoc, loadNewPageAt.reverseOrder, currentLocation),
    data: (orders) => dispatch(listenToOrders(orders)),
    pageLoadingData: loadNewPageAt,
    limit,
    cursor,
    deps: [dispatch, limit, loadNewPageAt, currentLocation],
  })

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const handleFetchNextItems = () => {
    setLoadNewPageAt({ cursorDoc: lastDocOfPage, reverseOrder: false })
    setCursor(cursor + 1)
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  const handleFetchPrevItems = () => {
    setLoadNewPageAt({ cursorDoc: firstDocOfCollection, reverseOrder: true })
    setCursor(cursor - 1)
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  function sendLocationOrder(e) {
    const locPOS = locations.find((loc) => loc.id === currentLocation.id)
    history.push({
      pathname: `/orders/create/location/${currentLocation.id}`,
      state: `${locPOS.locationPOS}`,
      previousPage: 'orders'
    })
  }

  if (!selectedLocation) {
    return <Redirect to="/locations" />
  }
  
  return (
    <>
      {user.isPremium && user.hasLocations && (
        <div className={styles.LocationSelection}>
          <Button color="green" size="big" onClick={() => sendLocationOrder()} disabled={!currentLocation}>
            {t('add_order', { ns: 'orders' })}
          </Button>
        </div>
      )}
      { currentLocation ?
        <>
          {orders.length > 0 && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  {t('from_to', { from: counters[0], to: counters[1], ns: 'orders' })}
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  {t('page_number', { page_number: cursor + 1, ns: 'orders' })}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          <div className={styles.OrderList}>
            {!orders.length && (
              <p className="align-center">
                {t('nothing_here', { ns: 'common' })}
                <br />
                {t('create_your_first_order', { ns: 'orders' })}
              </p>
            )}
            {!user.isPremium && (
              <Message align="center">
                <Message.Content>
                  <p className="large">{t('upgrade_to_premium', { ns: 'orders' })}</p>
                  <a className="link" href="/settings">
                    {t('upgrade_now', { ns: 'orders' })}
                  </a>
                </Message.Content>
              </Message>
            )}

            {endOfCollection && <Message content={t('no_more_orders', { ns: 'orders' })} />}
            {error && <Message warning content={t('something_wrong', { ns: 'common' , error })} />}

            {orders.map((order) => (
              <Order key={order.id} order={order} cursor={cursor} />
            ))}

            {orders.length > 0 && (
              <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapper}>
                  <Button
                    className={cx(styles.Button, styles.Green)}
                    basic
                    color="green"
                    disabled={cursor === 0}
                    onClick={handleFetchPrevItems}
                    icon
                  >
                    <Icon name="left angle" />
                    {t('previous', { ns: 'orders' })}
                  </Button>
                  <Button
                    className={cx(styles.Button, styles.Green)}
                    basic
                    color="green"
                    disabled={!moreResults}
                    onClick={handleFetchNextItems}
                    icon
                  >
                    {t('next', { ns: 'orders' }).toUpperCase()}
                    <Icon name="right angle" />
                  </Button>
                </span>
              </div>
            )}
          </div>
        </>
        :
        <Message className={styles.Message}>
          <Message.Content className="align-center">{t('select_a_location', { ns: 'orders' })}</Message.Content>
        </Message>
      }
    </>
  )
}

export default Orders